import React, { Component } from "react";
import "./HomeScreen.scss";
import { connect } from "react-redux";
import {
  setListEmpty,
  emptyFilterCount,
} from "../../actions/experienceActions";
import {
  getHappeningList,
  getMyHappenings,
  getFilterCount,
  getExperienceList,
} from "../../apiManager/services/experienceServices";
import {
  Card,
  WingBlank,
  WhiteSpace,
  NavBar,
  Icon,
  Flex,
  ListView,
  PullToRefresh,
  Accordion,
  Toast,
  Modal,
  Button,
} from "antd-mobile";
import { Link } from "react-router-dom";
import DEEPLINK from "../../apiManager/endpoints/deeplink";
import ProfileCard from "./components/ProfileCard/ProfileCard";
import { NavigationBar } from "./components/NavigationBar/navigationBar";
import {
  DefaultEventCard,
  EventCard,
  EventCardRow,
} from "./components/EventCard/eventCard";
import { CreateEventCard } from "../CreateEventList/components/createEventCard/createEventCard";
import SkeletonnList from "../../components/common/skeletonList/skeletonList";
import StepChallengeBanner from "./components/StepChallenge/Banner";
import StepsInfo from "./components/StepChallenge";
import SkeletonBanner from "../../components/common/SkeletonLayouts/SkeletonBanner";

const LIMIT = 20;

const NUM_ROWS = 5;

const temp = [
  {
    id: "5d13783e848a1f064ce4f785",
    name: "Mohammed Rashid",
    image:
      "https://s3.us-east-2.amazonaws.com/workfam-upload/maf-images/user/f929c053-320.jpg",
  },
  {
    id: "5d2e33adc330d42460ecfe29",
    name: "Mohammed Rashid",
    image:
      "https://s3.us-east-2.amazonaws.com/workfam-upload/maf-images/user/f929c053-320.jpg",
  },
  {
    id: "5d360a18e8dd734e6c7d0a86",
    name: "Mohammed Rashid",
    image:
      "https://s3.us-east-2.amazonaws.com/workfam-upload/maf-images/user/33328625-320.jpg",
  },
];

function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = pIndex * NUM_ROWS + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
    // console.log(dataBlob);
  }
  return dataBlob;
}

class HomeScreen extends Component {
  constructor(props) {
    super(props);
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });
    this.state = {
      dataSource,
      isLoading: true,
      initialLoad: true,
      isUpdated: false,
      myEvents: [],
      events: [],
      experiences: [],
      error: false,
    };
  }

  componentDidMount() {
    this.props.getFilterCount("home");
    this.fetchMyEvents();
    // console.log(this.props.match.params.id)
  }

  _onDataArrived(newData) {
    this._data = this._data.concat(newData);
    this.setState({
      dataSource: this.state.dataSource.cloneWithRows(this._data),
    });
  }

  _setLoadingNumber(number) {
    this.setState({
      loadingCount: number,
    });
  }

  componentDidUpdate() {
    // console.log(this.props.filterCount);
    if (
      this.props &&
      this.props.result &&
      this.props.result.items &&
      this.props.result.items.length === 0 &&
      this.props.filterCount &&
      this.props.filterCount.category.length > 0 &&
      !this.flag
    ) {
      this.handleListExperience(0, LIMIT);
      this.props.getExperienceList(0, LIMIT);
      this._setLoadingNumber(LIMIT);
      this.flag = true;
    }
  }

  componentWillUnmount() {
    // console.log("UNMOUNTING");
    this.props.emptyFilterCount();
  }

  static getDerivedStateFromProps(props, state) {
    // console.log("data", props, state);
    if (props.result && props.result.items && props.experiences.items) {
      return {
        events: props.result.items,
        experiences: props.experiences.items,
      };
    } else {
      return null;
    }
  }

  fetchMyEvents = () => {
    getMyHappenings(this._success, this.failure);
  };

  _success = (data) => {
    // console.log("my interest", data);
    if (data.data.result) {
      this.setState({ myEvents: data.data.data });
    }

    // this.setState({
    //   myEvents: [
    //     {
    //       cover: 'https://s3.us-east-2.amazonaws.com/workfam-upload/media/company-5d1319c8828b1806d4bc4a62/e0f7ffd8-1573118838750-486.jpg',
    //       name: 'adsfghjkhgfds',
    //       date: '88-88-8888'
    //     },
    //     {
    //       cover: 'https://s3.us-east-2.amazonaws.com/workfam-upload/media/company-5d1319c8828b1806d4bc4a62/e0f7ffd8-1573118838750-486.jpg',
    //       name: 'ads',
    //       date: '88-88-888'
    //     }
    //   ]
    // })
  };

  failure = (error) => {
    const errorObj = JSON.parse(JSON.stringify(error));

    console.log(errorObj.message);
    Toast.fail(errorObj.message, 1);
  };

  onEndReached = (event) => {
    if (
      this.props &&
      this.props.result &&
      this.props.result.totalResult &&
      this.props.result.totalResult > this.props.result.items.length
    ) {
      const _page = this.props.result.items.length / LIMIT;
      this.setState({ initialLoad: false });
      this.handleListExperience(_page, LIMIT);
      // console.log("page", _page);
      const _loadingCount =
        (_page + 1) * LIMIT > this.props.result.totalResult
          ? this.props.result.totalResult % LIMIT
          : LIMIT;
      this._setLoadingNumber(_loadingCount);
    }
  };

  renderMyEvents = () => {
    const that = this;
    return this.state.myEvents.map(function (item, i) {
      return (
        <th
          key={item.id}
          className="postition-relative"
          onClick={() => that.handleEventClick(item.id)}
        >
          <div
            className="home-my-events-item"
            style={{ backgroundImage: `url(${item.cover})` }}
          />
          <div className="home-my-events-item-overlay-container" />
          <p className="home-my-events-name">
            {item.name && item.name.length > 12
              ? item.name.substr(0, 12) + ".."
              : item.name}
          </p>
          <div className="home-my-events-date-container">{item.date}</div>
        </th>
      );
    });
  };

  render() {
    // console.log("print", this.props.filterCount);

    let { events, experiences } = this.state;

    if (this.props.isLoading) {
      events = [1, 2, 3];
      experiences = [1, 2, 3];
    }

    if (this.state.error)
      return (
        <div>
          <h1 className="text-center">Your Token is Expired or Wrong</h1>
        </div>
      );

    // console.log("ECPERCES", events, experiences);

    return (
      <>
        <NavBar
          mode="light"
          leftContent={
            <Icon
              key="1"
              type="left"
              className="navbar-back-icon"
              onClick={() => {
                window.open(DEEPLINK.APPLICATION_BACK);
              }}
            />
          }
          className="navbar-container"
          rightContent={
            <div onClick={this.handleCreateClick}>
              {this.props.result.create ? (
                <div className="discount-button">
                  <p>%</p>
                  <p>Discounts</p>
                </div>
              ) : null}
            </div>
          }
        >
          MyWellness
        </NavBar>
        {/* {
          console.warn("WARNNNN", this.props?.profile?.stepChallengeData?.appleHealth, this.props?.profile?.stepChallengeData?.totalSteps)
        } */}
        <div className="main-container">
          <ProfileCard isExpanded={false} />
          {this.props.profile &&
            this.props.profile.hasOwnProperty("stepChallenge") &&
            this.props.profile.stepChallenge && (
              <>
                {this.props.profile.stepChallengeJoin === false ? (
                  <StepChallengeBanner
                    setupStepChallenge={() =>
                      this.props.history.replace("/step-challenge-setup")
                    }
                  />
                ) : (
                  <StepsInfo
                    history={this.props.history}
                    setError={() => this.setState({ error: true })}
                    params={this.props.match.params}
                    data={this.props.profile.stepChallengeData}
                  />
                )}
              </>
            )}

          <NavigationBar history={this.props.history} />

          {this.state.myEvents.length > 0 ? (
            <div className="home-my-events-container">
              {this.state.myEvents.length > 0 ? (
                <div className="home-my-event-coount">
                  {this.state.myEvents.length}
                </div>
              ) : null}
              <Accordion
                accordion
                openAnimation={{}}
                className="my-accordion"
                onChange={this.onChange}
              >
                <Accordion.Panel header="My Experiences" className="pad">
                  <div>{this.renderMyEvents()}</div>
                </Accordion.Panel>
              </Accordion>
            </div>
          ) : null}

          <div className="home-heading-container slide-top">
            <div className="relative">
              <span className="home-heading-container-title margin-s-20">
                What is Happening
              </span>
              {this.props.result.totalResult > 0 ? (
                <div className="home-all-event-coount">
                  {this.props.result.totalResult}
                </div>
              ) : null}
            </div>
            {this.props.result.totalResult > 0 ? (
              <div className="home-view-all">
                <div className="experieence-viewall-wrapper">
                  <p
                    onClick={() => this.props.history.push("/list/happening")}
                    className="margin-r-5"
                  >
                    View All
                  </p>
                  <img src="/icons/view-all.png" height="15px" width="15px" />
                </div>
              </div>
            ) : null}
          </div>
          <div>
            <div className="calender-event-container">
              {events.map((evt, key) => (
                <EventCard
                  key={key}
                  rowData={evt}
                  rowID={evt.id}
                  history={this.props.history}
                />
              ))}
            </div>
            {this.props.result.totalResult === 0 ? (
              <DefaultEventCard history={this.props.history} />
            ) : null}
          </div>

          <div className="home-heading-container slide-top">
            <div className="relative">
              {this.props.experiences.totalResult > 0 ? (
                <div className="home-all-event-coount">
                  {this.props.experiences.totalResult}
                </div>
              ) : null}
              <span className="home-heading-container-title margin-s-20">
                MyWellness Discounts
              </span>
            </div>
            {this.props.experiences.totalResult > 0 ? (
              <div className="home-view-all">
                {" "}
                <div className="experieence-viewall-wrapper">
                  <p onClick={this.handleCreateClick} className="margin-r-5">
                    View All
                  </p>
                  <img src="/icons/view-all.png" height="15px" width="15px" />
                </div>
              </div>
            ) : null}
          </div>

          <div>
            <div className="calender-event-container">
              {experiences.map((evt, key) => (
                <CreateEventCard
                  key={key}
                  rowData={evt}
                  rowID={evt.id}
                  history={this.props.history}
                />
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }

  onRefresh = () => {
    this.setState({ pullToRefresh: true });
    this.handleListExperience(0, LIMIT);
    this.fetchMyEvents();
  };

  handleListExperience = (page, limit) =>
    this.props.getHappeningList(
      page,
      limit,
      this.props.filter.categories,
      this.props.filter.countries,
      this.props.filter.opco,
      this.props.filter.price
    );

  handleEventClick = (id) => this.props.history.push(`/event/${id}`);

  handleFilterClick = () => this.props.history.push("/home/filter");

  handleCreateClick = () => this.props.history.push("/create");
}

const mapStateToProps = (state) => ({
  result: state.experienceReducer.result,
  isLoading: state.experienceReducer.isLoading,
  filter: state.experienceReducer.filter,
  filterCount: state.experienceReducer.filterCount,
  experiences: state.experienceReducer.experiences,
  profile: state.profileReducer.profile,
});

const mapDispatchToProps = (dispatch) => ({
  getExperienceList: (
    page,
    limit,
    category,
    country,
    opco,
    price,
    requirement,
    subcategory
  ) =>
    dispatch(
      getExperienceList(
        page,
        limit,
        category,
        country,
        opco,
        price,
        requirement,
        subcategory
      )
    ),
  getHappeningList: (page, limit, category, country, opco, price) =>
    dispatch(getHappeningList(page, limit, category, country, opco, price)),
  setListEmpty: (type) => dispatch(setListEmpty(type)),
  getFilterCount: () => dispatch(getFilterCount()),
  emptyFilterCount: () => dispatch(emptyFilterCount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
