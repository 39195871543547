export const listLeaderBoardAction = (data, type) => dispatch => {
    // console.log('data', data)
    dispatch({
        type: 'LIST_LEADERBOARD',
        payload: data,
        updateType: type
    })
}

export const listStepLeaderBoardAction = (data, type) => dispatch => {
    // console.log('data', data)
    dispatch({
        type: 'LIST_STEP_LEADERBOARD',
        payload: data,
        updateType: type
    })
}

export const listLeaderBoardTribeAction = (data, type) => dispatch => {
    // console.log('data', data)
    dispatch({
        type: 'LIST_LEADERBOARD_TRIBE',
        payload: data,
        updateType: type
    })
}