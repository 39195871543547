import React from "react";
import ContentLoader from "react-content-loader";
import { WingBlank } from "antd-mobile";

const SkeletonCardList = ({ width = 290, height = 235, ...rest }) => (
  <WingBlank size="md">
    <ContentLoader
      speed={1}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...rest}
    >
      <rect x="0" y="0" rx="3" ry="3" width={width} height="133" />
      <rect x="10" y="145" rx="3" ry="3" width="160" height="13" />
      <rect x="10" y="165" rx="3" ry="3" width="30" height="10" />
      <rect x="50" y="165" rx="3" ry="3" width="30" height="10" />
      <rect x="10" y="195" rx="0" ry="0" width="105" height="30" />
      <rect x={width - 100} y="140" rx="0" ry="0" width="98" height="47" />
    </ContentLoader>
  </WingBlank>
);

export default SkeletonCardList;
