import { Flex } from "antd-mobile";
import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchProfileData } from "../../../../apiManager/services/profileServices";

import Image from "../../../../components/common/Image/image";
import "./ProfileCard.scss";

class ProfileCard extends Component {
  state = {
    isExpanded: false,
  };

  componentDidMount() {
    this.props.fetchProfileData();
    if (this.props.isExpanded) {
      let isExpanded = this.props;
      this.setState({ isExpanded });
    }
  }

  render() {
    let { isExpanded } = this.state;
    let { name, point, image, badge, credit, tribe, position } =
      this.props.profile;

    if (!tribe) {
      tribe = [];
    }

    return (
      <div
        className={`profile-container ${
          isExpanded ? "profile-container-expanded" : "profile-container-normal"
        }`}
      >
        <img
          src={require("../../../../assets/icons/bg.svg")}
          className="overlay-image"
        ></img>
        <Flex align="start">
          <Flex.Item className="z-index-1">
            <Image
              src={image || "/icons/profile.png"}
              width="100%"
              height="100%"
              className="profile-picture"
              fallbackurl="/icons/profile.png"
            />
          </Flex.Item>
          <Flex.Item className="profile-content">
            <Flex direction="column" align="start">
              <Flex.Item className="profile-card-element">
                <span className="profile-card-text">
                  Hello&nbsp;
                  <span className="profile-card-name">{name}</span> !
                </span>
              </Flex.Item>

              <Flex.Item className="profile-card-element">
                <Flex>
                  <Flex.Item className="profile-card-sub-element">
                    <span className="title">Wallet balance</span>
                    <p className="value">{credit} credits</p>
                  </Flex.Item>
                  <Flex.Item className="profile-card-sub-element">
                    <span className="title">Total points</span>
                    <p className="value">{point} points</p>
                  </Flex.Item>
                  <Flex.Item className="profile-card-sub-element">
                    <span className="title">Ranking</span>
                    <p className="value"># {position}</p>
                  </Flex.Item>
                  {badge ? (
                    <div className="status-icon-container">
                      <img
                        alt="status icon"
                        className="status-icon"
                        src={badge}
                      />
                    </div>
                  ) : null}
                </Flex>
              </Flex.Item>
              {isExpanded ? (
                <Flex.Item className="profile-card-element">
                  <p className="sub-heading">Tribe</p>
                  <Flex wrap="wrap" className="profile-card-tags-container">
                    {tribe.map((item) => (
                      <Flex.Item>
                        <div className="profile-card-tag">
                          <p>{item}</p>
                        </div>
                      </Flex.Item>
                    ))}
                  </Flex>
                </Flex.Item>
              ) : null}
            </Flex>
          </Flex.Item>
        </Flex>
        <img
          alt="show more"
          className={`show-more ${isExpanded ? "show-less" : ""}`}
          src={require("../../../../assets/icons/show-more-down.png")}
          onClick={() => this.setState({ isExpanded: !isExpanded })}
        ></img>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profileReducer.profile,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProfileData: () => dispatch(fetchProfileData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard);
