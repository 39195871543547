export default (state = {
    result: {
        data: [],
    },
    details: {},
    historyResult: {
        items: []
    }
}, action) => {
    switch (action.type) {
        case 'LIST_STORES':
            if(state.result.items) {
                console.log("from store", [...state.result.items, ...action.payload.data])
            }
            return Object.assign({}, state, {
                result: {
                    items: action.updateType === 'FORCE_UPDATE' ? action.payload.data : [...state.result.items, ...action.payload.data],
                    totalResult: action.payload.total
                }
            })
        case 'LIST_DETIALS_STORE':
            // console.log("from store", action.payload.data)
            return Object.assign({}, state, {
                details: action.payload.data
            })

        case 'LIST_STORE_HISTORY':
            // console.log("from leader reducer", action)
            return Object.assign({}, state, {
                historyResult: {
                    items: action.updateType === 'FORCE_UPDATE' ? action.payload.data : [...state.historyResult.items, ...action.payload.data],
                    totalResult: action.payload.total
                }
            })
        default:
            return state
    }
}
