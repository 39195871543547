import React, { Component } from "react";


import AppNavigator from "./AppNavigator";
import "./App.scss";

class App extends Component {
  render() {
    return <AppNavigator />;
  }
}

export default App;
