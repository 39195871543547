import React from "react";
import ContentLoader from "react-content-loader";
import { WingBlank } from "antd-mobile";

const SkeletonLeaderCard = ({ width = 330, height = 70, ...rest }) => (
  <ContentLoader
    speed={1}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...rest}
  >
    <circle cx="45" cy="24" r="24" />

    <rect x="85" y="0" rx="3" ry="3" width="70" height="17" />
    <rect x="85" y="25" rx="0" ry="0" width="50" height="10" />
    <rect x={width - 135} y="10" rx="0" ry="0" width="60" height="14" />
    <rect x={width - 55} y="0" rx="10" ry="10" width="30" height="40" />
    <rect x={0} y={height - 10} rx="20" ry="20" width={width} height="2" />

    {/* <rect x="160" y={40} rx="3" ry="3" width="50" height="15" /> */}
    {/* <rect x="350" y="40" rx="0" ry="0" width="30" height="40" /> */}
  </ContentLoader>
);

export default SkeletonLeaderCard;
