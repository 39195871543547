import { Flex } from 'antd-mobile';
import React, { useState } from 'react'

import './counter.scss'


export const Counter = ({ value, onChange }) => {

    const count = value || 0;

    const change = (val) => {
        onChange(val)
    }

    return (
        <Flex justify="center">
            <Flex.Item>
                <div className="counter-button" onClick={() => change(count - 1)}>
                    -
                </div>
            </Flex.Item>
            <Flex.Item>
                <p className="counter-text">{count}</p>
            </Flex.Item>
            <Flex.Item>
                <div className="counter-button" onClick={() => change(count + 1)}>
                    +
                </div>
            </Flex.Item>
        </Flex>
    )
}