import React, { useLayoutEffect, useState } from "react";
import { Button, Modal } from "antd-mobile";

import {
  digimeConnect,
  appleHealthConnect,
} from "../../../apiManager/services/stepsChallengeServices";

import { servicesData } from "../constants";

const ConnectAccount = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const connectRequest = (item) => {
    setSelectedItem(item.id);
    if (item.deeplink) {
      appleHealthConnect().then((res) => {
        if (res.data.result) window.location.replace(item.deeplink);
        else {
          modalError();
          setSelectedItem(null);
        }
      });
    } else
      digimeConnect(item.id)
        .then((res) => {
          if (res.result) window.location.href = res.url;
          else {
            modalError();
            setSelectedItem(null);
          }
        })
        .catch(() => {
          modalError();
          setSelectedItem(null);
        });
  };

  const modalError = () =>
    Modal.alert("Something went wrong !", "Please try again.", [
      { text: "OK" },
    ]);

  function isIOS() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
 
    if (/iPad|iPhone|iPod|AppleWebKit/.test(userAgent) && !window.MSStream) {
      return true;
    }
    return false;
  }

  return (
    <>
      <div className="contents">
        <div className="row text-left">
          <div>
            <h2>Connect with your Account</h2>
            <p className="message-text">
              Select your fitness account and connect 
            </p>
          </div>
        </div>
        {isIOS()}
        {servicesData
          .filter((item) => {
            if (item.deeplink) return isIOS();
            else return true;
          })
          .map((item, key) => (
            <div key={key} className="text-left connect-options-container">
              <div className="image-connect-container">
                <img alt="connect-option-logo" src={item.logo} />
              </div>
              <div className="connect-options-text">
                <p>{`Connect with ${item.name}`}</p>
                <div>
                  <Button
                    loading={selectedItem === item.id}
                    size="small"
                    onClick={() => connectRequest(item)}
                    className="connect-button"
                  >
                    Connect
                  </Button>
                </div>
              </div>
              <hr />
            </div>
          ))}
      </div>
    </>
  );
};

export default ConnectAccount;
