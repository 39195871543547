import axios from "axios";

import Token from "../token/tokenValue";

export const httpGETRequest = (url, data, timeout) => {
  // console.log(data)
  return axios.get(url, {
    params: data,
    headers: { Authorization: Token.getToken() },
    timeout: timeout || 0,
  }); // TODO get this dynamic from url
};

export const httpPOSTRequest = (url, data) => {
  // console.log(data)
  return axios.post(url, data, {
    headers: { Authorization: Token.getToken() },
  }); // TODO get this dynamic from url
};

export const httpGETRequestToken = (url, token) => {
  // console.log('token')
  return axios.get(url, { headers: { Authorization: token } }); // TODO get this dynamic from url
};
