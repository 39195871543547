import { Icon, NavBar } from 'antd-mobile'
import React, { component } from 'react'
import DEEPLINK from '../../../apiManager/endpoints/deeplink'
import './navBar.scss'

export const NavigationBar = ({history, isDiscountVisible}) => {

    const handleCreateClick = () => history.push('/create')


    return (
        <NavBar
            mode='light'
            leftContent={
                <Icon key='1' type='left' className='navbar-back-icon' onClick={() => { history? history.goBack() :window.open(DEEPLINK.APPLICATION_BACK) }} />
            }
            className='navbar-container'
            rightContent={
                <div onClick={handleCreateClick}>
                    {/* <Icon key='0' type='plus' style={{ width: '13px', height: '13px', color: '#9F803E' }} /> */}
                    {isDiscountVisible ? <div className="discount-button"><p>%</p><p>Discounts</p></div> : null}
                </div>
            }
        > MyWellness
        </NavBar>
    )

}
