import { Card, Flex, WhiteSpace } from 'antd-mobile'
import React from 'react'
import { TimeSince } from '../../../../components/utils/date'
import './walletListCard.scss'

export function WalletListCard({ data }) {
    let { name, status, quantity, credits, total, image, createdAt } = data

let mafTag;
if (status=="Pending"){  mafTag =<span className="maf-gray">{status}</span> }
				else if( status=="Delivered") { mafTag=<span className="maf-green">{status}</span>	}
				else {  mafTag =<span className="maf-red">{status}</span> }
				

    return ( 
        <div className="wallet-card-main-container">
            <div>
                <img
                    className="wallet-card-img"
                    src={image}
                    alt=""
                ></img>
            </div>

            <div className="wallet-sub-container">
                <p className="wallet-card-title">{name}</p>
                <span className="wallet-card-credits">{total} credits</span>
                <WhiteSpace size='lg' />
                <p className="wallet-card-time">{TimeSince(new Date(createdAt))}</p>

            </div>

            <Flex direction="column" justify="between">
                <Flex.Item>
                    <span className="wallet-card-new-credit">{quantity} x {credits} Credits</span>
                </Flex.Item>
                <Flex.Item>
				<div className="maf-tag">{mafTag}</div>
					
                </Flex.Item>
            </Flex>
        </div>
	
    )
}
