import React, { Component } from "react";
import "./ExperienceDetails.scss";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import enUs from "antd-mobile/lib/date-picker/locale/en_US";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import InviteScreen from "../Invite/Invite";

import { Redirect } from "react-router-dom";

import {
  Carousel,
  Accordion,
  Modal,
  Stepper,
  Flex,
  List,
  Switch,
  Toast,
  PickerView,
  ActivityIndicator,
  NavBar,
} from "antd-mobile";
import {
  getSuggestedUsers,
  getSearchedUsers,
  getExperienceDetails,
  getBookingAvailability,
  createBooking,
  getInviteFilter,
} from "../../apiManager/services/experienceServices";

import Image from "../../components/common/Image/image";
import DEEPLINK from "../../apiManager/endpoints/deeplink";

class ExperienceDetailsScreen extends Component {
  state = {
    isLoading: true,
    isBookingInProgress: false,
    isModalVisible: false,
    eventData: {
      images: [],
    },
    step: 1,
    numberOfFamilyMembers: 0,
    numberOfFamilyMembersToDisplay: 0,
    isShareDetails: false,
    selectMonth: false,
    isInvited:false,
    isDateSelected: false,
    query: "",
    filterData: {},
    selectedFilter: "",
    filter: {
      opco: [],
      bu: [],
      country: [],
    },
    tempFilter: {},
    hasOverflowingChildren: false,
    isInviteFilterPopUpVisible: false,
    availablespecificSlot: [],
    isConfirmBookingEnabled: false,
    isMapModelVisible: false,
    fetchLoading: false,
    ispopupLoading: true,
    isBookingLoading: false,
    selectedInviteeList: [],
    inviteOldValue: 0,
    inviteUpdated: false,
    specificSelected: {
      slot: null,
    },
    options: ["one", "two", "three"],
    isDescriptionFullView: false,
    data: [
      "https://dynaimage.cdn.cnn.com/cnn/q_auto,w_1280,c_fill,g_auto,h_720,ar_16:9/http%3A%2F%2Fcdn.cnn.com%2Fcnnnext%2Fdam%2Fassets%2F170606121206-italy---travel-destination---shutterstock-517522957.jpg",
      "https://dynaimage.cdn.cnn.com/cnn/q_auto,w_1280,c_fill,g_auto,h_720,ar_16:9/http%3A%2F%2Fcdn.cnn.com%2Fcnnnext%2Fdam%2Fassets%2F170606121206-italy---travel-destination---shutterstock-517522957.jpg",
      "https://dynaimage.cdn.cnn.com/cnn/q_auto,w_1280,c_fill,g_auto,h_720,ar_16:9/http%3A%2F%2Fcdn.cnn.com%2Fcnnnext%2Fdam%2Fassets%2F170606121206-italy---travel-destination---shutterstock-517522957.jpg",
    ],
  };

  constructor() {
    super();
    this.searchRef = React.createRef();
  }

  componentDidUpdate() {
    const element = this.element;
    // Things involving accessing DOM properties on element
    // In the case of what this question actually asks:
    if (element) {
      const _hasOverflowingChildren =
        element.offsetHeight < element.scrollHeight ||
        element.offsetWidth < element.scrollWidth;

      if (this.state.hasOverflowingChildren !== _hasOverflowingChildren) {
        this.setState({ hasOverflowingChildren: _hasOverflowingChildren });
      }
    }
  }

  componentDidMount() {
    this.fetchEventDetails(this.props.match.params.id);
  }

  componentWillMount() {
    this._setEnv();
  }

  addToSelectedList = (id) => {
    const selectedList = this.state.selectedInviteeList;
    const index = selectedList.indexOf(id);
    if (index > -1) {
      selectedList.splice(index, 1);
    } else {
      selectedList.push(id);
    }
    this.setState({ selectedInviteeList: selectedList }, () =>
      console.log("selectedList", this.state.selectedInviteeList)
    );
    this.state.isInvited=true
  if (this.state.isDateSelected){
    this.setState({ isConfirmBookingEnabled: true });
  }
  };

  _getMonthLabel = (monthNumber) => {
    let dayWord;
    switch (monthNumber) {
      case 0:
        dayWord = "December";
        break;
      case 1:
        dayWord = "January";
        break;
      case 2:
        dayWord = "February";
        break;
      case 3:
        dayWord = "March";
        break;
      case 4:
        dayWord = "April";
        break;
      case 5:
        dayWord = "May";
        break;
      case 6:
        dayWord = "June";
        break;
      case 7:
        dayWord = "July";
        break;
      case 8:
        dayWord = "August";
        break;
      case 9:
        dayWord = "September";
        break;
      case 10:
        dayWord = "October";
        break;
      case 11:
        dayWord = "November";
        break;
      default:
        console.log("day error");
    }
    return dayWord;
  };

  _setEnv = () => {
    const now = moment();
    var _day = moment(now).day();
    var _month = moment(now).month() + 1;
    var _year = now.year();
    let _monthYearArray = [];
    for (let i = 0; i < 12; i++) {
      const _tempMonth = (_month + i) % 12;
      // console.log("temp month", _tempMonth);
      const _tempYear = _year + parseInt((_month + i) / 13);
      _monthYearArray = [
        ..._monthYearArray,
        {
          value: _tempMonth + "-" + _tempYear,
          label: this._getMonthLabel(_tempMonth) + " " + _tempYear,
        },
      ];
    }
    if (
      _day !== this.state.day ||
      _month !== this.state.month ||
      _year !== this.state.year
    ) {
      this.setState({
        day: _day,
        month: _month,
        year: _year,
        monthYearArray: _monthYearArray,
      });
    }
  };

  fetchEventDetails = (id) => {
    getExperienceDetails(id, this._success, this._failure);
  };

  _success = (data) => {
    // console.log(data);
    this.setState({ isLoading: false, eventData: data.data.items });
  };

  _failure = (data) => {
    this.setState({ isLoading: false });
    // console.log(data);
    Toast.fail("Network request failed !!!", 1);
  };

  handleModalButton = () => {
    if (!this.state.isModalVisible && this.state.numberOfFamilyMembers === 0) {
      this.setState({ numberOfFamilyMembers: 1, inviteOldValue: 0 });
    }
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };

  submitModal = () => {
    this.setState({
      numberOfFamilyMembersToDisplay: this.state.numberOfFamilyMembers,
    });
    this.closeModalButton();
  };

  closeModalButton = () => {
    this.setState({
      isModalVisible: !this.state.isModalVisible,
      inviteOldValue: this.state.numberOfFamilyMembersToDisplay,
    });
  };

  handlePrevButton = () => {
    // console.log("next button pressed");
    const { val } = this.state;
    this.setState({ val: val - 1 });
  };

  handleJoinClick = () => {
    // console.log("handle join click");
    this.setState({ isModalVisible: true });
  };

  onChange = (numberOfFamilyMembers) => {
    this.setState({ numberOfFamilyMembers });
  };

  _renderSteppr = () => {
    return (
      <div className="event-qauestion-container">
        <img src="/icons/family.png" width="50" height="50" />
        <p className="font-12">How many people are you booking for?</p>
        <div className="event-stepper-container">
          <Stepper
            style={{ width: "100%", minWidth: "100px" }}
            showNumber
            max={10}
            min={0}
            step={1}
            onChange={this.onChange}
            value={this.state.numberOfFamilyMembers}
          />
        </div>
      </div>
    );
  };

  _filterData = () => {
    const _query = this.state.query;
    this.setState({ ispopupLoading: true });
    const _opco =
      this.state.filter.opco.length === 0
        ? null
        : this.state.filter.opco.toString();
    const _bu =
      this.state.filter.bu.length === 0
        ? null
        : this.state.filter.bu.toString();
    const _country =
      this.state.filter.country.length === 0
        ? null
        : this.state.filter.country.toString();

    getSearchedUsers(
      this.props.match.params.id,
      _query,
      _opco,
      _bu,
      _country,
      this._successSearch,
      this._failureSuggested
    );
  };

  handleInviteClick = () => {
    this.setState({ isInvitePopUpVisible: true, ispopupLoading: true });
    this.fetchSugestedInvitees();
  };

  fetchSugestedInvitees = () => {
    getSuggestedUsers(
      this.props.match.params.id,
      this._successSuggested,
      this._failureSuggested
    );
    getInviteFilter(this._success_filterData, this._failure_filterData);
  };

  _success_filterData = (data) => {
    // console.log("data", data);
    if (data && data.data && data.data.data) {
      this.setState({ filterData: data.data.data });
    }
  };

  _failure_filterData = (err) => {
    Toast.fail(err.message, 1);
    // console.log(err);
  };

  _successSuggested = (resp) => {
    // console.log("resp", resp, this.props.match.params.expId);
    this.setState({
      ispopupLoading: false,
      inviteeList: resp.data.data,
      suggestedList: JSON.parse(JSON.stringify(resp.data.data)),
    });
  };

  _failureSuggested = (err) => {
    this.setState({ ispopupLoading: false });
    Toast.fail(err.message, 1);
  };

  fetchQueryList = (query) => {
    this.setState({ query: query });
    const _opco =
      this.state.filter.opco.length === 0
        ? null
        : this.state.filter.opco.toString();
    const _bu =
      this.state.filter.bu.length === 0
        ? null
        : this.state.filter.bu.toString();
    const _country =
      this.state.filter.country.length === 0
        ? null
        : this.state.filter.country.toString();
    if (query.length >= 3) {
      this.setState({ ispopupLoading: true });
      getSearchedUsers(
        this.props.match.params.id,
        query,
        _opco,
        _bu,
        _country,
        this._successSearch,
        this._failureSuggested
      );
    } else {
      this.setState({
        ispopupLoading: false,
        inviteeList: this.state.suggestedList,
      });
    }
  };

  resetSearch = () => {
    this.searchRef.current.value = "";
    this.fetchQueryList("");
  };

  resetFilter = () => {
    this.setState({
      filter: {
        opco: [],
        bu: [],
        country: [],
      },
    });
    this.fetchQueryList(this.state.query);
  };

  checkIsFilterAvailable = () => {
    return (
      this.state.filter.opco.length !== 0 ||
      this.state.filter.bu.length !== 0 ||
      this.state.filter.country.length !== 0
    );
  };

  _successSearch = (resp) => {
    // console.log("resp", resp, this.props.match.params.expId);
    this.setState({ ispopupLoading: false, inviteeList: resp.data.data });
  };

  goToMap(location) {
    if (location && location.lat) {
      var win = window.open(
        `https://maps.google.com/?q=${location.lat},${location.lng}`,
        "_blank"
      );
      win.focus();
    }
  }

  _addFilter = (value, label) => {
    const _filter = this.state.tempFilter;
    if (_filter[label].includes(value)) {
      const _index = _filter[label].indexOf(value);
      _filter[label] = [
        ..._filter[label].slice(0, _index),
        ..._filter[label].slice(_index + 1, _filter[label].length),
      ];
    } else {
      _filter[label].push(value);
    }
    this.setState({ tempFilter: _filter }, () => {
      console.log("temo", this.state.tempFilter, "filter", this.state.filter);
    });
  };

  _clearAll = () => {
    const _filter = this.state.tempFilter;
    if (this.state.selectedFilter === "Opco") {
      _filter.opco = [];
    } else if (this.state.selectedFilter === "Business Unit") {
      _filter.bu = [];
    } else if (this.state.selectedFilter === "Country") {
      _filter.country = [];
    }

    this.setState({ tempFilter: _filter });
  };

  _renderFilterList = () => {
    let _data = [];
    let _label = "";
    // console.log(this.state.filterData);
    if (this.state.selectedFilter === "Opco") {
      _data = this.state.filterData.opco;
      _label = "opco";
    } else if (this.state.selectedFilter === "Business Unit") {
      _data = this.state.filterData.bu;
      _label = "bu";
    } else if (this.state.selectedFilter === "Country") {
      _data = this.state.filterData.country;
      _label = "country";
    }
    return _data.map((item) => {
      return (
        <Flex className="invite-dashed-line">
          <Flex.Item className="flex-1 margin-l-15">
            <p>{item}</p>
          </Flex.Item>
          <Flex.Item className="userdetailscheckcdiv">
            <div className="round invite-user-filter-round">
              <input
                type="checkbox"
                id={item}
                onChange={() => this._addFilter(item, _label)}
                checked={this.state.tempFilter[_label].includes(item)}
              />
              <label htmlFor={item} />
            </div>
          </Flex.Item>
        </Flex>
      );
    });
  };

  _closeInvitePopup = () => {
    this.setState({
      isModalVisible: false,
      numberOfFamilyMembers: this.state.inviteOldValue,
      inviteOldValue: false,
    });
  };

  render() {
    let { points } = this.state.eventData;
    let { isBookingInProgress, isConfirmBookingEnabled  } = this.state;

    const isButtonDisabled = isBookingInProgress && !isConfirmBookingEnabled;
    
    return (
      <div>
        {this.state.isLoading ? (
          <div className="loader">
            <img src="/icons/MAF.gif" />
          </div>
        ) : this.state.isInvitePopUpVisible ? (
          this.state.isInviteFilterPopUpVisible ? (
            <div className="popup-main-container">
              <NavBar
                mode="light"
                leftContent={
                  <p className="font-12 color-gray" onClick={this._clearAll}>
                    Clear All
                  </p>
                }
                rightContent={
                  <img
                    src="../../icons/close-r.png"
                    width="50px"
                    height="50px"
                    onClick={() =>
                      this.setState({ isInviteFilterPopUpVisible: false })
                    }
                  />
                }
                className="navbar-container"
              >
                <div className="text-center">
                  <p className="font-14">Filter</p>
                  <p className="color-gray font-12">
                    {this.state.selectedFilter}
                  </p>
                </div>
              </NavBar>
              <div className="padding-t-70">{this._renderFilterList()}</div>
              <Flex.Item>
                <div className="filter-footer">
                  <div
                    className="filter-footer-button"
                    onClick={() =>
                      this.setState(
                        {
                          filter: JSON.parse(
                            JSON.stringify(this.state.tempFilter)
                          ),
                        },
                        () => {
                          this._filterData();
                          this.setState({ isInviteFilterPopUpVisible: false });
                        }
                      )
                    }
                  >
                    <p>Apply</p>
                  </div>
                </div>
              </Flex.Item>
            </div>
          ) : (
            <div>
              <div>
                <div className="popup-main-container">
                  <NavBar
                    mode="light"
                    leftContent={
                      <img
                        className="arrowback"
                        src="../../icons/back-gold.png"
                        onClick={() =>
                          this.setState({ isInvitePopUpVisible: false })
                        }
                      />
                    }
                    className="navbar-container"
                  >
                    {" "}
                    Invite
                  </NavBar>
                  <Flex>
                    <Flex.Item className="searchdiv">
                      <div className="ant-col-24">
                        <input
                          ref={this.searchRef}
                          type="text"
                          name="search"
                          className="searchbox"
                          placeholder="Search.."
                          onChange={(event) =>
                            this.fetchQueryList(event.target.value)
                          }
                          style={{
                            width: this.checkIsFilterAvailable()
                              ? "80%"
                              : "90%",
                          }}
                        />
                        {this.state.query.length ? (
                          <button
                            className="close-icon"
                            type="reset"
                            onClick={() => this.resetSearch()}
                          />
                        ) : null}
                        {this.checkIsFilterAvailable() ? (
                          <span
                            className="clear"
                            onClick={() => this.resetFilter()}
                            style={{
                              marginLeft: this.state.query.length
                                ? "0%"
                                : "10px",
                            }}
                          >
                            Clear
                          </span>
                        ) : null}
                      </div>
                    </Flex.Item>
                  </Flex>
                  <div>
                    <Flex className="bg-white margin-t-15 invite-filter-container">
                      <Flex.Item
                        className="flex-1 text-center margin-t-10 margin-b-10 invite-filter-item padding-top-5  seperation-border-right"
                        onClick={() => {
                          this.setState(
                            {
                              selectedFilter: "Opco",
                              tempFilter: JSON.parse(
                                JSON.stringify(this.state.filter)
                              ),
                            },
                            () => {
                              this.setState({
                                isInviteFilterPopUpVisible: true,
                              });
                            }
                          );
                        }}
                      >
                        <p
                          className={
                            this.state.filter.opco.length > 0
                              ? "display-line margin-r-5 invite-filter-text-active"
                              : "display-line margin-r-5 font-color-gray"
                          }
                        >
                          OpCo
                        </p>
                        <img
                          className="rotate-90"
                          width="8px"
                          height="10px"
                          src="../../icons/back-gold.png"
                        />
                      </Flex.Item>
                      <Flex.Item
                        className="flex-1 text-center margin-t-10 margin-b-10 invite-filter-item padding-top-5"
                        onClick={() => {
                          this.setState(
                            {
                              selectedFilter: "Business Unit",
                              tempFilter: JSON.parse(
                                JSON.stringify(this.state.filter)
                              ),
                            },
                            () => {
                              this.setState({
                                isInviteFilterPopUpVisible: true,
                              });
                            }
                          );
                        }}
                      >
                        <p
                          className={
                            this.state.filter.bu.length > 0
                              ? "display-line margin-r-5 invite-filter-text-active"
                              : "display-line margin-r-5 font-color-gray"
                          }
                        >
                          Business Unit
                        </p>
                        <img
                          className="rotate-90"
                          width="8px"
                          height="10px"
                          src="../../icons/back-gold.png"
                        />
                      </Flex.Item>
                      <Flex.Item
                        className="flex-1 text-center margin-t-10 margin-b-10 invite-filter-item padding-top-5 seperation-border-left"
                        onClick={() => {
                          this.setState(
                            {
                              selectedFilter: "Country",
                              tempFilter: JSON.parse(
                                JSON.stringify(this.state.filter)
                              ),
                            },
                            () => {
                              this.setState({
                                isInviteFilterPopUpVisible: true,
                              });
                            }
                          );
                        }}
                      >
                        <p
                          className={
                            this.state.filter.country.length > 0
                              ? "display-line margin-r-5 invite-filter-text-active"
                              : "display-line margin-r-5 font-color-gray"
                          }
                        >
                          Country
                        </p>
                        <img
                          className="rotate-90"
                          width="8px"
                          height="10px"
                          src="../../icons/back-gold.png"
                        />
                      </Flex.Item>
                    </Flex>
                  </div>
                  {this.state.ispopupLoading ? (
                    <div className="invite-loader">
                      <img src="/icons/MAF.gif" />
                    </div>
                  ) : (
                    <div>
                      {this.state.inviteeList.map((invitee, id) => (
                        <Flex className="borderlinedotted" key={id}>
                          <Flex.Item className="userdetailsimagediv">
                            <Image
                              className="personimage"
                              src={`${invitee.image || "/icons/profile.png"}`}
                              height="45"
                              width="45"
                              fallbackurl="/icons/profile.png"
                              onClick={() =>
                                invitee && invitee.empid
                                  ? window.open(DEEPLINK.EMP_ID + invitee.empid)
                                  : null
                              }
                            />
                          </Flex.Item>

                          <Flex.Item className="userdetailsdescdiv">
                            <span className="spanfontsize">{invitee.name}</span>
                            {invitee.department ? <br /> : null}
                            {invitee.department ? (
                              <span>{invitee.department || ""}</span>
                            ) : null}
                            {invitee.opco ? <br /> : null}
                            {invitee.opco ? (
                              <span>{invitee.opco || ""}</span>
                            ) : null}
                          </Flex.Item>
                          <Flex.Item className="userdetailscheckcdiv">
                            <div className="round">
                              <input
                                type="checkbox"
                                id={invitee.id}
                                onChange={() =>
                                  this.addToSelectedList(invitee.id)
                                }
                                checked={this.state.selectedInviteeList.includes(
                                  invitee.id
                                )}
                              />
                              <label htmlFor={invitee.id} />
                            </div>
                          </Flex.Item>
                        </Flex>
                      ))}
                    </div>
                  )}
                  <Flex>
                    <Flex.Item>
                      {" "}
                      {this.state.selectedInviteeList.length ? (
                        <div className="filter-footer">
                          <div
                            className="filter-footer-button"
                            onClick={() =>
                              this.setState({ isInvitePopUpVisible: false })
                            }
                          >
                            <p>Update</p>
                          </div>
                        </div>
                      ) : null}
                    </Flex.Item>
                  </Flex>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="event-main-container fade-in">
            <div>
              <ActivityIndicator
                toast
                size="large"
                text="Loading..."
                color="white"
                animating={this.state.isBookingLoading}
              />
              <Modal
                popup
                visible={this.state.isMapModelVisible}
                transparent
                maskClosable
                animationType="slide-up"
                onClose={() => this.setState({ isMapModelVisible: false })}
                wrapProps={{ onTouchStart: this.onWrapTouchStart }}
              >
                <div className="margin-20" />
                <div className="margin-20">
                  {this.state.eventData &&
                  this.state.eventData.location &&
                  this.state.eventData.location.lat ? (
                    <img
                      className="margin-t-15"
                      width="100%"
                      height="180px"
                      src={`https://maps.googleapis.com/maps/api/staticmap?center=${this.state.eventData.location.lat}${this.state.eventData.location.lng}&zoom=15&size=600x400&markers=${this.state.eventData.location.lat},${this.state.eventData.location.lng}&key=AIzaSyD9IVRFkKjD4V6piuPlA7hHhrAusA0G2wc`}
                    />
                  ) : null}
                </div>
                <div>
                  <div
                    className="filter-footer-button color-yellow"
                    onClick={() =>
                      this.goToMap(
                        this.state.eventData.location || { lat: "", long: "" }
                      )
                    }
                  >
                    <p className="padding-t-12">Open in Google Maps</p>
                  </div>
                </div>
                <div
                  className="event-map-close"
                  onClick={() => this.setState({ isMapModelVisible: false })}
                >
                  <img width="40px" height="40px" src="/icons/close-r.png" />
                </div>
              </Modal>

              <Modal
                popup
                visible={this.state.isModalVisible}
                transparent
                animationType="slide-up"
                onClose={() => {
                  this._closeInvitePopup();
                }}
                wrapProps={{ onTouchStart: this.onWrapTouchStart }}
              >
                {this._renderSteppr()}
                <div
                  className="filter-footer-button"
                  onClick={this.submitModal}
                >
                  <p>Update</p>
                </div>
                <div
                  className="event-map-close"
                  onClick={() => this._closeInvitePopup()}
                >
                  <img width="40px" height="40px" src="/icons/close-r.png" />
                </div>
              </Modal>

              {this.state.monthYearArray ? (
                <Modal
                  popup
                  visible={this.state.selectMonth}
                  transparent
                  animationType="slide-up"
                  wrapProps={{ onTouchStart: this.onWrapTouchStart }}
                  onClose={this._toggleMonthSelecter}
                >
                  <div className="experience-month-chooser-title">
                    <p>Select Month</p>
                    <img
                      src="/icons/close-r.png"
                      width="50"
                      height="50"
                      onClick={this._toggleMonthSelecter}
                    />
                  </div>
                  <PickerView
                    onChange={this.handleOnChange}
                    value={this.state.value}
                    data={this.state.monthYearArray}
                    cascade={false}
                  />
                </Modal>
              ) : null}
            </div>
            {!isBookingInProgress && (
              <div className="top-container">
                <div>
                  <Carousel
                    autoplay={false}
                    infinite
                    dots={
                      !(
                        this.state.eventData &&
                        this.state.eventData.images &&
                        this.state.eventData.images.length <= 1
                      )
                    }
                    beforeChange={(from, to) =>
                      console.log(`slide from ${from} to ${to}`)
                    }
                    className="event-carousel"
                    afterChange={(index) => console.log("slide to", index)}
                  >
                    {this.state.eventData.images.map((val) => (
                      <a
                        key={val}
                        style={{
                          display: "inline-block",
                          width: "100%",
                          height: this.state.imgHeight,
                        }}
                      >
                        <img
                          src={val.sd}
                          alt=""
                          style={{ width: "100%", verticalAlign: "top" }}
                        />
                      </a>
                    ))}
                  </Carousel>
                  <div
                    className="event-back-button"
                    onClick={this.handleBackClick}
                  >
                    <img
                      className="arrow-back-details"
                      src="/icons/back-gold.png"
                      width="10px"
                      height="15px"
                    />
                  </div>
                  {points ? (
                    <div className="maf-tag event-details-points">
                      <span>{points} points</span>
                    </div>
                  ) : null}
                </div>
                <div className="event-title-container bg-white postition-relative">
                  <h2 className="event-title">{this.state.eventData.name}</h2>
                  <div className="tag-container">
                    <span>{this.state.eventData.category}</span>
                  </div>
                  <div className="am-flexbox-item tag-container">
                    <span>{this.state.eventData.required}</span>
                  </div>

                  {this.state.eventData && this.state.eventData.address ? (
                    <h4 className="experience-location-label">Location</h4>
                  ) : null}
                  {this.state.eventData && this.state.eventData.address ? (
                    <h5 className="experience-location">
                      {this.state.eventData.address}
                    </h5>
                  ) : null}
                  <div className="experience-card-content-container">
                    <div className="experience-card-content-data-container">
                      <span className="experience-card-main-text">
                        {this.state.eventData.discount}
                      </span>
                      <p className="experience-card-main-sub-text">off</p>
                    </div>
                    <div className="experience-card-content-data-container">
                      <p className="line-height-5">
                        <strike className="font-9 color-gray">
                          {this.state.eventData.currency || "AED"}{" "}
                          {this.state.eventData.price}
                        </strike>
                      </p>
                      <span className="experience-card-main-text">
                        {this.state.eventData.currency || "AED"}{" "}
                        {this.state.eventData.OfferPrice}
                      </span>
                      <p className="experience-card-main-sub-text">
                        {this.state.eventData.priceUnit}
                      </p>
                    </div>
                  </div>
                  {this.state.eventData.location ? (
                    <div className="experience-tag-container-time font-8 b-25">
                      <img src="/icons/navigation.png" />
                      <span
                        className="color-white"
                        onClick={() =>
                          this.setState({ isMapModelVisible: true })
                        }
                      >
                        Open in Maps
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className="event-details-container">
                  <h4 className="event-details-header">About</h4>
                  {!this.state.isDescriptionFullView ? (
                    <div
                      className="event-description-closed padding-t-1"
                      ref={(el) => {
                        this.element = el;
                      }}
                    >
                      <p className="event-description">
                        {ReactHtmlParser(this.state.eventData.about)}
                      </p>
                    </div>
                  ) : (
                    <div
                      className="event-description-open padding-t-1"
                      ref={(el) => {
                        this.element = el;
                      }}
                    >
                      <p className="event-description">
                        {ReactHtmlParser(this.state.eventData.about)}
                      </p>
                    </div>
                  )}
                </div>
                {this.state.hasOverflowingChildren ? (
                  this.state.isDescriptionFullView ? (
                    <div className="text-center padding-t-5 rotate-180">
                      <img
                        className="bounce bounce-2"
                        src="/icons/show-more-down.png"
                        width="10px"
                        onClick={this.handleOpenFullDescriptopn}
                      />
                    </div>
                  ) : (
                    <div className="text-center padding-b-5">
                      <img
                        className="bounce bounce-2"
                        src="/icons/show-more-down.png"
                        width="10px"
                        onClick={this.handleOpenFullDescriptopn}
                      />
                    </div>
                  )
                ) : (
                  <div className="text-center padding-b-5">
                    <img
                      className="bounce bounce-2"
                      src="/icons/show-more-up.png"
                      width="10px"
                      onClick={this.handleOpenFullDescriptopn}
                    />
                  </div>
                )}
              </div>
            )}

            {isBookingInProgress && (
              <div className="top-bottom">
                <NavBar
                  mode="light"
                  leftContent={
                    <p className="font-18 color-gray" onClick={this._clearAll}>
                      Booking
                    </p>
                  }

                  rightContent={
                    <img
                      src="../../icons/close-r.png"
                      width="50px"
                      height="50px"
                      onClick={() =>
                        this.setState({ isBookingInProgress: false })
                      }
                    />
                  }
                  className="navbar-container"
                ></NavBar>
                <div className="experience-seperator" />
                <div className="experience-calender-container padding-32">
                 <p className="bookingInfo">Complete the below to successfully submit a booking request</p>
                  <List.Item
                    arrow="horizontal"
                    onClick={this._toggleMonthSelecter}
                  >
                    {this._getMonthLabel(this.state.month % 12) +
                      " " +
                      this.state.year}
                  </List.Item>
                  <div className="experience-calender-wrapper">
                    {this.state.eventData && this.state.eventData.dedline
                      ? this._renderCalender(this.state.month, this.state.year)
                      : null}
                  </div>
                </div>
                <div>
                  {this.state.fetchLoading
                    ? this._renderLoading()
                    : this.state.eventData &&
                      this.state.eventData.experienceHours &&
                      this.state.eventData.experienceHours !==
                        "Specific Timeslot"
                    ? this._renderAvailabilityFixed()
                    : this._renderAvailabilityFlexible()}
                </div>
                <List.Item
                  arrow="horizontal"
                  className="experience-list-item experience-borer"
                  onClick={this.handleInviteClick}
                >
                  Invite Colleagues
                  {this.state.selectedInviteeList.length > 0 ? (
                    <div className="invite-count">
                      {this.state.selectedInviteeList.length}
                    </div>
                  ) : null}
                </List.Item>
                <List.Item
                  arrow="horizontal"
                  className="experience-list-item experience-borer"
                  onClick={this.handleModalButton}
                >
                  Invite Family and Friends
                  {this.state.numberOfFamilyMembersToDisplay > 0 ? (
                    <div className="invite-count experience-invite-count">
                      {this.state.numberOfFamilyMembersToDisplay}
                    </div>
                  ) : null}
                </List.Item>
                <div>
                  <div className="experience-toggle-container">
                    <p className="experience-toggle-label">
                      I'd like to share my contact details with the vendor
                    </p>
                    <Switch
                      platform="ios"
                      color="#8A1538"
                      checked={this.state.isShareDetails}
                      className="experience-toggle-button"
                      onChange={() => {
                        this.setState({
                          isShareDetails: !this.state.isShareDetails,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="experience-terms-conditions">
                  <Accordion>
                    <Accordion.Panel header="Terms and Conditions">
                      {this.state.eventData.type !== "byInternal" ? (
                        <p>
                          {this.state.eventData.priceUnit != "Per Person" ? (
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: "400",
                                color: "gray",
                                margin: 10,
                                marginTop: 20,
                                textAlign: "center",
                              }}
                            >
                              The price outlined is{" "}
                              {this.state.eventData.priceUnit
                                ? this.state.eventData.priceUnit.toLowerCase()
                                : this.state.eventData.priceUnit}
                              . The maximum capacity per{" "}
                              {this.state.eventData.priceUnit
                                ? this.state.eventData.priceUnit
                                    .slice(
                                      4,
                                      this.state.eventData.priceUnit.length
                                    )
                                    .toLowerCase()
                                : this.state.eventData.priceUnit}{" "}
                              is {this.state.eventData.capacity}{" "}
                              {this.state.eventData.capacity == 1
                                ? " participant"
                                : " participants"}
                              . If you exceed the maximum capacity, an
                              additional{" "}
                              {this.state.eventData.priceUnit
                                ? this.state.eventData.priceUnit
                                    .slice(
                                      4,
                                      this.state.eventData.priceUnit.length
                                    )
                                    .toLowerCase()
                                : this.state.eventData.priceUnit}{" "}
                              can be added to your reservation upon request.{" "}
                              {this.state.eventData.partner} will need to know
                              the exact number of participants joining by{" "}
                              {this.state.selectedDate
                                ? moment(this.state.selectedDate, "DD-MM-YYYY")
                                    .subtract(
                                      this.state.eventData.dedline,
                                      "days"
                                    )
                                    .format("DD-MM-YYYY")
                                : `${this.state.eventData.dedline} days before the booking date`}
                              .
                            </p>
                          ) : (
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: "400",
                                color: "gray",
                                margin: 10,
                                marginTop: 20,
                                textAlign: "center",
                              }}
                            >
                              The price outlined is per person.{" "}
                              {this.state.eventData.partner} will need to know
                              the exact number of participants joining by{" "}
                              {this.state.selectedDate
                                ? moment(this.state.selectedDate, "DD-MM-YYYY")
                                    .subtract(
                                      this.state.eventData.dedline,
                                      "days"
                                    )
                                    .format("DD-MM-YYYY")
                                : `${this.state.eventData.dedline} days before the booking date`}
                              .
                            </p>
                          )}
                        </p>
                      ) : (
                        <p>
                          <p
                            style={{
                              fontSize: 14,
                              fontWeight: "400",
                              color: "gray",
                              margin: 10,
                              marginTop: 20,
                              textAlign: "center",
                            }}
                          >
                            Please ensure full commitment when you register to
                            an experience as slots are limited and will be on
                            first-come, first-served basis. Upon arrival, kindly
                            ensure you scan the QR code or enter the voucher
                            number that you receive in your confirmation email
                            to receive your points. If you are no longer able to
                            attend an experience, please cancel before the
                            confirmation deadline. You can reach us on
                            sustainability@maf.ae for any queries.
                          </p>
                        </p>
                      )}
                    </Accordion.Panel>
                  </Accordion>
                </div>
              </div>
            )}

            <div className="footer">
              <div
                className={`filter-footer-button experience-footer-confirm-button ${
                  isButtonDisabled ? "disabled" :  null
                }`}
                onClick={this.handleButtonClick}
              >
                <p>{isBookingInProgress ? "Submit Booking" : "Book Now"}</p>
              </div>
            </div>
            {/* <div className='filter-footer'>
          <div className='filter-footer-button' onClick={this.handleJoinClick}>
            <p>
                Be the first to join
            </p>
          </div>
        </div> */}
          </div>
        )}
      </div>
    );
  }

  _renderLoading = () => (
    <div className="experience-availability-loader">
      <ActivityIndicator text="Loading..." />
    </div>
  );

  handleButtonClick = () => {
    let { isConfirmBookingEnabled, isBookingInProgress } = this.state;
    if (isBookingInProgress && isConfirmBookingEnabled)
      this.handleConfirmBooking();

    if (!isBookingInProgress) this.setState({ isBookingInProgress: true });
  };

  handleConfirmBooking = () => {
    // this.props.history.push('/confirm', {
    //   contact: '',
    //   date: 'Wednesday, 09 10 2019',
    //   image: 'http://13.126.106.195/images/bussiness-icon-64.jpg',
    //   location: 'Al Wasl Road, Al safa 1, Villa 677a - دبي - United Arab Emirates',
    //   name: 'Aquabiking',
    //   noOfPerson: 1,
    //   partner: 'Wellness Provider',
    //   price: '300 AED',
    //   priceUnit: 'Per Class',
    //   qrcode: 'id=undefined&userid=5d2e33adc330d42460ecfe29&booking=EDzqc-MtcZ&type=byCompany',
    //   reference: 'EDzqc-MtcZ',
    //   time: '8:45 am - 9:45 am',
    //   totalNoOfPerson: 1,
    //   type: 'CREATE'
    // })

    const API_data = {
      experience: this.props.match.params.id,
      bookingDate: this.state.selectedDateApi,
      bookingStart:
        this.state.eventData.experienceHours === "Specific Timeslot"
          ? this.state.specificSelected.start
          : this.state.startTime,
      bookingEnd:
        this.state.eventData.experienceHours === "Specific Timeslot"
          ? this.state.specificSelected.end
          : this.state.endTime,
      noOfPerson: this.state.numberOfFamilyMembersToDisplay,
      share: this.state.isShareDetails,
      inviteType: "closed",
      invited: this.state.selectedInviteeList.toString(),
    };
    if (!API_data.bookingStart) {
      Modal.alert(
        "Required Field Missing",
        "Please select time solt to submit this booking request.",
        [{ text: "Ok", onPress: () => console.log("ok") }]
      );
    } else if (!API_data.invited) {
      Modal.alert(
        "Required Field Missing",
        "Please invite your colleagues to submit this booking request.",
        [{ text: "Ok", onPress: () => console.log("ok") }]
      );
    } else {
      if (this.state.eventData.experienceHours === "Specific Timeslot") {
        API_data.slotID = this.state.specificSelected.slot;
      }
      this.setState({ isBookingLoading: true });
      createBooking(API_data, this.bookingSuccess, this.bookingFailure);
    }
  };

  bookingSuccess = (data) => {
    // console.log(data);
    this.setState({ isBookingLoading: false });
    data.data.displayData["type"] = "CREATE";
    // console.log(data.data.displayData);
    this.props.history.push("/confirm", data.data.displayData);
  };

  bookingFailure = (error) => {
    // console.log(error);
    this.setState({ isBookingLoading: false });
    let _message = "network request failed!!";
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error_description
    ) {
      _message = error.response.data.error_description;
    }
    Modal.alert("Something went wrong", _message, [
      { text: "Ok", onPress: () => console.log("ok") },
    ]);
  };

  _toggleMonthSelecter = () => {
    
    this.setState({ selectMonth: !this.state.selectMonth });
  };

  _showNotAvailableToast = () => {
    Toast.fail("Not available on selected date", 1);
  };

  _renderAvailabilityFlexible = () => {
    // console.log(this.state.availablespecificSlot);

    return this.state.availablespecificSlot.length > 0 ? (
      <div className="experience-fixed-container ">{this._renderSlots()}</div>
    ) : null;
  };

  _renderSlots = () => {
    const that = this;
    return this.state.availablespecificSlot.map(function (item, i) {
      return (
        <th
          className={
            that.state.specificSelected !== null &&
            that.state.specificSelected.slot === item.slot
              ? "experience-fixed-wrapper active"
              : "experience-fixed-wrapper"
          }
          key={i}
          onClick={() => that.setState({ specificSelected: item })}
        >
          <p>
            {item.start} to {item.end}
          </p>
        </th>
      );
    });
  };

  handleOnChange = (value) => {
    this.setState({
      value: value,
      month: parseInt(value[0].split("-")[0]),
      year: parseInt(value[0].split("-")[1]),
      selectMonth: false,
    });
    // console.log("value", this.state.year);
  };

  _renderAvailabilityFixed = () => {
    const duration = this.state.eventData.duration.split(":");
    const durationStep = duration[0] * 4 + duration[1] / 15;
    return this.state.dropdownValues && this.state.dropdownValues.length > 0 ? (
      <div className="experience-flexible-container">
        <div className="experience-flexible-wrapper">
          <p className="experience-flexible-dropdown-label flex-1">
            Start Time
          </p>
          <Dropdown
            options={this.state.dropdownValues.slice(
              0,
              this.state.dropdownValues.length - durationStep
            )}
            placeholder="Select an option"
            value={this.state.startTime}
            className="experience-flexible-dropdown flex-2"
            onChange={(itemValue, itemIndex) => {
              // console.log(itemValue, itemIndex);
              this.setState({
                startTime: itemValue.value,
                endTime:
                  this.state.dropdownValues[
                    this.state.dropdownValues.indexOf(itemValue.value) +
                      durationStep
                  ],
              });
            }}
          />
        </div>
        <div className="experience-flexible-wrapper">
          <p className="experience-flexible-dropdown-label flex-1">End Time</p>
          <Dropdown
            disabled
            options={this.state.dropdownValues}
            placeholder="Select an option"
            value={this.state.endTime}
            className="experience-flexible-dropdown flex-2"
          />
        </div>
      </div>
    ) : null;
  };

  handleBackClick = () => this.props.history.goBack();

  handleOpenFullDescriptopn = () =>
    this.setState({ isDescriptionFullView: !this.state.isDescriptionFullView });

  splitHours = (value) => {
    const start = this._split(value.startTime);
    const end = this._split(value.endTime);
    const temp = start;
    const duration = this.state.eventData.duration.split(":");
    let totalDuration;
    if (value.overflow) {
      totalDuration = this.durationSplit(value.startTime, "12:00 pm");
      const _temp = this.durationSplit("12:00 pm", value.endTime);
      totalDuration = {
        hours: _temp.hours + totalDuration.hours,
        minute: _temp.minute + totalDuration.minute,
      };
    } else {
      totalDuration = this.durationSplit(value.startTime, value.endTime);
    }
    const count =
      totalDuration.hours * 4 +
      totalDuration.minute / 15 +
      duration[0] * 4 +
      duration[1] / 15;
    const totalCount = [];
    let minute = temp.minute === 0 ? "00" : temp.minute.toString();
    totalCount[0] = start.hour + ":" + minute + "" + start.time;
    for (let i = 1; i <= count; i++) {
      temp.minute = temp.minute + 15;
      if (temp.minute / 60 >= 1) {
        temp.minute = temp.minute = temp.minute % 60;
        temp.hour = temp.hour + 1;
      }
      if (temp.hour / 12 >= 1) {
        temp.hour = temp.hour % 12;
        if (temp.hour === 0) {
          temp.hour = 12;
          if (temp.minute === 0) {
            temp.time = temp.time === "pm" ? "am" : "pm";
          }
        }
        // console.log("temp", temp);
        // temp.time = temp.time === 'pm' ? 'am' : 'pm'
      }
      minute = temp.minute === 0 ? "00" : temp.minute.toString();
      totalCount[i] = temp.hour + ":" + minute + "" + temp.time;
      console.log(
        moment(this.state.eventData.availableFrom, "YYYY-MM-DD").isBefore(
          this.state.eventData.availableTo
        )
      );
    }
    this.setState({
      dropdownValues: totalCount,
      dropdownStart: totalCount.slice(
        0,
        count - (duration[0] * 4 + parseInt(duration[1] / 15) - 1)
      ),
      startTime: totalCount[0],
      endTime: totalCount[duration[0] * 4 + duration[1] / 15],
    });
  };

  durationSplit = (startValue, endValue) => {
    const start = this._split(
      startValue === "12:00 pm" ? "12:00 am" : startValue
    );
    // console.log("start", start);
    const end = this._split(endValue);
    // console.log("end", end);
    // console.log("time", start.time, end.time);

    const duration = [];
    if (start.time == end.time) {
      duration.minute = end.minute - start.minute;
      duration.hours = end.hour - start.hour;
      // console.log("time duration", duration);
      if (duration.minute < 0) {
        duration.minute = 60 + duration.minute;
        duration.hours = duration.hours - 1;
      }
      if (duration.hours < 0) {
        duration.hours = 12 + duration.hours;
      }
    } else {
      // console.log("working", start.hour);
      duration.minute = 60 - start.minute + end.minute;
      duration.hours = 11 - start.hour + end.hour;
      // console.log("devide", duration.minute / 60);
      if (duration.minute / 60 >= 1) {
        // console.log("into loop", duration.minute % 60);
        duration.hours = duration.hours + parseInt(duration.minute / 60);
        duration.minute = duration.minute % 60;
      }
    }
    // console.log("final duration", duration);
    return duration;
  };

  // split 2:30 pm format value and return numbers
  _split = (value) => {
    const temp = {};
    let start = value.split(" ");
    temp.time = start[1];
    start = start[0].split(":");
    temp.hour = parseInt(start[0]);
    temp.minute = parseInt(start[1]);
    return temp;
  };

  _renderCalender = (_month, _year) => {
    let _contentArray = [];
    _month = _month === 0 ? 12 : _month;
    var now = moment();
    var month = now.month() + 1;
    var year = now.year();
    var startOfMonth = moment(_month + "/1/" + _year).startOf("month");
    var endOfMonth = moment(now).endOf("month");
    var dayWord;
    let _start;
    const _deadline = moment(now).add(this.state.eventData.dedline + 2, "day");
    const _deadlineDay = _deadline.date();
    // console.log("test deadline", _deadlineDay, month, _month, year, _year);
    if (month === _month && year === _year) {
      // console.log("now");
      _start = now;
    } else {
      // console.log("start", startOfMonth);
      _start = startOfMonth;
      endOfMonth = startOfMonth;
    }

    // console.log("_start.date()", startOfMonth);
    // console.log("endOfMonth.date()", endOfMonth.date());
    for (var i = _start.date(); i <= endOfMonth.date(); i++) {
      // console.log(_start.date(), _start.day());
      switch (_start.day()) {
        case 0:
          dayWord = "Sunday";
          break;
        case 1:
          dayWord = "Monday";
          break;
        case 2:
          dayWord = "Tuesday";
          break;
        case 3:
          dayWord = "Wednesday";
          break;
        case 4:
          dayWord = "Thursday";
          break;
        case 5:
          dayWord = "Friday";
          break;
        case 6:
          dayWord = "Saturday";
          break;
        default:
          console.log("day error");
      }
      _contentArray = [..._contentArray, { date: i, day: dayWord }];
      _start.add(1, "day");
    }
    const that = this;
    return _contentArray.map(function (item, i) {
      // console.log("deadline", _deadline.day());
      return (
        <th
          key={i}
          onClick={() =>
            that.state.eventData &&
            (that.state.eventData.offDays[item.day] ||
              (_deadline.year() === _year &&
                _deadline.month() + 1 === _month &&
                _deadlineDay > item.date) ||
              moment(`${_year}-${_month}-${item.date}`, "YYYY-MM-DD").isAfter(
                that.state.eventData.availableTo
              ) ||
              moment(`${_year}-${_month}-${item.date}`, "YYYY-MM-DD").isBefore(
                that.state.eventData.availableFrom
              ))
              ? that._showNotAvailableToast()
              : that.handleDateClick(item.date, _month, _year, item.day)
          }
          className={
            that.state.selectedDate === item.date + "-" + _month + "-" + _year
              ? "experience-calender-active"
              : that.state.eventData.offDays[item.day] ||
                (_deadline.year() === _year &&
                  _deadline.month() + 1 === _month &&
                  _deadlineDay > item.date) ||
                moment(`${_year}-${_month}-${item.date}`, "YYYY-MM-DD").isAfter(
                  that.state.eventData.availableTo
                ) ||
                moment(
                  `${_year}-${_month}-${item.date}`,
                  "YYYY-MM-DD"
                ).isBefore(that.state.eventData.availableFrom)
              ? null
              : "font-color-gray"
          }
        >
          <div className="cDate">{item.date}</div> <br />
          <div>{item.day.substr(0, 3)}</div>
        </th>
      );
    });
  };

  getAvailabilityFlexibleSuccess = (data) => {
    // console.log(data);
    this.setState({ fetchLoading: false });
    if (
      data &&
      data.data &&
      data.data.data &&
      Array.isArray(data.data.data) &&
      data.data.data.length > 0
    ) {
      const _data = data.data.data[0];
      this.splitHours({
        startTime: _data.start,
        endTime: _data.end,
        id: _data.slot,
        overflow: _data.overflow,
      });
    }
  };

  getAvailabilityFlexibleFailure = (error) => {
    this.setState({ fetchLoading: false });
    // console.log(error);
    Toast.fail("Network request failed !!!", 1);
  };

  handleDateClick = (date, month, year, day) => {
    // console.log(date + "-" + month + "-" + year);
    const _dateApiFormat = `${date < 10 ? "0" + date : date}/${
      month < 10 ? "0" + month : month
    }/${year}`;
    this.setState({
      selectedDate: date + "-" + month + "-" + year,
      selectedDateApi: _dateApiFormat,
      fetchLoading: true,
      isDateSelected:true
    });
    if (this.state.eventData.experienceHours === "Flexible Hours") {
      getBookingAvailability(
        { id: this.props.match.params.id, date: _dateApiFormat },
        this.getAvailabilityFlexibleSuccess,
        this.getAvailabilityFlexibleFailure
      );
    } else if (this.state.eventData.experienceHours === "Specific Timeslot") {
      getBookingAvailability(
        { id: this.props.match.params.id, date: _dateApiFormat },
        this.getAvailabilitySuccess,
        this.getAvailabilityFailure
      );
    }
    if (this.state.isInvited){
      this.setState({ isConfirmBookingEnabled: true });
    }
   // this.setState({ isConfirmBookingEnabled: true });
  };

  getAvailabilitySuccess = (data) => {
    // console.log(data);
    this.setState({ fetchLoading: false });
    this.setState({ availablespecificSlot: data.data.data });
  };

  getAvailabilityFailure = (error) => {
    this.setState({ fetchLoading: false });
    // console.log(error);
    Toast.fail("Network request failed !!!", 1);
  };
}

const mapStateToProps = (state) => ({
  result: state.experienceReducer.result,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExperienceDetailsScreen);
