import React, { Component } from 'react'
import './Confirmation.scss'
import { connect } from 'react-redux'

var QRCode = require('qrcode.react')

class ConfirmationScreen extends Component {
  state = {
    eventData: null
  }

  componentDidMount () {
    // console.log('success', this.props.location.state)
    if (this.props.location && this.props.location.state) { this.setState({ eventData: this.props.location.state }) }
  }

  handleBackButton = () => {
    // console.log('history', this.props.history)
    this.props.history.replace('/home')
  }

  _getMonthLabel = (monthNumber) => {
    let dayWord
    switch (monthNumber) {
      case 0:
        dayWord = 'December'
        break
      case 1:
        dayWord = 'January'
        break
      case 2:
        dayWord = 'February'
        break
      case 3:
        dayWord = 'March'
        break
      case 4:
        dayWord = 'April'
        break
      case 5:
        dayWord = 'May'
        break
      case 6:
        dayWord = 'June'
        break
      case 7:
        dayWord = 'July'
        break
      case 8:
        dayWord = 'August'
        break
      case 9:
        dayWord = 'September'
        break
      case 10:
        dayWord = 'October'
        break
      case 11:
        dayWord = 'November'
        break
      case 12:
        dayWord = 'December'
        break
      default:
        console.log('day error')
    }
    return dayWord
  }

  render () {
    return (
      this.state.eventData ? <div>
        <div className='confirmation-image-contatiner'>
          {this.state.eventData.type !== 'CREATE' ? <img src='./icons/confirm.png' width='50px' height='50px' /> : <img src='./icons/booking.png' width='50px' height='50px' />}
        </div>
        <div className='confirmation-close-button-contatiner' onClick={this.handleBackButton}>
          <img src='/icons/close-r.png' width='60px' height='60px' className='confirmation-close-button' />
        </div>
        {this.state.eventData.type !== 'CREATE'
          ? <div className='confirmation-thank-you-container'>
            <p>Thank you for booking with us!</p>
            <p className='margin-t-5'>You will receive a confirmation shortly at</p>
            <p className='margin-t-5'>{this.state.eventData.emailAddress || 'testemail@maf.com'}</p>
          </div>
          : <div className='confirmation-thank-you-container'>
            <p>Thank you for submitting a booking request.</p>
            <p className='margin-t-5'>{this.state.eventData.partner} will check their availability and get back to your shortly at {this.state.eventData.emailAddress}</p>
            </div>}
        <div className='confirmation-event-details-container'>
          <div className='confirmation-event-data-container'>
            <p className='confirmation-event-title'>{this.state.eventData.name}</p>
            <p className='confirmation-event-time'>{this.state.eventData.date.split(' ')[1]} {this._getMonthLabel(parseInt(this.state.eventData.date.split(' ')[2])).substr(0, 3)} at {this.state.eventData.time}</p>
            <p className='confirmation-event-points'>{this.state.eventData.points} Point(s)</p>
            <p className='confirmation-event-ticket'>{this.state.eventData.noOfPerson} Ticket(s)</p>
            <p className='confirmation-event-place'>{this.state.eventData.location}</p>
          </div>
          <div className='confirmation-event-image-container'>
            <img src={this.state.eventData.image} width='100%' height='100%' />
          </div>
        </div>
        {this.state.eventData.type !== 'CREATE'? <>
          <div className='margin-t-15'>
            <p className='text-center font-10  color-gray'>Please present the below QR code </p>
            <p className='text-center font-10  color-gray margin-t-5'> when you arrive at the venue</p>
          </div>
          <div className='text-center margin-10'>
            <img src={this.state.eventData.qrcodeimg} />
          </div>
          <div className='text-center'>
            <p className='text-center font-12 color-gray'><b>Booking ID:</b> {this.state.eventData.reference}</p>
          </div>
        </>:null}
      </div> : null
    )
  }
}

const mapStateToProps = state => ({
  result: state.experienceReducer.result
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationScreen)
