import { updateProfile } from "../../actions/profileActions";
import API from "../endpoints";
import { httpGETRequest } from "../httpRequestHandler";

export const fetchProfileData = () => {
  return (dispatch) => {
    httpGETRequest(API.PROFILE)
      .then((res) => {
        if (res.data) {
          dispatch(updateProfile(res.data));
        } else {
          dispatch(updateProfile(res));
        }
      })
      .catch((error) => {
        // console.log(error);
        dispatch(updateProfile(error));
      });
  };
};
