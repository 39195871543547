import React, { Component } from "react";
import "./Interest.scss";
import { connect } from "react-redux";
import { NavBar, Toast } from "antd-mobile";

import { fetchProfileData } from "../../apiManager/services/profileServices";
import {
  postInterests,
  getIntetersts,
} from "../../apiManager/services/experienceServices";

class InterestScreen extends Component {
  state = {
    isLoading: true,
    interests: [],
    selectedInterests: [],
    height: "160px",
  };

  componentDidMount() {
    this.props.fetchProfileData();
    this.fetchInterests();
  }

  fetchInterests = () => {
    getIntetersts(this._success, this._failure);
  };

  _success = (resp) => {
    // console.log('resp', resp)
    this.setState({ isLoading: false, interests: resp.data.items });
  };

  _failure = (err) => {
    this.setState({ isLoading: false });
    // console.log(err)
    Toast.fail(err.message, 1);
  };

  addToSelectedList = (id) => {
    const selectedList = this.state.selectedInterests;
    const index = selectedList.indexOf(id);
    if (index > -1) {
      selectedList.splice(index, 1);
    } else {
      selectedList.push(id);
    }
    this.setState({ selectedInterests: selectedList }, () =>
      console.log("selectedList", this.state.selectedInterests)
    );
  };

  handleInterestProceed = () => {
    const interest = {
      interest: this.state.selectedInterests,
    };
    postInterests(interest, this.interestPostSuccess, this.interestPostFailure);
  };

  interestPostSuccess = (data) => {
    // console.log('data', data)
    Toast.success(data.message || "updated interest", 1);
    this.handleRedirect();
  };

  interestPostFailure = (error) => {
    // console.log(error)
    Toast.fail(error.message, 1);
    this.handleRedirect();
  };

  handleRedirect = () =>
    this.props.profile.stepChallenge
      ? this.props.history.replace("/home")
      : this.props.history.replace("/step-challenge-setup");

  onImgLoad = ({ target: img }) => {
    this.setState({
      height: img.width,
    });
  };

  render() {
    return (
      <div className="main-container">
        <NavBar
          mode="light"
          leftContent={
            // <Icon key='1' type='left' className='navbar-back-icon' onClick={() => this.handleRedirect()} />
            <img
              className="arrowback"
              src="./icons/back-gold.png"
              onClick={() => {
                this.handleRedirect();
              }}
            />
          }
          className="navbar-container"
        >
          Select Interests
        </NavBar>
        <div className="interest-container margin-t-70">
          {this.state.isLoading ? (
            <div className="loader">
              <img src="/icons/MAF.gif" />
            </div>
          ) : (
            <div className="row">
              {this.state.interests.map((interest, id) => (
                <div
                  className="column interest-container"
                  key={id}
                  onClick={() => this.addToSelectedList(interest.id)}
                >
                  <img
                    className="imgHolder image"
                    onLoad={this.onImgLoad}
                    src={interest.cover}
                    alt={interest.name}
                    style={{
                      width: "100%",
                      height: this.state.height || "160px",
                      opacity: this.state.selectedInterests.includes(
                        interest.id
                      )
                        ? 1.3
                        : 1,
                      filter: this.state.selectedInterests.includes(interest.id)
                        ? "brightness(50%)"
                        : "brightness(100%)",
                    }}
                  />
                  {this.state.selectedInterests.includes(interest.id) ? null : (
                    <div className="centered">{interest.name}</div>
                  )}
                  {this.state.selectedInterests.includes(interest.id) ? (
                    <div className="middle">
                      <div className="text">
                        <div className="font-tick">
                          <img
                            src="./images/select.svg"
                            alt="Smiley face"
                            height="25"
                            width="25"
                          />
                        </div>
                        <div>{interest.name}</div>
                      </div>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          )}
        </div>
        {this.state.selectedInterests.length ? (
          <div className="filter-footer">
            <div
              className="filter-footer-button"
              onClick={this.handleInterestProceed}
            >
              <p>Proceed</p>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  result: state.experienceReducer.result,
  profile: state.profileReducer.profile,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProfileData: () => dispatch(fetchProfileData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InterestScreen);
