import { Flex } from "antd-mobile";
import moment from "moment";
import React, { Component } from "react";
import { getHappeningCalendar } from "../../apiManager/services/experienceServices";
import { NavigationBar } from "../../components/common/NavBar/navBar";
import {
  DefaultEventCard,
  EventCard,
} from "../Home/components/EventCard/eventCard";
import "./calenderScreen.scss";
import Calendar from "./component/calender";

export class CalenderScreen extends Component {
  state = {
    date: moment(),
    events: [],
    eventsTodisplay: null,
    selectedDate: null,
    isEventsLoaded: false,
  };

  componentDidMount() {}

  getEvents = (startDate = null, endDate = null, dateToFilte) => {
    this.setState({ selectedDate: null });
    getHappeningCalendar(startDate, endDate).then((res) => {
      let events = res.items;
      this.setState(
        {
          events: events,
          eventsTodisplay: events,
          isEventsLoaded: true,
        },
       // () => this.filterEvents(dateToFilte)
      );
    });
  };

  filterEvents = (date) => {
    if (!date) {
      let eventsTodisplay = [...this.state.events];
      this.setState({ eventsTodisplay, selectedDate: null });
    } else {
      let eventsTodisplay = this.state.events.filter((item) =>
        this.checkAdult(item, date)
      );
      this.setState({ eventsTodisplay, selectedDate: date });
    }
  };

  checkAdult = (item, date) => {
    let _date = moment(item.date);
    let selected = date;
    // console.log(
    //   _date.date(),
    //   date.date(),
    //   _date.month(),
    //   selected.month(),
    //   _date.year(),
    //   selected.year()
    // );
    if (
      _date.date() === date.date() &&
      _date.month() === selected.month() &&
      _date.year() === selected.year()
    )
      return true;
    else return false;
  };

  render() {
    let { date, events, eventsTodisplay, selectedDate } = this.state;

    // console.log(events);
    return (
      <div className="calendar-main-container">
        <NavigationBar history={this.props.history} />
        <p className="calendar-title">
          {selectedDate ? selectedDate.date() + " " : null}
          {date.format("MMMM").substring(0, 3).toUpperCase()} {date.year()}{" "}
          <span className="calendar-count">{events.length}</span>
        </p>
        <div className="calender-event-container">
          {eventsTodisplay &&
            eventsTodisplay.map((evt) => (
              <EventCard
                rowData={evt}
                rowID={evt.id}
                history={this.props.history}
              />
            ))}
          {eventsTodisplay && eventsTodisplay.length === 0 ? (
            <DefaultEventCard history={this.props.history} />
          ) : null}
        </div>
        <Calendar
          onChange={(date) => this.setState({ date })}
          onMonthChange={this.getEvents}
          filterDate={this.filterEvents}
          events={events}
        ></Calendar>
      </div>
    );
  }
}
