import React, { Component } from 'react'
import './EventDetails.scss'
import { connect } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'
import Dropdown from 'react-dropdown'
import QRCode from 'qrcode.react'
import 'react-dropdown/style.css'

import { Carousel, Accordion, Modal, Stepper, Icon, TextareaItem, Toast, ActivityIndicator, Flex, InputItem } from 'antd-mobile'
import { getHappeningDetails, cancelBooking, joinExperience } from '../../apiManager/services/experienceServices'
import DEEPLINK from '../../apiManager/endpoints/deeplink'
import Image from '../../components/common/Image/image'
import ReactStars from "react-rating-stars-component";

class EventDetailsScreen extends Component {
  state={
    isLoading: true,
    isModalVisible: false,
    isOperation: false,
    isCancelModelVisible: false,
    yesNoAnswer: 'yes',
    numberOfFamilyMembers: 0,
    objectiveAnswer: 'Not required',
    multipleChoiceAnswer: null,
    questionData: [],
    eventData: {
      images: []
    },
    reasons: [
      {
        label: 'Not enough colleagues interested',
        value: 'Not enough colleagues interested'
      },
      {
        label: 'The date and time no longer work for me​',
        value: 'The date and time no longer work for me​'
      },
      {
        label: 'The cost per person is too expensive​',
        value: 'The cost per person is too expensive​'
      },
      {
        label: 'I’m confused about the offer​',
        value: 'I’m confused about the offer​'
      },
      {
        label: 'Others',
        value: 'Others'
      }
    ],
    step: 1,
    reason: null,
    val: 1,
    data: ['https://dynaimage.cdn.cnn.com/cnn/q_auto,w_1280,c_fill,g_auto,h_720,ar_16:9/http%3A%2F%2Fcdn.cnn.com%2Fcnnnext%2Fdam%2Fassets%2F170606121206-italy---travel-destination---shutterstock-517522957.jpg', 'https://dynaimage.cdn.cnn.com/cnn/q_auto,w_1280,c_fill,g_auto,h_720,ar_16:9/http%3A%2F%2Fcdn.cnn.com%2Fcnnnext%2Fdam%2Fassets%2F170606121206-italy---travel-destination---shutterstock-517522957.jpg', 'https://dynaimage.cdn.cnn.com/cnn/q_auto,w_1280,c_fill,g_auto,h_720,ar_16:9/http%3A%2F%2Fcdn.cnn.com%2Fcnnnext%2Fdam%2Fassets%2F170606121206-italy---travel-destination---shutterstock-517522957.jpg']
  }
  componentDidUpdate() {
    const element = this.element;
    // Things involving accessing DOM properties on element
    // In the case of what this question actually asks:
    if (element) {
      const _hasOverflowingChildren =
        element.offsetHeight < element.scrollHeight ||
        element.offsetWidth < element.scrollWidth;

      if (this.state.hasOverflowingChildren !== _hasOverflowingChildren) {
        this.setState({ hasOverflowingChildren: _hasOverflowingChildren });
      }
    }
  }
  componentDidMount () {
    this.fetchEventDetails(this.props.match.params.id)
  }

  fetchEventDetails = (id) => {
    getHappeningDetails(id, this._success, this._failure)
  }

  _success = (data) => {
    let eventData = data.data.data
    if(eventData && eventData.morequestions) {
      eventData.questions = [...eventData.questions, ...eventData.morequestions]
    }
    let questionAnswer = eventData.questions.map(item => { return{id: item.id, answer: this.getDefaultAnswer(item.type)}})
    // console.log("questionAnswer" , questionAnswer)
    this.setState({ isLoading: false, eventData, isOperation: false , questionAnswer})
    // console.log(data.data.data)
  }

  _failure = (data) => {
    this.setState({ isLoading: false, isOperation: false })
    // console.log(data)
  }

  getDefaultAnswer = (type) => {
    switch (type) {
      case 'Number' :
      case 'number' : return 0
      case 'Yes/No' : return "yes"
      case 'Objective' : return "Not required"
      default: return null
    }
  }

  handleNextButton = () => {
    // console.log('next button pressed')
    const { val } = this.state
    if (val >= 1) {
      const _tempArray = this.state.questionData
      _tempArray[val - 1] = this.getMessageDetails(val)
    }
    this.setState({ val: val + 1 })
  }

  getMessageDetails = (val) => {
    const _data = this.state.eventData.questions[val - 1]
    return {
      id: _data.id,
      answer: this.getQuestionAnswer(_data.type)
    }
  }

  getQuestionAnswer = (type) => {
    switch (type) {
      case 'number' : return this.state.numberOfFamilyMembers
      case 'Yes/No' : return this.state.yesNoAnswer
      case 'Objective' : return this.state.objectiveAnswer
    }
  }

  handlePrevButton = () => {
    // console.log('next button pressed')
    const { val } = this.state
    this.setState({ val: val - 1 })
  }

  onChange = (numberOfFamilyMembers, id) => {
    let { questionAnswer } = this.state

    let index = questionAnswer.findIndex(item => item.id === id)

    if(index > -1) 
      questionAnswer[index].answer = numberOfFamilyMembers 

    this.setState({ questionAnswer })

    // console.log("CHANGE ANSEWER", questionAnswer)
  }

  getAnswerValue(id) {
    return this.state.questionAnswer.find(item => item.id === id).answer
  }

  handleJoinClick = () => {
    // console.log('handle join click')
    if (!this.state.eventData.timeout) {
      if (!this.state.eventData.organiser) {
        if (!this.state.eventData.join) {
          if (this.state.eventData && this.state.eventData.questions && this.state.eventData.questions.length > 0) { this.setState({ isModalVisible: true }) } else {
            this.joinExperience()
          }
        } else {
          this.joinExperience()
        }
      } else {
        // console.log('Cancel')
        this.setState({ isCancelModelVisible: true })
      }
    } else {
      // console.log('timeout')
      Modal.alert('Registration Closed', `The confirmation deadline for ${this.state.eventData.name} has expired. This means that we have already sent the final number of participants to ${this.state.eventData.type === 'byInternal' ? 'Sustainability Office' : this.state.eventData.partner}. If you are looking to join this experience or cancel your spot, please contact ${this.state.eventData.type === 'byInternal' ? 'Sustainability Office' : this.state.eventData.partner} directly.`, [
        { text: 'Ok', onPress: () => console.log('ok') }
      ])
    }
  }

  handleInviteClick = () => this.props.history.push({
    pathname: `/invite/${this.props.match.params.id}`
  });

  handleAttendeeClick = () => this.props.history.push(`/attendee/${this.props.match.params.id}`);

  _renderStep = (val) => {
    if (val === 0) {
      if (this.state.isModalVisible) {
        this.setState({ isModalVisible: false, val: 1 })
      }
    } else if (val <= this.state.eventData.questions.length) {
      return (this._renderQuestions(val))
    } else {
      this.setState({ isModalVisible: false, val: 1 })
      this.joinExperience()
    }
  }

  _renderQuestions = (value) => {
    const questionData = this.state.eventData.questions[value - 1]
    // console.log('questionData', questionData)
    return (
      <div className='event-qauestion-container'>
        {questionData.image?<img src={questionData.image} width='50' height='50' />:null}
        <p className='font-12'>{questionData.question}</p>
        {this._renderOptions(questionData, value - 1)}
      </div>
    )
  }

  _renderOptions = (questionData, index) => {
    let val = this.getAnswerValue(questionData.id)
    switch (questionData.type) {
      case 'Number' :
      case 'number' : return (
        <div className='event-stepper-container'>
          <Stepper
            style={{ width: '100%', minWidth: '100px' }}
            showNumber
            max={10}
            min={0}
            onChange={(val) => this.onChange(val, questionData.id)}
            value={this.getAnswerValue(questionData.id)}
          />
        </div>
      )
      case 'Yes/No' : 
      return (
        <div className='event-yes-no-button-container'>
          <div className={val !== 'yes' ? 'event-yes-no-button' : 'event-yes-no-button active'} onClick={() => this.onChange('yes', questionData.id)}>
            <p className='font-12'>Yes</p>
          </div>
          <div className={val !== 'no' ? 'event-yes-no-button' : 'event-yes-no-button active'} onClick={() => this.onChange('no', questionData.id)}>
            <p className='font-12'>No</p>
          </div>
        </div>
      )
      case 'Objective' : 
      return (
        <div className='event-shirt-size-button-container margin-t-b-20'>

          <div className='event-shirt-size-button-container'>

            {questionData.options.split(',').map((data) => <div className={val !== data ? 'event-shirt-size-button' : 'event-shirt-size-button active'} onClick={() => this.onChange(data, questionData.id)}>
              <p className='font-10'>{data}</p>
                                                           </div>
            )}
          </div>

          <div className={this.state.objectiveAnswer !== 'Not required' ? 'event-not-required-button' : 'event-not-required-button active'} onClick={() => this.onChange('Not required', questionData.id)}>
            <p className='font-8'>Not Required</p>
          </div>
        </div>
      )

      case 'Multiple Choice' : return (
        <div className='margin-t-b-20'>

          <div className='event-more-options-button-container event-more-questions-button-container'>

            {questionData.choice.map((data) => <div className={val !== data ? 'event-shirt-size-button event-more-options-button-container' : 'event-shirt-size-button event-more-options-button-container active'} onClick={() => this.onChange(data, questionData.id)}>
              <p className='font-10'>{data}</p>
                                                           </div>
            )}
          </div>
        </div>
      )

      case 'Text Box' : return (
        <div className='event-shirt-size-button-container margin-t-b-20'>

          <div className='event-shirt-size-button-container event-more-options-button-container'>

            <InputItem className="questions-input" onChange={(val) => this.onChange(val, questionData.id)}></InputItem>

          </div>
        </div>
      )
      

      case 'Happiness Meter' : return (
        <div className='event-shirt-size-button-container margin-t-b-20'>

          <div className='event-shirt-size-button-container event-more-options-button-container'>

            <div className={`happiness-meter-icon-sad ${val===0 ? 'is-active': ''}`} onClick={(val) => this.onChange(0, questionData.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="30px" height="30px"><path d="M0 0h24v24H0V0z" fill="none"/><circle cx="15.5" cy="9.5" r="1.5"/><circle cx="8.5" cy="9.5" r="1.5"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-6c-2.33 0-4.32 1.45-5.12 3.5h1.67c.69-1.19 1.97-2 3.45-2s2.75.81 3.45 2h1.67c-.8-2.05-2.79-3.5-5.12-3.5z"/></svg>
            </div>
            <div className={`happiness-meter-icon-neutral ${val===50 ? 'is-active': ''}`} onClick={(val) => this.onChange(50, questionData.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="30px" height="30px"><path d="M0 0h24v24H0V0z" fill="none"/><circle cx="15.5" cy="9.5" r="1.5"/><circle cx="8.5" cy="9.5" r="1.5"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-4c-.73 0-1.38-.18-1.96-.52-.12.14-.86.98-1.01 1.15.86.55 1.87.87 2.97.87 1.11 0 2.12-.33 2.98-.88-.97-1.09-.01-.02-1.01-1.15-.59.35-1.24.53-1.97.53z"/></svg>            </div>
            <div className={`happiness-meter-icon-happy ${val===100 ? 'is-active': ''}`} onClick={(val) => this.onChange(100, questionData.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="30px" height="30px"><path d="M0 0h24v24H0V0z" fill="none"/><circle cx="15.5" cy="9.5" r="1.5"/><circle cx="8.5" cy="9.5" r="1.5"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-5-6c.78 2.34 2.72 4 5 4s4.22-1.66 5-4H7z"/></svg>            </div>

          </div>
        </div>
      )
      
      case 'Rating' : return (
        <div className='event-shirt-size-button-container margin-t-b-20'>

          <div className='event-shirt-size-button-container event-more-options-button-container question-rating'>

          <ReactStars
            count={5}
            onChange={(val) => this.onChange(val, questionData.id)}
            size={40}
            activeColor="#8A1538"
            value={val}
          />

          </div>
        </div>
      )

    }
  }

  render () {

    let {points} = this.state.eventData
    return (
      <div>{this.state.isLoading ? <div className='loader'><img src='/icons/MAF.gif' /></div> : <div className='event-main-container fade-in'>
        <ActivityIndicator
          toast
          size='large'
          text='Loading...'
          color='white'
          animating={this.state.isOperation}
        />
        <Modal
          popup
          visible={this.state.isMapModelVisible}
          transparent
          animationType='slide-up'
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
          onClose={() => this.setState({ isMapModelVisible: false })}
        >
          <div className='margin-20' />
          <div className='margin-20'>
            {this.state.eventData && this.state.eventData.location ? <img className='margin-t-15' width='100%' height='180px' src={`https://maps.googleapis.com/maps/api/staticmap?center=${this.state.eventData.location.lat}${this.state.eventData.location.lng}&zoom=15&size=600x400&markers=${this.state.eventData.location.lat},${this.state.eventData.location.lng}&key=AIzaSyD9IVRFkKjD4V6piuPlA7hHhrAusA0G2wc`} /> : null}
          </div>
          <div>
            <div className='filter-footer-button color-yellow' onClick={() => this.goToMap(this.state.eventData.location || { lat: '', long: '' })}>
              <p className='padding-t-12'>
                Open in Google Maps
              </p>
            </div>
          </div>
          <div className='event-map-close' onClick={() => this.setState({ isMapModelVisible: false })}>
            <img width='40px' height='40px' src='/icons/close-r.png' />
          </div>
        </Modal>
        <Modal
          popup
          visible={this.state.isModalVisible}
          transparent
          animationType='slide-up'
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
          onClose={() => this.setState({ isModalVisible: false, val: 0 })}
        >
          <div className='event-popup-top-bar margin-t-30 '>
            <p className='event-popup-back-button' onClick={this.handlePrevButton}>Back</p>
            {this.state.eventData.questions ? <p className='event-popup-step-label'>Step {this.state.val}/{this.state.eventData.questions.length}</p> : null}
          </div>
          {this.state.eventData.questions && this.state.eventData.questions.length > 0 ? this._renderStep(this.state.val) : <p>issue here</p>}
          <div className='filter-footer-button' onClick={this.handleNextButton}>
            <p>
                Next
            </p>
          </div>
          <div className='event-map-close' onClick={() => this.setState({ isModalVisible: false })}>
            <img width='40px' height='40px' src='/icons/close-r.png' />
          </div>
        </Modal>
        <Modal
          popup
          visible={this.state.isCancelModelVisible}
          transparent
          animationType='slide-up'
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
          onClose={() => this.setState({ isCancelModelVisible: false })}
        >
          <div>
            <h3 className='font-14 color-black'>Cancel</h3>
            <p className='margin-20 font-12'>Do you wish to cancel <b>{this.state.eventData.name}</b> ? If yes please choose the reason</p>
          </div>
          <div className='event-cancel-dropdown'>
            <Dropdown options={this.state.reasons} placeholder='Select an option' value={this.state.reason} onChange={(itemValue, index) => { this.setState({ reason: itemValue.value }) }} className='experience-flexible-dropdown flex-2' />
            {this.state.reason === 'Others'
              ? <TextareaItem
                className='event-cancel-reason'
                placeholder='please enter your reason'
                value={this.state.cutomReason}
                onChange={(reason) => this.setState({ cutomReason: reason })}
                rows={3}
                /> : <div className='margin-b-50' />}
          </div>
          <div>
            <div className='filter-footer-button' onClick={this.handleCancelEvent}>
              <p className='padding-t-12'>
                Submit
              </p>
            </div>
          </div>
          <div className='event-map-close' onClick={() => this.setState({ isCancelModelVisible: false })}>
            <img width='40px' height='40px' src='/icons/close-r.png' />
          </div>
        </Modal>
        <div className="position-relative">
          <Carousel
            autoplay={false}
            infinite
            dots={!(this.state.eventData && this.state.eventData.images && this.state.eventData.images.length <= 1)}
            beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
            className='event-carousel'
            afterChange={index => console.log('slide to', index)}
          >
            {this.state.eventData.images.map(val => (
              <a
                key={val}
                style={{ display: 'inline-block', width: '100%', height: this.state.imgHeight }}
              >
                <img
                  src={val.sd}
                  alt=''
                  style={{ width: '100%', verticalAlign: 'top' }}
                />
              </a>
            ))}
          </Carousel>
          <div className='event-back-button' onClick={this.handleBackClick}><img className='arrow-back-details' src='/icons/back-gold.png' width='10px' height='15px' /></div>
          {this.state.eventData && (this.state.eventData.timeout || this.state.eventData.full) ? null : <div className='event-invite-button' onClick={this.handleInviteClick}><p className='color-white font-10'>Invite</p></div>}
          {this.state.eventData && this.state.eventData.timeout ? <div className='event-tag-container-time border-5 bg-gray'>
            <span className='color-white'>Registration Closed</span>
          </div> :  this.state.eventData.join ? <div className='event-tag-container-time border-5'>
                                                                    <span className='color-white'>Registered</span>
          </div>:<div className='event-tag-container-time border-5'>
                                                                    <span className='color-white'>Register in {this.state.eventData.deadline}</span>
          </div>}
          {points ? <div className="maf-tag event-details-points"><span>{points} points</span></div> : null}
        </div>
        <div className='event-title-container bg-white postition-relative'>
          <h2 className='event-title'>{this.state.eventData.name}</h2>
         
         
          {this.state.eventData.type !== 'byInternal' ? <div className='experience-card-content-container'>
            <div className='experience-card-content-data-container'>
              <span className='experience-card-main-text'>{this.state.eventData.discount}</span>
              <p className='experience-card-main-sub-text'>off</p>
            </div>
            <div className='experience-card-content-data-container'>
              <p className='line-height-5'><strike className='font-9 color-gray'>{this.state.eventData.currency || 'AED'} {this.state.eventData.price}</strike></p>
              <span className='experience-card-main-text'>{this.state.eventData.currency || 'AED'} {this.state.eventData.OfferPrice}</span>
              <p className='experience-card-main-sub-text'>{this.state.eventData.priceUnit}</p>
            </div>
                                                        </div> : <div className='experience-card-content-container'><div className='experience-card-content-data-container'>
            <span className='experience-card-main-text'>FREE</span>
          </div>
                   </div>}
          <Flex className='margin-5'>
            <Flex.Item>
              <h4 className='event-time event-margin-0'>{this.state.eventData.heppeningOn}</h4>
            </Flex.Item>
          </Flex>
          {this.state.eventData.ticket ? <h4 className='event-time'>{this.state.eventData.ticket}</h4> : null}
          <div className='tag-container'>
            <span>{this.state.eventData.category}</span>
           
          </div>
          
          {this.state.eventData.required ? <div className='am-flexbox-item tag-container'><span>{this.state.eventData.required}</span></div> : null}
  
          {this.state.eventData && this.state.eventData.address ? <h4 className='event-location-label'>Location</h4> : null}
          {this.state.eventData && this.state.eventData.address ? <h5 className='event-location'>{this.state.eventData.address}</h5> : null}
          {this.state.eventData.location ? <div className='experience-tag-container-time font-8' onClick={() => this.setState({ isMapModelVisible: true })}>
            <img src='/icons/navigation.png' />
            <span className='color-white'>Open in Maps</span>
          </div> : null}
        </div>
        <div className='event-details-container'>
          <h4 className='event-details-header'>About</h4>
          {!this.state.isDescriptionFullView ? (
                    <div
                      className="event-description-closed padding-t-1"
                      ref={(el) => {
                        this.element = el;
                      }}
                    >
                      <p className="event-description">
                        {ReactHtmlParser(this.state.eventData.about)}
                      </p>
                    </div>
                  ) : (
                    <div
                      className="event-description-open padding-t-1"
                      ref={(el) => {
                        this.element = el;
                      }}
                    >
                      <p className="event-description">
                        {ReactHtmlParser(this.state.eventData.about)}
                      </p>
                    </div>
                  )}
        </div>
        {this.state.hasOverflowingChildren ? (
                  this.state.isDescriptionFullView ? (
                    <div className="text-center padding-t-5 rotate-180">
                      <img
                        className="bounce bounce-2"
                        src="/icons/show-more-down.png"
                        width="10px"
                        onClick={this.handleOpenFullDescriptopn}
                      />
                    </div>
                  ) : (
                    <div className="text-center padding-b-5">
                      <img
                        className="bounce bounce-2"
                        src="/icons/show-more-down.png"
                        width="10px"
                        onClick={this.handleOpenFullDescriptopn}
                      />
                    </div>
                  )
                ) : (
                  <div className="text-center padding-b-5">
                    <img
                      className="bounce bounce-2"
                      src="/icons/show-more-up.png"
                      width="10px"
                      onClick={this.handleOpenFullDescriptopn}
                    />
                  </div>
                )}
        <div className='bg-white '>
          <div className='event-attendees-container'>
            <p>Attendees {this.state.eventData.who ? `(${this._getWhoisGoingConunt()})` : null}</p>
            <div className='experieence-viewall-wrapper'>
              <p onClick={this.handleAttendeeClick} className='margin-r-5'>View All</p>
              <img src='/icons/view-all.png' height='15px' width='15px' />
            </div>
          </div>
          <div className='experience-attendee-sub-cntainer'>
            {this.renderAttendes()}
          </div>
        </div>
        {(this.state.eventData.join || this.state.eventData.organiser) && this.state.eventData.qrcode ? <div className='event-terms-container'>
          <Accordion>
            <Accordion.Panel header='Ticket Details'>
              <Flex className='flex-1'>
                <Flex.Item className='flex-1'>
                  <div>
                    <span>Present this code to <br /><b>{this.state.eventData.partner}</b><br /> to check-in and receive your points!</span>
                  </div>
                </Flex.Item>
                <Flex.Item className='flex-1'>
                  <div className='text-center'>
                    <QRCode value={this.state.eventData.qrcode} />
                    <h2>{this.state.eventData.qrcode}</h2>
                  </div>
                </Flex.Item>
              </Flex>
            </Accordion.Panel>
          </Accordion>
                                                                                                        </div> : null}

        <div className='event-terms-container pb-100'>
          <Accordion>
            <Accordion.Panel header='Terms and Conditions'>
              {this.state.eventData.type !== 'byInternal' ? <p>
                {this.state.eventData.priceUnit != 'Per Person'
                  ? <p style={{ fontSize: 14, fontWeight: '400', color: 'gray', margin: 10, marginTop: 20, textAlign: 'center' }}>
                                    The price outlined is {this.state.eventData.priceUnit ? this.state.eventData.priceUnit.toLowerCase() : this.state.eventData.priceUnit}. The maximum capacity per {this.state.eventData.priceUnit ? this.state.eventData.priceUnit.slice(4, this.state.eventData.priceUnit.length).toLowerCase() : this.state.eventData.priceUnit} is {this.state.eventData.capacity} {this.state.eventData.capacity == 1 ? ' participant' : ' participants'}. If you exceed the maximum capacity,
                          an additional {this.state.eventData.priceUnit ? this.state.eventData.priceUnit.slice(4, this.state.eventData.priceUnit.length).toLowerCase() : this.state.eventData.priceUnit} can be added to your reservation upon request. {this.state.eventData.partner} will need to know the exact number of participants
                          joining by {this.state.eventData.expdate}
                  </p>
                  : <p style={{ fontSize: 14, fontWeight: '400', color: 'gray', margin: 10, marginTop: 20, textAlign: 'center' }}>
                                    The price outlined is per person. {this.state.eventData.partner} will need to know the exact number of participants joining by {this.state.eventData.expdate}.
                  </p>}
                                                            </p>
                : <p>
                  <p style={{ fontSize: 14, fontWeight: '400', color: 'gray', margin: 10, marginTop: 20, textAlign: 'center' }}>
                  Please ensure full commitment when you register to an experience as slots are limited and will be on first-come, first-served basis. Upon arrival, kindly present the QR code or show the booking ID that you receive in your confirmation email to receive your points. If you are no longer able to attend an experience, please cancel before the confirmation deadline. You can reach us on sustainability@maf.ae for any queries.
                  </p>
                </p>}
            </Accordion.Panel>
          </Accordion>
        </div>
        <div className='margin-t-15 dummy-space' />
        <div className='filter-footer'>
          <div className={!this.state.eventData.full && !this.state.eventData.timeout ? 'filter-footer-button' : 'filter-footer-button bg-gray'} onClick={this.handleJoinClick}>
            <p>
              {!this.state.eventData.timeout ? !this.state.eventData.organiser ? !this.state.eventData.join ? this.state.eventData.full ? 'Fully Booked' : 'Join Experience' : 'Leave Experience' : 'Cancel' : 'Registration Closed'}
            </p>
          </div>
        </div>
                                                                                                </div>}

      </div>
    )
  }

  _getWhoisGoingConunt = () => {
    let _sum = 0
    this.state.eventData.who.map(data => {
      _sum = _sum + data.noOfPerson
    })
    return _sum
  }

  handleCancelEvent = () => {
    if (!this.state.reason || (this.state.reason === 'Others' && !this.state.cutomReason)) { Toast.fail('Please enter a reason', 1) } else {
      const API_data = { message: this.state.reason === 'Others' ? this.state.cutomReason : this.state.reason }
      this.setState({ isOperation: true })
      cancelBooking(this.props.match.params.id, API_data, this._cancelSuccess, this._cancelFailure)
    }
  }

  joinExperience = () => {
    const API_data = {
      status: !this.state.eventData.join
    }
    if (this.state.eventData.questions && this.state.eventData.questions.length > 0) {
      API_data.answers = this.state.questionAnswer
    }
    if (!this.state.eventData.join == false) {
      Modal.alert('Leave Experience', 'Are you sure you want to leave?', [
        { text: 'No', onPress: () => console.log('cancel') },
        {
          text: 'Yes',
          onPress: () => {
            this.setState({ isOperation: true })
            joinExperience(this.props.match.params.id, API_data, this.joinSuccess, this.joinFailure)
          }
        }
      ])
    } else {
      this.setState({ isOperation: true })
      joinExperience(this.props.match.params.id, API_data, this.joinSuccess, this.joinFailure)
    }
  }

  joinSuccess = (data) => {
    // console.log('data', data)
    if (data && data.data && data.data.result) {
      this.setState({ isOperation: false })
      if (data.data.message !== 'Joined the Experience') {
        getHappeningDetails(this.props.match.params.id, this._success, this._failure)
        Toast.success(data.data.message, 3)
      } else {
        if (data && data.data && data.data.data) { this.props.history.push('/confirm', data.data.data) }
      }
    }
  }

  joinFailure = (error) => {
    this.setState({ isOperation: false })
    let _message = 'network request failed!!'
    if (error && error.response && error.response.data && error.response.data.error_description && typeof (error.response.data.error_description) === 'string') { _message = error.response.data.error_description }
    // console.log(_message)
    Modal.alert('Request Failed', _message, [
      { text: 'Ok', onPress: () => console.log('ok') }
    ])
  }

  _cancelSuccess = (data) => {
    // console.log(data)
    let _message
    if (data && data.data && data.data.experience) {
      _message = `The event ${data.data.experience} cancelled successfully.`
    } else {
      _message = 'something went wrong'
    }
    this.setState({ isOperation: false })
    Modal.alert('Cancelled successfully', _message, [
      { text: 'Ok', onPress: () => this.handleBackClick() }
    ])
  }

  _cancelFailure = (error) => {
    this.setState({ isOperation: false })
    let _message = 'network request failed!!'
    if (error && error.response && error.response.data && error.response.data.error_description && typeof (error.response.data.error_description) === 'string') { _message = error.response.data.error_description }
    // console.log(_message)
    Modal.alert('Request Failed', _message, [
      { text: 'Ok', onPress: () => console.log('ok') }
    ])
  }

  renderAttendes =() => {
    if(!this.state.eventData.who) {
      return null
    }
    return (this.state.eventData.who.slice(0, 6).map(function (item, i) {
      return <th key={i} className='experience-attendee-item' onClick={() => item && item.empid? window.open(DEEPLINK.EMP_ID + item.empid) :null }>
        <Image 
        src={item.image || '/icons/profile.png'} 
        width='100%' 
        height='100%' 
        className='experience-attendee-avatar' 
        fallbackurl='/icons/profile.png'
        /><br /><div className='experience-attendee-name'>{item.name}</div> {item && item.noOfPerson && item.noOfPerson > 1 ? <div className='event-attendees-number-round'><p>+{item.noOfPerson - 1}</p></div> : null}</th>
    }))
  }

  handleOpenFullDescriptopn = () =>
    this.setState({ isDescriptionFullView: !this.state.isDescriptionFullView });
    
  handleBackClick = () => this.props.history.goBack()

  goToMap (location) {
    if (location) {
      var win = window.open(`https://maps.google.com/?q=${location.lat},${location.lng}`, '_blank')
      win.focus()
    }
  }
}

const mapStateToProps = state => ({
  result: state.experienceReducer.result
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(EventDetailsScreen)
