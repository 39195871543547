import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Button, Modal, SwipeAction } from "antd-mobile";

import {
  syncStepsData,
  appleHealthDisconnect,
  digimeRevokeAccess,
} from "../../../../apiManager/services/stepsChallengeServices";
import { validateToken } from "../../../../apiManager/services/validationServices";
import Token from "../../../../apiManager/token/tokenValue";
import { numberWithCommas } from "../../../../components/utils/numbers";

import DEEPLINK from "../../../../apiManager/endpoints/deeplink";

import ReloadIcon from "../../../../assets/icons/arrow-clockwise.svg";
import StepsIcon from "../../../../assets/icons/steps-icon.png";
import BurnIcon from "../../../../assets/icons/burn-icon.png";
import FlagIcon from "../../../../assets/icons/flag-icon.png";
import PodiumIcon from "../../../../assets/icons/podium.png";
import "./styles.scss";
import SyncImageIcon from "../../../../assets/icons/cloud-sync-2.png"
import ProgressBar from "@ramonak/react-progress-bar";

const StepsInfo = ({ data, params, setError, history }) => {
  const [refetching, setRefetching] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);
  const [fetchComplete, setFetchComplete] = useState(false);
  const calculatePercentage = (value, total) => {
    let percentage = (value / total) * 100;
    if (percentage > 100) return 100;
    return percentage;
  };

  const right = [
    {
      text: "Revoke Access",
      onPress: () => modalAlert(),
      style: { backgroundColor: "red", color: "white", padding: "15px" },
    },
  ];

  const modalError = useCallback(() => {
    Modal.alert("Something went wrong !", "Please try again.", [
      { text: "OK", onPress: () => window.location.replace("/home") },
    ])
  }, [])

  const modalSuccess = useCallback(() =>
    Modal.alert(
      "Success !",
      "You are no longer a part of Step Challenge Program now",
      [{ text: "OK", onPress: () => window.location.replace("/home") }]
    ), [])

  const revokeAccessHandler = useCallback(() => {
    if (data.appleHealth) window.location.replace(DEEPLINK.REVOKE_APPLE_HEALTH);
    else
      digimeRevokeAccess()
        .then((res) => {
          if (res.result) modalSuccess();
          else modalError();
        })
        .catch(() => modalError());
  }, [data.appleHealth, modalError, modalSuccess])

  const modalPartialSync = useCallback(() => {
    Modal.alert("", "Step Challenge data has been partially synced.", [
      { text: "OK", onPress: () => { } },
    ]);
  }, [])

  const modalAlert = useCallback(() => {
    Modal.alert(
      "Revoke access ?",
      "Are you sure that you want to unregister from Step Challenge?",
      [
        {
          text: "Cancel",
          style: "cancel",
        },
        { text: "OK", onPress: () => revokeAccessHandler() },
      ]
    )
  }, [revokeAccessHandler])

  const success = useCallback((data) => {
    if (data.data.result) {
      Token.setToken(params.token);
      if (params.status === "true") {
        appleHealthDisconnect().then((res) =>
          res.data.result ? modalSuccess() : modalError()
        );
      } else modalError();
    }
  }, [params.token, params.status, modalError, modalSuccess])

  const failure = useCallback(() => {
    setError();
  }, [setError])

  const validate = useCallback((token) => {
    validateToken(token, success, failure);
  }, [failure, success])

  const syncButtonHandler = useCallback((initial = false) => {
    setRefetching(true);
    syncStepsData()
      .then((res) => {
        if (res.data.result) {
          setFetchedData(res.data.data);
          setFetchComplete(true)
          if (res.data.data.queryStatus === "partial" && !initial) {
            modalPartialSync();
          }
        } else {
          if (!initial) modalError();
        }
        setRefetching(false);
      })
      .catch(() => {
        if (!initial) {
          modalError();
        }
        setRefetching(false);
      })
  }, [setRefetching, setFetchedData, modalPartialSync, modalError])

  useLayoutEffect(() => {
    syncButtonHandler(true);
  }, [syncButtonHandler]);


  useEffect(() => {
    let { servicename, token, status } = params;
    if (servicename && token && status) {
      validate(params.token);
    }
  }, [params, validate]);

  return (
    <>
      <Modal
        visible={fetchComplete && !data?.appleHealth && data?.totalSteps === 0}
        transparent
        animationType='slide-up'
        onClose={() => console.warn("CLOSE")}
      >
        <div className="sync-error-container">
          <img src={SyncImageIcon} alt="sync image icon" />
          <div className="sync-error-title">Steps Sync Issue</div>
          <div className="sync-error-message">
            We are currently unable to sync your steps. To resolve this, please disconnect and reconnect your device.
          </div>
          <Button
            // type="warning"
            size="small"
            onClick={revokeAccessHandler}
            className="disconnect-button"
          >
            Disconnect
          </Button>
          <Button
            style={{
              marginTop: 10
            }}
            size="small"
            onClick={() => window.location.reload()}
          >
            Already Done!
          </Button>
        </div>
      </Modal>
      <SwipeAction
        autoClose
        style={{ backgroundColor: "transparent" }}
        right={right}
        onOpen={() => console.log("open")}
        onClose={() => console.log("close")}
      >
        <div className="row steps-info-container">
          <div
            onClick={() => { history.push("/leader-board") }}
            className='leaderboard-icon-container'
          >
            Step Challenge
            <img src={PodiumIcon} />
          </div>
          <div style={{ textAlign: "left" }}>
            <div className="steps-title-container">
              <h3 style={{ marginBottom: "10px", fontSize: "16px" }}>
                Get Your Move On
              </h3>
              {!data.appleHealth && (
                <span
                  onClick={syncButtonHandler}
                  className={`sync-button-container ${refetching && "loading"}`}
                >
                  <img src={ReloadIcon} />
                </span>
              )}
            </div>
            <span className="steps-count">
              <span>
                <img alt="icon-steps" src={StepsIcon} />
              </span>
              <span className="count-text txt-w-800">
                {!!!fetchedData
                  ? numberWithCommas(data.totalSteps)
                  : numberWithCommas(fetchedData.totalSteps)}
              </span>
              <span>Steps / Month</span>
            </span>
            <span className="steps-message">
              <span>Walking Step Counter</span>
            </span>
          </div>
          <div className="progress-container">
            <ProgressBar
              completed={calculatePercentage(
                !!!fetchedData ? data.totalSteps : fetchedData.totalSteps,
                data.targetSteps
              )}
            />
          </div>
          <div>
            <span className="calories-count">
              <img alt="icon-calories" src={BurnIcon} />
              <span className="txt-w-800">
                {numberWithCommas(
                  Math.ceil(
                    !!!fetchedData ? data.calorieBurn : fetchedData.calorieBurn
                  )
                ) || 0}
              </span>
              <span>Calories You Burnt</span>
            </span>
            <span className="target-container">
              <span className="txt-w-800">
                {numberWithCommas(5000)} Steps / Day
              </span>
              {` `}
              <img alt="icon-flag" src={FlagIcon} />
            </span>
          </div>
        </div>
      </SwipeAction></>
  );
};

StepsInfo.defaultProps = {
  data: { totalSteps: "0", calorieBurn: "0", targetSteps: "0" },
};

export default StepsInfo;
