import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Route from "react-router-dom/Route";

// components
import HomeScreen from "./scenes/Home/HomeScreen";
import FilterScreen from "./scenes/Filter/FilterScreen";
import EventDetailsScreen from "./scenes/EventDetails/EventDetails";
import ConfirmationScreen from "./scenes/Confirmation/Confirmation";
import CreateEventList from "./scenes/CreateEventList/CreateEventList";
import ExperienceDetailsScreen from "./scenes/ExperienceDetails/ExperienceDetails";
import InterestScreen from "./scenes/Interest/Interest";
import LandingScreen from "./scenes/LandingPage/LandingScreen";
import InviteScreen from "./scenes/Invite/Invite";
import StoreScreen from "./scenes/StoreScreen/StoreScreen";
import WalletScreen from "./scenes/WalletScreen/walletScreen";
import { CalenderScreen } from "./scenes/CalenderScreen/calenderScreen";
import StoreDetails from "./scenes/StoreDetails/storeDetails";
import { LeaderBoardScreen } from "./scenes/LeaderBoardScreen/leaderBoard";
import homeList from "./scenes/Home/scenes/HomeList/homeList";
import StepChallengeSetup from "./scenes/StepChallengeSetup";

class AppNavigator extends Component {
  render() {
    return (
      <Router>
        <Route path="/token/:id" exact component={LandingScreen} />
        <Route
          path="/step-challenge-setup"
          exact
          component={StepChallengeSetup}
        />
        <Route
          path="/step-challenge-setup/:id/:status"
          exact
          component={StepChallengeSetup}
        />
        <Route
          path="/step-challenge-setup/:id/:status/:token"
          exact
          component={StepChallengeSetup}
        />
        <Route
          path="/event-details/:eventId/:id"
          exact
          component={LandingScreen}
        />
        <Route path="/home" exact component={HomeScreen} />
        <Route
          path="/home/:servicename/:status/:token"
          exact
          component={HomeScreen}
        />
        <Route path="/event/:id" exact component={EventDetailsScreen} />
        <Route
          path="/experience/:id"
          exact
          component={ExperienceDetailsScreen}
        />
        <Route path="/confirm" exact component={ConfirmationScreen} />
        <Route path="/:component/filter" exact component={FilterScreen} />
        <Route path="/create" exact component={CreateEventList} />
        <Route path="/interest" exact component={InterestScreen} />
        <Route path="/invite/:expId" exact component={InviteScreen} />
        <Route path="/attendee/:expId" exact component={InviteScreen} />
        <Route path="/store" exact component={StoreScreen} />
        <Route path="/store-detail/:id" exact component={StoreDetails} />
        <Route path="/wallet" exact component={WalletScreen} />
        <Route path="/calender" exact component={CalenderScreen} />
        <Route path="/leader-board" exact component={LeaderBoardScreen} />
        <Route path="/list/happening" exact component={homeList} />
      </Router>
    );
  }
}

export default AppNavigator;
