import { Card, Flex } from "antd-mobile";
import React from "react";
import "./StoreListCard.scss";
import SkeletonStoreCard from "../../../../components/common/SkeletonLayouts/SkeletonStoreCard";

export function StoreListCard({ data, history }) {
  let { name, pointValue, image, stock, id, details } = data;

  if (!name) return <SkeletonStoreCard width={window.innerWidth / 2 - 20} />;

  return (
    <>
      <Card
        full
        onClick={() => history.push(`/store-detail/${id}`)}
        className="store-card-container"
      >
        {/* {stock===0?<div className="overlay"></div>:null} */}
        <Card.Body>
          <img className={`store-card-img ${stock === 0 ? "overlay" : ""}`} src={image}></img>
          <div className="store-card-content">
            <p className={`store-card-title  ${stock === 0 ? "overlay" : ""}`}>{name}</p>
            <p className={`store-card-description  ${stock === 0 ? "overlay" : ""}`}>{details}</p>
            <div className="r-card-details">
            <div className={`r-card-credit  ${stock === 0 ? "overlay" : ""}`}>
              <span>{pointValue + " Credits"}</span>
            </div>
            <div className="r-card-stock">
            <div className="maf-tag">
              <span>{stock ? stock + " Left" : "Restocking soon"}</span>
              
            </div>
            </div>
            </div>
            <div className={`redeem-action  ${stock === 0 ? "overlay" : ""}`}>
          <a className={`call-action ${stock === 0 ? "disabled-action" : ""}`}>Redeem</a>
        </div>
         
          </div>
        </Card.Body>
        <Card.Footer
         
         
          
        />
      </Card>
    </>
  );
}
