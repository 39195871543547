import API from "../endpoints";
import { httpGETRequest, httpPOSTRequest } from "../httpRequestHandler";

export const digimeConnect = (id) =>
  httpGETRequest(API.DIGIME + `/${id}`).then((res) => res.data);

export const digimeRevokeAccess = () =>
  httpGETRequest(API.DIGIME_REVOKE_ACCESS).then((res) => res.data);

export const appleHealthConnect = () =>
  httpGETRequest(API.APPLE_HEALTH_CONNECT);

export const appleHealthDisconnect = () =>
  httpGETRequest(API.APPLE_HEALTH_DISCONNECT);

export const appleHealthStatus = () => httpGETRequest(API.APPLE_HEALTH_STATUS);

export const syncStepsData = () => httpGETRequest(API.DIGIME_SYNC, null, 59000);

