import React, { Component } from "react";
import moment from "moment";
import "./calender.scss";
import { Flex, Icon } from "antd-mobile";

export default class Calendar extends Component {
  state = {
    dateObject: moment(),
    selectedDate: null,
  };

  weekdayshort = moment.weekdaysShort();

  componentDidMount() {
    let dateObject = moment();
    let date;
    if (dateObject.month() === 0) {
      date = moment(`${dateObject.year()}-1-15`, "YYYY-MM-DD");
    } else {
      date = moment(
        `${dateObject.year()}-${dateObject.month() + 1}-15`,
        "YYYY-MM-DD"
      );
    }
    let currentDate = moment(
      `${dateObject.year()}-${dateObject.month() + 1}-${new Date().getDate()}`,
      "YYYY-MM-DD"
    );
    this.onMonthChange(date, currentDate);
  }

  firstDayOfMonth = () => {
    let dateObject = this.state.dateObject;
    let firstDay = moment(dateObject).startOf("month").format("d");
    return firstDay;
  };

  daysInMonth = () => {
    let dateObject = this.state.dateObject;

    return moment(moment(dateObject).month() + 1, "MM").daysInMonth();
  };

  currentDay = (date) => {
    let dateObject = this.state.dateObject;
    let today = moment();

    return (
      dateObject.year() === today.year() &&
      dateObject.month() === today.month() &&
      date === today.date()
    );
  };

  month = () => {
    return this.state.dateObject.format("MMMM");
  };

  previous = () => {
    let dateObject = this.state.dateObject;
    let date;

    if (dateObject.month() === 0) {
      date = moment(`${dateObject.year() - 1}-12-15`, "YYYY-MM-DD");
    } else {
      date = moment(
        `${dateObject.year()}-${dateObject.month()}-15`,
        "YYYY-MM-DD"
      );
    }

    this.onMonthChange(date);
    this.props.onChange(date);
    this.setState({ dateObject: date });
  };

  onMonthChange(dateObject, date) {
    let startDate = moment(dateObject).startOf("month").format("YYYY-MM-DD");
    let endDate = moment(dateObject).endOf("month").format("YYYY-MM-DD");
    // console.log(startDate, endDate);
    this.props.onMonthChange(startDate, endDate, date);
  }

  next = () => {
    let dateObject = this.state.dateObject;
    let date;

    if (dateObject.month() === 11) {
      date = moment(`${dateObject.year() + 1}-1-15`, "YYYY-MM-DD");
    } else {
      date = moment(
        `${dateObject.year()}-${dateObject.month() + 2}-15`,
        "YYYY-MM-DD"
      );
    }
    this.onMonthChange(date);
    this.props.onChange(date);
    this.setState({ dateObject: date });
  };

  isEvent = (day) => {
    let { events } = this.props;

    let isEvent = false;
    events.map((item) => {
      let _date = moment(item.date);
      let selected = this.state.dateObject;
      if (
        _date.date() == day &&
        _date.month() === selected.month() &&
        _date.year() === selected.year()
      ) {
        isEvent = true;
      }
    });

    return isEvent ? "event-active" : "";
  };

  isSameDate = (date, day) => {
    let { dateObject, selectedDate } = this.state;
    if (!date) return false;

    let _date = moment(date);

    if (
      _date.date() === day &&
      _date.month() === dateObject.month() &&
      _date.year() === dateObject.year()
    )
      return true;
    else return false;
  };

  dateSelected = (day) => {
    let { dateObject } = this.state;

    let date = moment(
      `${dateObject.year()}-${dateObject.month() + 1}-${day}`,
      "YYYY-MM-DD"
    );

    this.props.filterDate(date);
    this.setState({ selectedDate: date });
  };

  render() {
    let weekdayshortname = this.weekdayshort.map((day) => {
      return (
        <th key={day} className="week-day">
          {day}
        </th>
      );
    });

    let blanks = [];
    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      blanks.push(<td className="calendar-day empty">{""}</td>);
    }

    let daysInMonth = [];
    for (let d = 1; d <= this.daysInMonth(); d++) {
      let currentDay = this.currentDay(d) ? "today" : "";
      daysInMonth.push(
        <td
          key={d}
          className={`${currentDay}`}
          onClick={() => this.dateSelected(d)}
        >
          <div
            className={`calendar-day ${
              this.isSameDate(this.state.selectedDate, d)
                ? "event-selected"
                : ""
            }`}
          >
            {d} <span className={`calender-event ${this.isEvent(d)}`}></span>
          </div>
        </td>
      );
    }

    var totalSlots = [...blanks, ...daysInMonth];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row); // if index not equal 7 that means not go to next week
      } else {
        rows.push(cells); // when reach next week we contain all td in last week to rows
        cells = []; // empty container
        cells.push(row); // in current loop we still push current row to new container
      }
      if (i === totalSlots.length - 1) {
        // when end loop we add remain date
        rows.push(cells);
      }
    });

    let daysinmonth = rows.map((d, i) => {
      return <tr>{d}</tr>;
    });

    let { selectedDate } = this.state;

    if (!selectedDate) this.dateSelected(new Date().getDate());
    return (
      <>
        <Flex justify="between" className="calendar-heaeder">
          <Flex.Item>
            <Icon onClick={this.previous} type="left"></Icon>
          </Flex.Item>
          <Flex.Item>
            <span className="calendar-label">{this.month()}</span>
          </Flex.Item>
          <Flex.Item>
            <Icon onClick={this.next} type="right"></Icon>
          </Flex.Item>
        </Flex>
        <table className="calendar-day">
          <thead>
            <tr>{weekdayshortname}</tr>
          </thead>
          <tbody>{daysinmonth}</tbody>
        </table>
      </>
    );
  }
}
