import { combineReducers } from 'redux'
import experienceReducer from './experienceReducer'
import storeReducer from './storeReducer'
import leaderBoardReducer from './leaderBoardReducer'
import profileReducer from './profileReducer'

export default combineReducers({
  experienceReducer,
  storeReducer,
  leaderBoardReducer,
  profileReducer
})
