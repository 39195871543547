import { Tabs } from 'antd-mobile'
import React, { Component } from 'react'
import { StickyContainer, Sticky } from 'react-sticky';
import { NavigationBar } from '../../components/common/NavBar/navBar'
import ProfileCard from '../Home/components/ProfileCard/ProfileCard'
import './leaderBoard.scss'
import LeaderTab from './tabs/leaderTab/leaderTab'
import TribeTab from './tabs/tribeTab/tribeTab'
import StepChallengeTab from './tabs/stepChallengeTab/StepChallengeTab';


function renderTabBar(props) {
  return (<Sticky topOffset={-62}>
    {({ style, isSticky }) => <div style={{ ...style, paddingTop: isSticky ? '62px' : '0px' }}><Tabs.DefaultTabBar {...props} /></div>}
  </Sticky>);
}

const tabs = [
  { title: 'Step Challenge' },
  { title: 'Leaderboard' },
  { title: 'Tribes' },
];



export class LeaderBoardScreen extends Component {

  render() {
    return (
      <div>
        <NavigationBar history={this.props.history} />
        <div className="wallet-main-container">
          <ProfileCard />
          <div className="tabs-container">
            <StickyContainer>
              <Tabs tabs={tabs}
                initialPage={0}
                renderTabBar={renderTabBar}
                swipeable={false}
                onChange={(tab, index) => { console.log('onChange', index, tab); }}
                onTabClick={(tab, index) => { console.log('onTabClick', index, tab); }}
              >
                <StepChallengeTab />
                <LeaderTab />
                <TribeTab />
              </Tabs>
            </StickyContainer>
          </div>
        </div>
      </div>
    )
  }
}
