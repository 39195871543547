import React, { useState, useLayoutEffect } from "react";

import SuccessAnimation from "../../../assets/animations/success01.gif";
import { servicesData } from "../constants";

const Success = ({ id }) => {
  const [data, setData] = useState(servicesData[3]);

  useLayoutEffect(() => {
    if (id) setData(servicesData.filter((item) => item.id === id)[0] || null);
  }, []);

  return (
    <>
      <div className="contents">
        <div className="row flex-1 flex-center-bottom">
          <img alt="success" className="success-image" src={SuccessAnimation} />
        </div>
        <div className="row flex-1 flex-center-top">
          <div>
            <h1>Congratulations</h1>
            <p className="desc-text">
              You are part of MyWellness <br /> Step Challenge
            </p>
            <br />
            <p className="desc-text">
              My Wellness uses your step data to provide a monthly step target
              for you. We encourage you to walk as many steps as possible
              throughout the day, and you will be rewarded with my wellness
              points
            </p>
          </div>
        </div>
        {data !== null && (
          <div className="row flex-1 flex-center-top flex-column">
            <div className="item-logo">
              <img alt="connect-option-logo" src={data.logo} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Success;
