import { listLeaderBoardAction, listLeaderBoardTribeAction, listStepLeaderBoardAction } from "../../actions/leaderBoardActions"
import API from "../endpoints"
import { httpGETRequest } from "../httpRequestHandler"

export const listLeaderBoard = (page, limit) => {
    return dispatch => {
        httpGETRequest(API.LEADER_BOARD, { page: page, limit: limit }).then(res => {
            if (res.data) {
                const _type = page === 0 ? 'FORCE_UPDATE' : 'NORMAL_PUSH'
                dispatch(listLeaderBoardAction(res.data, _type))
            } else {
                dispatch(listLeaderBoardAction(res))
            }
        })
            .catch((error) => {
                // console.log(error)
                dispatch(listLeaderBoardAction(error))
            })
    }
}


export const listStepLeaderBoard = (page, limit) => {
    return dispatch => {
        httpGETRequest(API.STEP_CHALLENGE_BOARD, { page: page, limit: limit }).then(res => {
            if (res.data) {
                const _type = page === 0 ? 'FORCE_UPDATE' : 'NORMAL_PUSH'
                dispatch(listStepLeaderBoardAction(res.data, _type))
            } else {
                dispatch(listStepLeaderBoardAction(res))
            }
        })
            .catch((error) => {
                // console.log(error)
                dispatch(listStepLeaderBoardAction(error))
            })
    }
}


export const listTribe = () => {
    return new Promise((resolve, reject) => {
        httpGETRequest(API.TRIBE).then(res => {
            // console.log(res.data)
            if (res.data) {
                resolve(res.data)
            } else {
                resolve(res)
            }
        }).catch(err => reject(err))
    })
}

export const listLeaderBoardTribe = (id, page, limit) => {
    return dispatch => {
        httpGETRequest(API.TRIBE_BOARD, { tribe: id, page: page, limit: limit }).then(res => {
            // console.log(res.data)
            if (res.data) {
                const _type = page === 0 ? 'FORCE_UPDATE' : 'NORMAL_PUSH'
                dispatch(listLeaderBoardTribeAction(res.data, _type))
            } else {
                dispatch(listLeaderBoardTribeAction(res))
            }
        })
            .catch((error) => {
                // console.log(error)
                dispatch(listLeaderBoardTribeAction(error))
            })
    }
}
