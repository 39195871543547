export default (
  state = {
    result: {
      items: [],
    },
    experiences: {
      items: [],
    },
    filterKey: "all",
    categories: [],
    filterCount: null,
    filter: {
      categories: "",
      opco: "",
      countries: "",
      price: [],
    },
    filterCreate: {
      categories: "",
      opco: "",
      countries: "",
      price: [],
      subcategory: "",
      subcategoryParent: {},
      requirement: [],
    },
    filterStore: {
      countries: "",
    },
  },
  action
) => {
  switch (action.type) {
    case "LIST_HAPPENINGS":
      return Object.assign({}, state, {
        result: {
          items:
            action.updateType === "FORCE_UPDATE"
              ? action.payload.items
              : [...state.result.items, ...action.payload.items],
          totalResult: action.payload.totalResult,
          create: action.payload.create,
        },
        isLoading: false,
      });
    case "LIST_EXPERIENCES":
      return Object.assign({}, state, {
        experiences: {
          items:
            action.updateType === "FORCE_UPDATE"
              ? action.payload.items
              : [...state.experiences.items, ...action.payload.items],
          totalResult: action.payload.totalResult,
        },
        isLoading: false,
      });
    case "LIST_CATEGORY":
      return Object.assign({}, state, {
        categories: action.payload,
      });
    case "UPDATE_FILTER_COUNT":
      // console.log("UPDATE_FILLTER_COUNT", action);
      const _filter =
        action.componentType !== "create"
          ? action.componentType === "store"
            ? state.filterStore
            : state.filter
          : state.filterCreate;
      if (action.payload && action.payload.defaultCountry) {
        const _defaultCountry = action.payload.defaultCountry;
        const defaultCountryDetails = action.payload.country.find(
          (c) => c.id == _defaultCountry
        );
        _filter.countries =
          state.filter.countries === "" &&
          defaultCountryDetails &&
          defaultCountryDetails.count != 0
            ? _defaultCountry
            : state.filter.countries;
      }
      if (action.componentType != "create") {
        if (action.componentType === "store") {
          // console.log("action.payload", action.payload);
          return Object.assign({}, state, {
            filterCount: {
              country: action.payload.country,
              sort: ["Name-asc", "Name-desc", "Credit-asc", "Credit-desc"],
            },
            filterStore: _filter,
          });
        } else {
          return Object.assign({}, state, {
            filterCount: action.payload,
            filter: _filter,
          });
        }
      } else {
        return Object.assign({}, state, {
          filterCount: action.payload,
          filterCreate: _filter,
        });
      }

    case "SET_FILTER_DATA":
      if (action.componentType === "create") {
        const _type = {
          categories: action.payload.categories,
          opco: action.payload.opco,
          countries: action.payload.countries,
          price: action.payload.price,
          subcategory: action.payload.subcategory,
          subcategoryParent: action.payload.subcategoryParent,
          requirement: action.payload.requirement,
        };
        return Object.assign({}, state, {
          filterCreate: _type,
        });
      } else if (action.componentType === "store") {
        const _type = {
          sort: action.payload.sort,
          countries: action.payload.countries,
        };
        return Object.assign({}, state, {
          filterStore: _type,
        });
      } else {
        const _type = {
          categories: action.payload.categories,
          opco: action.payload.opco,
          countries: action.payload.countries,
          price: action.payload.price,
        };
        return Object.assign({}, state, {
          filter: _type,
        });
      }
    case "SET_EXPERIENCE_FILTER":
      return Object.assign({}, state, {
        filterKey: action.payload,
      });
    case "SET_LIST_EMPTY":
      if (action.payload !== "CREATE") {
        return Object.assign({}, state, {
          result: {
            items: [],
          },
        });
      } else {
        return Object.assign({}, state, {
          experiences: {
            items: [],
          },
        });
      }
    case "EMPTY_FILTER_COUNT":
      // console.log("UNMOUNTING-store");
      return Object.assign({}, state, {
        filterCount: null,
      });
    case "SET_LOADING":
      return Object.assign({}, state, {
        isLoading: true,
      });
    default:
      return state;
  }
};
