import { Flex } from 'antd-mobile'
import React from 'react'
import './navigationBar.scss'

export function NavigationBar({ history }) {
    return (
        <div className="nav-main-container">
            <Flex justify="around">
            <Flex.Item>
                    <div
                        className="nav-item-container"
                        onClick={() => history.push("/calender")}>
                        <div className="nav-image-container">
                            <img
                                alt="discounts"
                                className="nav-image"
                                src={require('../../../../assets/icons/calendar.png')}></img>
                        </div>
                        <span>Calendar</span>
                    </div>
                </Flex.Item>
                <Flex.Item>
                    <div className="nav-item-container" onClick={() => history.push("/store")}>
                        <div className="nav-image-container">
                            <img
                                alt="discounts"
                                className="nav-image"
                                src={require('../../../../assets/icons/store.png')}></img>
                        </div>

                        <span>Rewards</span>
                    </div>
                </Flex.Item>
                <Flex.Item>
                    <div className="nav-item-container" onClick={() => history.push("/leader-board")}>
                        <div className="nav-image-container">
                            <img
                                alt="discounts"
                                className="nav-image"
                                src={require('../../../../assets/icons/leaderboard.png')}></img>
                        </div>
                        <span>Leaderboard</span>
                    </div>
                </Flex.Item>
                <Flex.Item>
                    <div className="nav-item-container" onClick={() => history.push("/wallet")}>
                        <div className="nav-image-container">
                            <img
                                alt="discounts"
                                className="nav-image"
                                src={require('../../../../assets/icons/wallet.png')}></img>
                        </div>
                        <span>Wallet</span>
                    </div>
                </Flex.Item>
                <Flex.Item>
                    <div className="nav-item-container" onClick={() => history.push("/create")}>
                        <div className="nav-image-container">
                            <img
                                alt="discounts"
                                className="nav-image"
                                src={require('../../../../assets/icons/discount.png')}></img>
                        </div>
                        <span>Discounts</span>
                    </div>
                </Flex.Item>
            </Flex>
        </div>
    )
}
