import { Card, Flex, WhiteSpace, WingBlank } from "antd-mobile";
import React from "react";
import Image from "../../../../components/common/Image/image";
import SkeletonCard from "../../../../components/common/SkeletonLayouts/SkeletonCard";
import "./eventCard.scss";

export const EventCardRow = (rowData, sectionID, rowID, history) => {
  return <EventCard rowData={rowData} rowID={rowID} history={history} />;
};

export const EventCard = ({ rowData, rowID, history }) => {
  const {
    id,
    cover,
    name,
    discount,
    points,
    required,
    join,
    priceUnit,
    price,
    discountPrice,
    currency,
    category,
    heppeningOn,
    deadline,
    who,
    going,
    sponsored,
    timeout,
    invited,
    type,
    ticket,
  } = rowData;
  let _date;
  if (heppeningOn) _date = heppeningOn.split("@")[1].substr(1);
  // console.log("rowData", cover);

  const handleEventClick = (id) => history.push(`/event/${id}`);

  if (!rowID) return <SkeletonCard />;

  return (
    <WingBlank size="md" key={rowID}>
      <Card
        className="event-card card padding-b-0  fade-in"
        style={{ backgroundImage: `url(${cover})` }}
        onClick={() => handleEventClick(id)}
      >
        <Card.Body>
          <div>
            <div className="rightalign home-additional-details-container">
              {invited ? (
                <div className="tag-container-time-new border-5">
                  <span className="color-white">Invited</span>
                </div>
              ) : null}
              {timeout ? (
                <div className="tag-container-time-new border-5 flex-1 bg-gray">
                  <span className="color-white color-gray">
                    Registration Closed
                  </span>
                </div>
              ) : join ? (
                <div className="tag-container-time-new border-5">
                  <span className="color-white">Registered</span>
                </div>
              ) : (
                <div className="tag-container-time-new border-5">
                  <span className="color-white">Register in {deadline}</span>
                </div>
              )}
            </div>
            {sponsored ? (
              <div className="home-star-icon">
                <img src="/icons/star.png" width="12px" height="12px" />
              </div>
            ) : null}
            {points ? (
              <div className="maf-tag event-points">
                <span>{points} points</span>
              </div>
            ) : null}
          </div>
        </Card.Body>
        <Card.Footer
          className="card-footer card-footer-120"
          content={
            <div className="text-align-left">
              <p className="card-event-title margin-5 margin-t-10">{name}</p>
              <p className="card-event-date margin-5">{heppeningOn}</p>
              {ticket ? (
                <p className="calender-card-event-required margin-5">
                  {ticket}
                </p>
              ) : null}
              <Flex className="margin-5 overflow-visible">
                <Flex.Item className="postition-relative padding-r-12">
                  {_renderGoing(who)}
                  {who && going - who.length > 0 ? (
                    <div className="workfam-avatar home-who-going-count">
                      <p>+{going - who.length}</p>
                    </div>
                  ) : null}
                </Flex.Item>
                <Flex.Item className="tag-container">
                  <span>{category}</span>
                </Flex.Item>
                {required ? (
                  <Flex.Item className="tag-container">
                    <span>{required}</span>
                  </Flex.Item>
                ) : null}
              </Flex>
              <div>
                <a className="call-action">Join Experience</a>
              </div>
            </div>
          }
          extra={
            <div>
              {type !== "byInternal" ? (
                <div className="create-card-content-container">
                  <div className="create-card-content-data-container">
                    <span className="create-card-main-text">{discount}</span>
                    <p className="create-card-main-sub-text">off</p>
                  </div>
                  <div className="create-card-content-data-container">
                    <p className="line-height-5">
                      <strike className="font-9 color-gray line-height-5">
                        {currency} {price}
                      </strike>
                    </p>
                    <span className="create-card-main-text">
                      {currency} {discountPrice}
                    </span>
                    <p className="create-card-main-sub-text">{priceUnit}</p>
                  </div>
                </div>
              ) : (
                <div className="create-card-content-data-container">
                  <span className="create-card-main-text">FREE</span>
                </div>
              )}
            </div>
          }
        />
      </Card>
      <WhiteSpace size="lg" />
    </WingBlank>
  );
};

const _renderGoing = (who) => {
  // console.log("who", who);
  return who
    ? who.map((data) => {
        // console.log("index", who.indexOf(data));
        const _index = who.indexOf(data);
        return (
          <Image
            key={data.id}
            src={data.image || "/icons/profile.png"}
            width="15"
            height="15"
            className={
              _index === 0
                ? "workfam-avatar"
                : "workfam-avatar home-margin-avatar"
            }
            fallbackurl="/icons/profile.png"
          ></Image>
        );
      })
    : null;
};

export const DefaultEventCard = ({ history }) => {
  const handleEventClick = () => history.push(`/create`);

  return (
    <WingBlank size="md" key={"default"}>
      <Card
        className="event-card card padding-b-0  fade-in default-card"
        style={{ backgroundColor: "lightgray" }}
        onClick={() => handleEventClick()}
      >
        <Card.Body className="ebent-myWellness"></Card.Body>
        <Card.Footer
          className="card-footer"
          content={
            <div className="text-align-center">
              <div className="create-now-button">
                <p>Make it Happen</p>
              </div>
            </div>
          }
        />
      </Card>
      <WhiteSpace size="lg" />
    </WingBlank>
  );
};
