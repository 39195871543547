import { Flex } from 'antd-mobile';
import React from 'react';
import './storeOrderSuccess.scss'


export const StoreOrderSuccess = ({ title, message, collection, history, error, balance, onClose}) => (
    <>
        <p className="store-success-title">{title}</p>
        <img src={require(!error?'../../../../assets/icons/booking.png': '../../../../assets/icons/outofstock.svg')} alt="warning" width="41" className="store-success-image"/>
       
        {!error? <Flex justify="center" className="model-row no-border new-balance">
            <Flex.Item className="model-instructions-title"><img src={require('../../../../assets/icons/wallet.svg')} alt="wallet" width="30" height="30"></img> New Wallet Balance</Flex.Item>
            <Flex.Item className="model-instructions-value">{balance} Credits</Flex.Item>
        </Flex>: null}
        <p className="model-instructions text-center info-text">
        {error ? error : message}
        </p>
        {/* {!error? <><p className="model-instructions-header">Collection Process</p>
        <p className="model-instructions">
            {collection}
        </p></>: null} */}
        <div className="maf-primary-button">
            <p onClick={() => error?onClose() :history.replace('/home')}>Done</p>
        </div>
    </>
)