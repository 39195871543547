import React, { Component } from "react";
import "./homeList.scss";
import { connect } from "react-redux";
import {
  setListEmpty,
  emptyFilterCount,
} from "../../../../actions/experienceActions";
import {
  getHappeningList,
  getMyHappenings,
  getFilterCount,
} from "../../../../apiManager/services/experienceServices";
import {
  Card,
  WingBlank,
  WhiteSpace,
  NavBar,
  Icon,
  Flex,
  ListView,
  PullToRefresh,
  Accordion,
  Toast,
} from "antd-mobile";
import { Link } from "react-router-dom";
import DEEPLINK from "../../../../apiManager/endpoints/deeplink";
import { EventCard, EventCardRow } from "../../components/EventCard/eventCard";
import SkeletonCard from "../../../../components/common/SkeletonLayouts/SkeletonCard";
const LIMIT = 20;

const NUM_ROWS = 5;

class HomeListScreen extends Component {
  constructor(props) {
    super(props);
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });
    this.state = {
      dataSource,
      isLoading: true,
      initialLoad: true,
      isUpdated: false,
      myEvents: [],
      events: [],
    };
  }

  componentDidMount() {
    this.props.getFilterCount("home");
    // console.log(this.props.match.params.id)

    this.fetchMyEvents();
  }

  componentWillUnmount() {
    // console.log("UNMOUNTING");
    this.props.emptyFilterCount();
  }

  _onDataArrived(newData) {
    this._data = this._data.concat(newData);
    this.setState({
      dataSource: this.state.dataSource.cloneWithRows(this._data),
    });
  }

  _setLoadingNumber(number) {
    this.setState({
      loadingCount: number,
    });
  }

  componentDidUpdate() {
    // console.log(this.props.filterCount);
    if (
      this.props &&
      this.props.result &&
      this.props.result.items &&
      this.props.result.items.length === 0 &&
      this.props.filterCount &&
      this.props.filterCount.category.length > 0 &&
      !this.flag
    ) {
      this.handleListExperience(0, LIMIT);
      this._setLoadingNumber(LIMIT);
      this.flag = true;
    }
  }

  static getDerivedStateFromProps(props, state) {
    // console.log("data", props, state);
    if (props.result && props.result.items) {
      return {
        dataSource: state.dataSource.cloneWithRows(props.result.items),
      };
    } else {
      return null;
    }
  }

  fetchMyEvents = () => {
    getMyHappenings(this._success, this.failure);
  };

  _success = (data) => {
    // console.log("my interest", data);
    if (data.data.result) {
      this.setState({ myEvents: data.data.data });
    }

    // this.setState({
    //   myEvents: [
    //     {
    //       cover: 'https://s3.us-east-2.amazonaws.com/workfam-upload/media/company-5d1319c8828b1806d4bc4a62/e0f7ffd8-1573118838750-486.jpg',
    //       name: 'adsfghjkhgfds',
    //       date: '88-88-8888'
    //     },
    //     {
    //       cover: 'https://s3.us-east-2.amazonaws.com/workfam-upload/media/company-5d1319c8828b1806d4bc4a62/e0f7ffd8-1573118838750-486.jpg',
    //       name: 'ads',
    //       date: '88-88-888'
    //     }
    //   ]
    // })
  };

  failure = (error) => {
    const errorObj = JSON.parse(JSON.stringify(error));

    // console.log(errorObj.message);
    Toast.fail(errorObj.message, 1);
  };

  onEndReached = (event) => {
    if (
      this.props &&
      this.props.result &&
      this.props.result.totalResult &&
      this.props.result.totalResult > this.props.result.items.length
    ) {
      const _page = this.props.result.items.length / LIMIT;
      this.setState({ initialLoad: false });
      this.handleListExperience(_page, LIMIT);
      // console.log("page", _page);
      const _loadingCount =
        (_page + 1) * LIMIT > this.props.result.totalResult
          ? this.props.result.totalResult % LIMIT
          : LIMIT;
      this._setLoadingNumber(_loadingCount);
    }
  };

  renderMyEvents = () => {
    const that = this;
    return this.state.myEvents.map(function (item, i) {
      return (
        <th
          key={item.id}
          className="postition-relative"
          onClick={() => that.handleEventClick(item.id)}
        >
          <div
            className="home-my-events-item"
            style={{ backgroundImage: `url(${item.cover})` }}
          />
          <div className="home-my-events-item-overlay-container" />
          <p className="home-my-events-name">
            {item.name && item.name.length > 12
              ? item.name.substr(0, 12) + ".."
              : item.name}
          </p>
          <div className="home-my-events-date-container">{item.date}</div>
        </th>
      );
    });
  };

  render() {
    // console.log("print", this.props.filterCount);

    let { events } = this.state;

    return (
      <>
        <NavBar
          mode="light"
          leftContent={
            <Icon
              key="1"
              type="left"
              className="navbar-back-icon"
              onClick={() => {
                this.props.history.goBack();
              }}
            />
          }
          className="navbar-container"
        >
          {" "}
          MyWellness
        </NavBar>
        <div className="main-container">
          {this.state.myEvents.length > 0 ? (
            <div className="home-my-events-container">
              {this.state.myEvents.length > 0 ? (
                <div className="home-my-event-coount">
                  {this.state.myEvents.length}
                </div>
              ) : null}
              <Accordion
                accordion
                openAnimation={{}}
                className="my-accordion"
                onChange={this.onChange}
              >
                <Accordion.Panel header="My Experiences" className="pad">
                  <div>{this.renderMyEvents()}</div>
                </Accordion.Panel>
              </Accordion>
            </div>
          ) : null}
          <div className="home-heading-container slide-top">
            <span className="home-heading-container-title margin-s-20">
              All Experiences
            </span>
            {this.props.result.totalResult > 0 ? (
              <div className="home-all-event-coount home-list-all-event-coount">
                {this.props.result.totalResult}
              </div>
            ) : null}
            <div
              className="home-heading-container-filter margin-s-20 margin-t-10"
              onClick={this.handleFilterClick}
            >
              <span>Filter</span>
              <img src="/icons/filter.png" width="15px" height="11px" />
              {(this.props.filter.countries !== ""
                ? this.props.filter.countries.split(",").length
                : 0) +
                (this.props.filter.categories !== ""
                  ? this.props.filter.categories.split(",").length
                  : 0) +
                (this.props.filter.opco !== ""
                  ? this.props.filter.opco.split(",").length
                  : 0) +
                (this.props.filter.price.length > 0 ? 1 : 0) >
              0 ? (
                <div className="home-filter-count">
                  {(this.props.filter.countries !== ""
                    ? this.props.filter.countries.split(",").length
                    : 0) +
                    (this.props.filter.categories !== ""
                      ? this.props.filter.categories.split(",").length
                      : 0) +
                    (this.props.filter.opco !== ""
                      ? this.props.filter.opco.split(",").length
                      : 0) +
                    (this.props.filter.price.length > 0 ? 1 : 0)}
                </div>
              ) : null}
            </div>
          </div>
          <div>
            <ListView
              dataSource={this.state.dataSource}
              renderRow={(a, b, c) => EventCardRow(a, b, c, this.props.history)}
              className="am-list"
              pageSize={4}
              useBodyScroll
              onScroll={() => {
                console.log("scroll");
              }}
              scrollRenderAheadDistance={500}
              onEndReached={this.onEndReached}
              initialListSize={this.props.result.items.length}
              pullToRefresh={
                <PullToRefresh
                  refreshing={this.state.refreshing}
                  onRefresh={this.onRefresh}
                  indicator={{
                    activate: "release to refresh",
                    deactivate: "",
                    finish: "refreshed",
                  }}
                />
              }
              onEndReachedThreshold={10}
            />
          </div>
          {this.props &&
          this.props.result &&
          this.props.result.items.length !== this.props.result.totalResult ? (
            <div>
              {/* <div className='create-loading-container'>
            <div className='sk-circle'>
              <div className='sk-circle1 sk-child' />
              <div className='sk-circle2 sk-child' />
              <div className='sk-circle3 sk-child' />
              <div className='sk-circle4 sk-child' />
              <div className='sk-circle5 sk-child' />
              <div className='sk-circle6 sk-child' />
              <div className='sk-circle7 sk-child' />
              <div className='sk-circle8 sk-child' />
              <div className='sk-circle9 sk-child' />
              <div className='sk-circle10 sk-child' />
              <div className='sk-circle11 sk-child' />
              <div className='sk-circle12 sk-child' />
            </div>
            <p>{this.state.initialLoad ? 'loading..' : 'loading ' + this.state.loadingCount + ' more..'}</p>
          </div> */}
              <SkeletonCard width={window.innerWidth - 20} />
              <SkeletonCard width={window.innerWidth - 20} />
              <SkeletonCard width={window.innerWidth - 20} />
            </div>
          ) : null}
          {/* {this.props.result ? this.renderExperienceCards() : null} */}
        </div>
      </>
    );
  }

  onRefresh = () => {
    this.setState({ pullToRefresh: true });
    this.handleListExperience(0, LIMIT);
    this.fetchMyEvents();
  };

  handleListExperience = (page, limit) =>
    this.props.getHappeningList(
      page,
      limit,
      this.props.filter.categories,
      this.props.filter.countries,
      this.props.filter.opco,
      this.props.filter.price
    );

  handleFilterClick = () => this.props.history.push("/home/filter");

  handleCreateClick = () => this.props.history.push("/create");
}

const mapStateToProps = (state) => ({
  result: state.experienceReducer.result,
  filter: state.experienceReducer.filter,
  filterCount: state.experienceReducer.filterCount,
});

const mapDispatchToProps = (dispatch) => ({
  getHappeningList: (page, limit, category, country, opco, price) =>
    dispatch(getHappeningList(page, limit, category, country, opco, price)),
  setListEmpty: (type) => dispatch(setListEmpty(type)),
  getFilterCount: () => dispatch(getFilterCount()),
  emptyFilterCount: () => dispatch(emptyFilterCount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeListScreen);
