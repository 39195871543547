export const listExperiences = (data, type) => (dispatch) => {
  // console.log("data", data);
  dispatch({
    type: "LIST_HAPPENINGS",
    payload: data,
    updateType: type,
  });
};

export const listallexperience = (data, type) => (dispatch) => {
  // console.log("data", data);
  dispatch({
    type: "LIST_EXPERIENCES",
    payload: data,
    updateType: type,
  });
};

export const serviceActionError = (data) => (dispatch) => {
  // console.log("data", data);
  dispatch({
    type: "SIMPLE_ACTION_1",
    payload: "Redux configuration working well",
  });
};

export const listCategories = (data) => (dispatch) => {
  // console.log("data", data);
  dispatch({
    type: "LIST_CATEGORY",
    payload: data,
  });
};

export const updateFilterCount = (data, type) => (dispatch) => {
  // console.log("data", data, type);
  dispatch({
    type: "UPDATE_FILTER_COUNT",
    payload: data,
    componentType: type,
  });
};

export const setFilterData = (data, componentType) => (dispatch) => {
  // console.log("data", data);
  dispatch({
    type: "SET_FILTER_DATA",
    payload: data,
    componentType: componentType,
  });
};

export const setListEmpty = (data) => (dispatch) => {
  dispatch({
    type: "SET_LIST_EMPTY",
    payload: data,
  });
};

export const setCreateFilter = (data) => (dispatch) => {
  dispatch({
    type: "SET_EXPERIENCE_FILTER",
    payload: data,
  });
};

export const emptyFilterCount = () => (dispatch) => {
  // console.log("UNMOUNTING-action");
  dispatch({
    type: "EMPTY_FILTER_COUNT",
  });
};

export const setLoading = () => (dispatch) => {
  dispatch({
    type: "SET_LOADING",
  });
};
