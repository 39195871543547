import { UPDATE_PROFILE } from "../actions/profileActions"

export default (state = {
    profile: {}
}, action) => {
    switch (action.type) {
        case UPDATE_PROFILE:
            let data = {}
            if(action.payload && action.payload.data) 
                data = action.payload.data
            return Object.assign({}, state, {
                profile: action.payload.data
            })
        default:
            return state
    }
}
