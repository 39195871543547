import React, { Component } from "react";
import "./CreateEventList.scss";
import { connect } from "react-redux";
import {
  getExperienceList,
  getCategories,
} from "../../apiManager/services/experienceServices";
import {
  Card,
  WingBlank,
  WhiteSpace,
  NavBar,
  Icon,
  Flex,
  ListView,
  Empty,
  PullToRefresh,
} from "antd-mobile";
import { setListEmpty, setFilterData } from "../../actions/experienceActions";
import { CreateEventCardRow } from "./components/createEventCard/createEventCard";
import SkeletonCardList from "../../components/common/SkeletonLayouts/SkeletonCardList";
const category = {
  all: "/icons/all.png",
  "5b684078f5dbf79fbcfb8b7f": "/icons/adventure.png",
  "5b68669bf8ec8e95000a2685": "/icons/art.png",
  "5b8526c3fafdf1c6505707eb": "/icons/wealth-wellness.png",
  "5bb0ad7c1fe356e6a44d328f": "/icons/kids.png",
  "5bb0ae251fe356e6a44d329b": "/icons/learning.png",
  "5b683f3645d12ec2e8cb9eac": "/icons/sports.png",
};

const LIMIT = 20;

const NUM_ROWS = 5;

function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = pIndex * NUM_ROWS + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
    // console.log(dataBlob);
  }
  return dataBlob;
}

class CreateEventListScreen extends Component {
  constructor(props) {
    super(props);
    this.topCreate = React.createRef() 
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });
    this.state = {
      dataSource,
      isLoading: true,
      initialLoad: true,
      selectedCategory: "all",
      listHidden: false,
      refresh: true,
    };
  }
 
  componentDidMount() {
    // console.log("working");
    if (
      this.props &&
      this.props.experiences &&
      this.props.experiences.items &&
      this.props.experiences.items.length == 0
    ) {
      this.handleListExperience(0, LIMIT, this.props.filterKey);
      this._setLoadingNumber(LIMIT);
    }

    if (this.props.categories.length === 0) {
      this.props.getCategories();
    }
  }

  _onDataArrived(newData) {
    this._data = this._data.concat(newData);
    this.setState({
      dataSource: this.state.dataSource.cloneWithRows(this._data),
    });
  }

  _setLoadingNumber(number) {
    this.setState({
      loadingCount: number,
    });
  }

  static getDerivedStateFromProps(props, state) {
    // console.log("data", props, state);
    if (props.experiences && props.experiences.items) {
      return {
        dataSource: state.dataSource.cloneWithRows(props.experiences.items),
        listHidden: false,
      };
    } else {
      return null;
    }
  }

  onEndReached = (event) => {
    if (
      this.props &&
      this.props.experiences &&
      this.props.experiences.totalResult &&
      this.props.experiences.totalResult > this.props.experiences.items.length
    ) {
      const _page = this.props.experiences.items.length / LIMIT;
      this.setState({ initialLoad: false });
      this.handleListExperience(_page, LIMIT, this.props.filterKey);
      // console.log("page", _page);
      const _loadingCount =
        (_page + 1) * LIMIT > this.props.experiences.totalResult
          ? this.props.experiences.totalResult % LIMIT
          : LIMIT;
      this._setLoadingNumber(_loadingCount);
    }
  };

  _renderCategories = () => {
    let _data = [{ name: "All", id: "all" }];
    _data = [..._data, ...this.props.categories];
    if (
      this.element &&
      this.state.leftScroll &&
      this.element.getBoundingClientRect().left
    ) {
      console.log(
        this.state.leftScroll,
        this.element.getBoundingClientRect().left
      );
    }
    return _data.map((data) => {
      return (
        <th className="padding-5">
          <div
            className={
              this.props.filter.categories.includes(data.id) ||
              (data.id == "all" && !this.props.filter.categories)
                ? "padding-5 create-category-active"
                : "padding-5"
            }
            onClick={() => {
              this.handleCategoryClick(data.id);
            }}
          >
            <img src={category[data.id]} width="15px" height="15px" />
            <p className="margin-5 font-8">{data.name}</p>
          </div>
        </th>
      );
    });
  };

  handleCategoryClick = (id) => {
    var _filter = this.props.filter;
    const _tempArray = this.props.filter.categories.split(",");
    if (id == "all") {
      _filter.categories = "";
      _filter.subcategory = "";
    } else {
      if (this.props.filter.categories.includes(id)) {
        const _index = _tempArray.indexOf(id);
        _filter.categories = [
          ..._tempArray.slice(0, _index),
          ..._tempArray.slice(_index + 1, _tempArray.length),
        ].toString();
        if (_filter.subcategory) {
          let _subCategory = _filter.subcategory.split(",");
          _subCategory.map((data) => {
            if (_filter.subcategoryParent[data] == id) {
              const _subIndex = _subCategory.indexOf(data);
              _subCategory = [
                ..._subCategory.slice(0, _subIndex),
                ..._subCategory.slice(_subIndex + 1, _subCategory.length),
              ];
            }
          });
          _filter.subcategory = _subCategory.toString();
        }
      } else {
        if (_tempArray[0] == "") {
          _tempArray[0] = id;
        } else {
          _tempArray.push(id);
        }
        _filter.categories = _tempArray.toString();
      }
    }
    this.props.setFilterData(_filter);
    // this.props.setListEmpty('CREATE')
    this.handleListExperience(
      0,
      LIMIT,
      _filter.categories,
      _filter.subcategory
    );
  };

  render() {
    
    
    if (this.element && !this.state.leftScroll) {
      this.setState({
        leftScroll: this.element.getBoundingClientRect().left + 400,
      });
    }
    return (
      <div className="main-container">
        <NavBar
          mode="light"
          leftContent={
            <Icon
              key="1"
              type="left"
              className="navbar-back-icon"
              onClick={this.handleBackClick}
            />
          }
          className="navbar-container"
        >
          {" "}
          MyWellness
        </NavBar>
        <div className="create-category-container">
          <div>
            <div
              className="home-heading-container-filter margin-s-20 margin-t-10 filter-create-border"
              onClick={this.handleFilterClick}
            >
              <span className="f-block-color font-12 margin-l-5">Filter</span>
              {(this.props.filter.countries !== ""
                ? this.props.filter.countries.split(",").length
                : 0) +
                (this.props.filter.categories !== ""
                  ? this.props.filter.categories.split(",").length
                  : 0) +
                (this.props.filter.opco !== ""
                  ? this.props.filter.opco.split(",").length
                  : 0) +
                (this.props.filter.price.length > 0 ? 1 : 0) +
                (!Array.isArray(this.props.filter.subcategory) &&
                this.props.filter.subcategory !== "" &&
                typeof this.props.filter.subcategory === "string"
                  ? this.props.filter.subcategory.split(",").length
                  : 0) +
                (this.props.filter.requirement.length > 0 ? 1 : 0) >
              0 ? (
                <span className="font-12 f-block-color">
                  {" "}
                  (
                  {(this.props.filter.countries !== ""
                    ? this.props.filter.countries.split(",").length
                    : 0) +
                    (this.props.filter.categories !== ""
                      ? this.props.filter.categories.split(",").length
                      : 0) +
                    (this.props.filter.opco !== ""
                      ? this.props.filter.opco.split(",").length
                      : 0) +
                    (this.props.filter.price.length > 0 ? 1 : 0) +
                    (this.props.filter.subcategory !== "" &&
                    !Array.isArray(this.props.filter.subcategory) &&
                    typeof this.props.filter.subcategory === "string"
                      ? this.props.filter.subcategory.split(",").length
                      : 0) +
                    (this.props.filter.requirement.length > 0 ? 1 : 0)}
                  )
                </span>
              ) : null}
              <img
                src="/icons/filter-new-white.png"
                width="15px"
                height="11px"
              />
            </div>
          </div>
          <div
            className="overflow-scroll"
            ref={(el) => {
              this.element = el;
            }}
            onScroll={() => {
              if (this.element.scrollLeft == 0) {
                this.setState({ refresh: true });
              } else if (this.element.scrollLeft > 0) {
                this.setState({ refresh: true });
              }
            }}
          >
            {this._renderCategories()}
          </div>
        </div>
        <div  ref={this.topCreate} className="create-heading-container margin-t-130 slide-top postition-relative">
          {this.props.experiences.totalResult > 0 ? (
            <div className="create-all-event-coount">
              {this.props.experiences.totalResult}
            </div>
          ) : null}
          <span className="heading-container-title margin-s-20 margin-t-10 flex-1 text-left">
            Make it Happen
          </span>
        </div>
        <div >
        {
         this.props &&
         this.props.experiences && this.props.experiences.totalResult==0?(<div className="no-result"><p>No result found</p><p>Try diffrent filter</p></div>):null
        }
          <ListView
            dataSource={this.state.dataSource}
            renderRow={(a, b, c) =>
              CreateEventCardRow(a, b, c, this.props.history)
            }
            classNammake="am-list"
            pageSize={4}
            useBodyScroll
            onScroll={() => {
              console.log("scroll");
            }}
            scrollRenderAheadDistance={500}
            onEndReached={this.onEndReached}
            initialListSize={this.props.experiences.items.length}
            pullToRefresh={
              <PullToRefresh
                refreshing={this.state.refreshing}
                onRefresh={this.onRefresh}
                indicator={{
                  activate: "release to refresh",
                  deactivate: "",
                  finish: "refreshed",
                }}
              />
            }
            
            onEndReachedThreshold={10}
          />
        
          {this.props &&
          this.props.experiences &&
          this.props.experiences.items.length !==
            this.props.experiences.totalResult ? (
            <div>
              {/* <div className='create-loading-container'>
            <div className='sk-circle'>
              <div className='sk-circle1 sk-child' />
              <div className='sk-circle2 sk-child' />
              <div className='sk-circle3 sk-child' />
              <div className='sk-circle4 sk-child' />
              <div className='sk-circle5 sk-child' />
              <div className='sk-circle6 sk-child' />
              <div className='sk-circle7 sk-child' />
              <div className='sk-circle8 sk-child' />
              <div className='sk-circle9 sk-child' />
              <div className='sk-circle10 sk-child' />
              <div className='sk-circle11 sk-child' />
              <div className='sk-circle12 sk-child' />
            </div>
            <p>{this.state.initialLoad ? 'loading..' : 'loading ' + this.state.loadingCount + ' more..'}</p>
          </div> */}
              <SkeletonCardList width={window.innerWidth - 20} />
              <SkeletonCardList width={window.innerWidth - 20} />
              <SkeletonCardList width={window.innerWidth - 20} />
            </div>
          ) : this.props &&
          this.props.experiences && this.props.experiences.totalResult>=3? (<div>
            <p>That's all folks!</p>
             <a className="backToTop" onClick={() => this.scrollTohowItWorks()}>Scroll to top</a>
            </div>):null}
        </div>

        {/* {this.props.experiences ? this.renderExperienceCards() : null} */}
      </div>
    );
  }
  scrollTohowItWorks = () =>  window.scroll({
    top: this.topCreate.current.offsetTop,
    left: 0,
    behavior: 'smooth'
  });
  onRefresh = () => {
    this.setState({
      listHidden: true,
      pullToRefresh: true,
    });
    this.handleListExperience(0, LIMIT, this.props.filterKey);
  };

  handleListExperience = (page, limit, category, subcategory) =>
    this.props.getExperienceList(
      page,
      limit,
      category === "all" &&
        this.props.filter.categories &&
        !this.props.filter.categories.split(",") > 0
        ? ""
        : this.props.filter.categories,
      this.props.filter.countries,
      this.props.filter.opco,
      this.props.filter.price,
      this.props.filter.requirement,
      subcategory || this.props.filter.subcategory
    );

  handleEventClick = (id) => this.props.history.push(`/experience/${id}`);

  handleFilterClick = () => this.props.history.push("/create/filter");

  handleCreateClick = () => this.props.history.push("/create");

  handleBackClick = () => this.props.history.replace("/home");
}

const mapStateToProps = (state) => ({
  categories: state.experienceReducer.categories,
  experiences: state.experienceReducer.experiences,
  filterKey: state.experienceReducer.filterKey,
  filter: state.experienceReducer.filterCreate,
});

const mapDispatchToProps = (dispatch) => ({
  getExperienceList: (
    page,
    limit,
    category,
    country,
    opco,
    price,
    requirement,
    subcategory
  ) =>
    dispatch(
      getExperienceList(
        page,
        limit,
        category,
        country,
        opco,
        price,
        requirement,
        subcategory
      )
    ),
  getCategories: () => dispatch(getCategories()),
  setListEmpty: (type) => dispatch(setListEmpty(type)),
  setFilterData: (data) => dispatch(setFilterData(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEventListScreen);
