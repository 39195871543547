import { httpGETRequest, httpPOSTRequest } from '../httpRequestHandler'
import API from '../endpoints'
import { listStore, listStoreHistoryAction, updateDetailedStore} from '../../actions/storeActions'

export const getStoreList = (page, limit, country, sortby, orderby) => {
    return dispatch => {
      httpGETRequest(API.STORE, { page: page, limit: limit, country: country, sortby: sortby, orderby:orderby }).then(res => {
        // console.log(page, limit, country, sortby, orderby, res.data)
        if (res.data) {
          const _type = page === 0 ? 'FORCE_UPDATE' : 'NORMAL_PUSH'
          dispatch(listStore(res.data, _type))
        } else {
          dispatch(listStore(res))
        }
      })
        .catch((error) => {
          // console.log(error)
          dispatch(listStore(error))
        })
    }
}

export const getStoreDetails = (id) => {
  return dispatch => {
    httpGETRequest(API.STORE + "/" + id).then(res => {
      // console.log(res.data)
      if (res.data) {
        dispatch(updateDetailedStore(res.data))
      } else {
        dispatch(updateDetailedStore(res))
      }
    })
      .catch((error) => {
        // console.log(error)
        dispatch(listStore(error))
      })
  }
}

export const listStoreHistory = (page, limit) => {
  return dispatch => {
      httpGETRequest(API.LEADER_STORE_HISTORY, { page: page, limit: limit }).then(res => {
          // console.log(res.data)
          if (res.data) {
              const _type = page === 0 ? 'FORCE_UPDATE' : 'NORMAL_PUSH'
              dispatch(listStoreHistoryAction(res.data, _type))
          } else {
              dispatch(listStoreHistoryAction(res))
          }
      })
          .catch((error) => {
              // console.log(error)
              dispatch(listStoreHistoryAction(error))
          })
  }
}

export const orderStore = (id, count) => {
  return new Promise((resolve, reject) => {
      httpPOSTRequest(API.STORE_ORDER, {id: id, quantity: count}).then(res => {
          // console.log(res.data)
          if (res.data) {
              resolve(res.data)
          } else {
              resolve(res)
          }
      }).catch(err => reject(err))
  })
}