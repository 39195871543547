import React from "react";

import Image from "../../../components/common/Image/image";

const Intro = ({ skip }) => {
  return (
    <>
      <div onClick={skip} className="skip-tag-container">
        <p className="label-skip">Skip This</p>
      </div>
      <div className="contents">
        <div className="image-container">
          <Image
            className="image-dfc"
            src={require("../../../assets/icons/walking01.jpg")}
          />
        </div>
        <div className="row flex-center-top details-container">
          <div className="text-container">
            <h1>Get Your Move On</h1>
            <p className="desc-text">
              Set up your account and challenge yourself/your team to get your steps in. Collect
              MyWellness points to redeem at the rewards store.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
