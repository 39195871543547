import { Card, Flex, ListView, WhiteSpace } from 'antd-mobile'
import React, { useEffect, useState } from 'react'
import { listLeaderBoard, listTribe } from '../../../../apiManager/services/leaderBoardServices';
import Image from '../../../../components/common/Image/image'
import './tribeFilter.scss'

export function TribeFilter({ onInitFilter }) {

    const [tribes, setTribes] = useState([]);
    const [active, setActive] = useState(null);
    const [name, setName] = useState(null);
    const [category, setCategory] = useState(null);

    useEffect(() => {
        listTribe().then(res => {
            let tribeArray =res.items || []
            setTribes(tribeArray)
            // console.log(tribeArray)
            if(tribeArray && Array.isArray(tribeArray) && tribeArray.length > 0) {
                onInitFilter(tribeArray[0].id)
                setActive(tribeArray[0].id)
                setName(tribeArray[0].name)
                setCategory(tribeArray[0].category)
            }
        })
    }, []);

    const onClicked = (item) => {
        let id = item.id
        onInitFilter(id)
        setActive(id)
        setName(item.name)
        setCategory(item.category)
    }
const convertSamll= (name)=> {
    if (name){
        return  name.toLowerCase()
    }else {
        return  name.toLowerCase()
    }

}
    return (
        <>
        <Flex className="tribe-filter">
            {tribes.map(item =>
                <Flex.Item key={item.id} onClick={() => onClicked(item)} className={active === item.id? 'tribe-active' : null}>
                    <img src={active === item.id ? item.iconactive : item.icon} alt=""></img>
                    <p>{item.name}</p>
                </Flex.Item>
            )}
        </Flex>
        <div className="decription-container">
            <p className="description-text">To become {name=="Adrenaline Junkies"? 'an' : 'a' } {name} member, participate in or create a MyWellness Experience in {category}.</p>
        </div>
        </>
        

    )
}
