import React, { Component } from 'react'
import './FilterScreen.scss'
import { connect } from 'react-redux'
import { getCategories, getFilterCount, getHappeningList, getExperienceList } from '../../apiManager/services/experienceServices'
import { setFilterData, setListEmpty, emptyFilterCount } from '../../actions/experienceActions'

import { Icon, Range, NavBar, Slider } from 'antd-mobile'
import { getStoreList } from '../../apiManager/services/storeServices'

class FilterScreen extends Component {
  state = {
    loading: true,
    navSelected: 'Category',
    filter: {
      categories: '',
      countries: '',
      opco: '',
      price: [],
      subcategory: [],
      subcategoryParent: [],
      requirement: []
    },
    isLoading: true
  }

  componentDidMount() {
    this.props.getFilterCount(this.props.match.params.component)
    if (this.state.filter.categories === '') {
      let _type = this.props.match.params.component
      this.setState({ filter: Object.assign({}, _type == 'create' ? this.props.filterCreate : _type === 'store' ? this.props.filterStore : this.props.filter), isLoading:false })
    }
    // console.log('lucky', this.props.filter)
  }

  componentWillUnmount() {
    // console.log("UNMOUNTING")
    this.props.emptyFilterCount()
  }

  renderOptions = (val) => {
    switch (val) {
      case 'Category':
        const _data = this.props.filterCount.category
        return _data.map(data => {
          return data.id !== 'all' && data.count <= 0 ? null : <div className='filter-content-item filter-content-menu-item'>
            <label className='container'>{data.name} ({data.id === 'all' ? this.props.filterCount.total : data.count})
              <input type='checkbox' checked={this.state.filter.categories === '' ? (this.state.filter.categories.includes(data.id) || this.state.filter.categories.includes('all')) : (this.state.filter.categories.includes(data.id) || this.state.filter.categories.includes('all')) ? 'checked' : false} onClick={() => this.handleCategoryCheckboxClick(data)} />
              <span className='checkmark' />
            </label>
          </div>
        })
      case 'Country': {
        const _data = this.props.filterCount.country
        return _data.map(data => {
          return data.count <= 0 ? null : <div className='filter-content-item filter-content-menu-item'>
            <label className='container'>{data.name} ({data.count})
              <input
                type='checkbox'
                checked={this.state.filter.countries !== '' && (this.state.filter.countries.includes(data.id)) ? 'checked' : false}
                onClick={() => this.handleCountryCheckboxClick(data)}
              />
              <span className='checkmark' />
            </label>
          </div>
        })
      }
      case 'Opco': {
        const _data = this.props.filterCount.opco
        return _data.map(data => {
          return <div className='filter-content-item filter-content-menu-item'>
            <label className='container'>{data.name}
              <input type='checkbox' checked={this.state.filter.opco === '' ? (this.state.filter.opco.includes(data.id) || this.state.filter.opco.includes('all')) : (this.state.filter.opco.includes(data.id) || this.state.filter.opco.includes('all')) ? 'checked' : null} onClick={() => this.handleOpcoCheckboxClick(data)} />
              <span className='checkmark' />
            </label>
          </div>
        })
      }

      case 'Price': {
        const _data = this.props.filterCount.price
        return (
          <div>
            <Range
              style={{ marginLeft: 30, marginRight: 30, marginTop: 100 }}
              min={_data ? _data.min : null}
              max={_data ? _data.max : null}
              defaultValue={this.state.filter.price && this.state.filter.price.length > 0 ? [this.state.filter.price[0], this.state.filter.price[1]] : _data ? [_data.min, _data.max] : [0, 0]}
              onChange={(e) => this.handleChange(e)}
            />
            <p className='filter-min-label'>{this.state.filter.price.length > 0 ? this.state.filter.price[0] : _data ? _data.min : 0} AED</p>
            <p className='filter-max-label'>{this.state.filter.price.length > 0 ? this.state.filter.price[1] : _data ? _data.max : 0} AED</p>
            <div />
          </div>
        )
      }

      case 'Requirement': {
        const _data = this.props.filterCount.requirement
        return (
          <div>
            <Slider
              style={{ marginLeft: 30, marginRight: 30, marginTop: 100 }}
              min={_data ? _data.min : null}
              max={_data ? _data.max : null}
              defaultValue={this.state.filter.requirement && this.state.filter.requirement.length > 0 ? this.state.filter.requirement[0] : _data ? _data.min : 0}
              onChange={(e) => this.handleRequirementChange(e)}
            />
            <p className='filter-min-label'>{this.state.filter.requirement.length > 0 ? this.state.filter.requirement[0] : _data ? _data.min : 0} Person(s)</p>
            <div />
          </div>
        )
      }

      case 'SubCategory': {
        const _data = this.props.filterCount.subcategory
        return _data.map(data => {
          // console.log(this.state.filter.categories, data)
          return (data.id !== 'all' && data.count <= 0) || (!this.state.filter.categories.includes(data.category)) ? null : <div className='filter-content-item filter-content-menu-item'>
            <label className='container'>{data.name} ({data.id === 'all' ? this.props.filterCount.total : data.count})
              <input type='checkbox' checked={this.state.filter.subcategory === '' ? (this.state.filter.subcategory.includes(data.id) || this.state.filter.subcategory.includes('all')) : (this.state.filter.subcategory.includes(data.id) || this.state.filter.subcategory.includes('all')) ? 'checked' : false} onClick={() => this.handleSubCategoryCheckboxClick(data)} />
              <span className='checkmark' />
            </label>
          </div>
        })
      }

      case 'Sort': {
        const _data = this.props.filterCount.sort
        return _data.map(data => {
          return !data ? null : <div className='filter-content-item filter-content-menu-item'>
            <label className='container'>{data.split("-")[0]} {data.split("-")[1] == 'desc' ? data.split("-")[0] ==='Name'? 'Z to A' :'High to Low':data.split("-")[0] ==='Name'? 'A to Z' : 'Low to High'}
              <input
                type='checkbox'
                checked={this.state.filter.sort == data ? 'checked' : false}
                onClick={() => {let sort = this.state.filter; sort['sort'] = data;this.setState({filter : sort})}}
              />
              <span className='checkmark' />
            </label>
          </div>
        })
      }
    }
  }

  handleChange = (data) => {
    // console.log(data)
    const _filter = this.state.filter
    _filter.price = data
    this.setState({ filter: _filter })
  }

  handleRequirementChange = (data) => {
    // console.log(data)
    const _filter = this.state.filter
    _filter.requirement = [data]
    this.setState({ filter: _filter })
  }

  handleCategoryCheckboxClick = (data) => {
    const _filter = this.state.filter
    let array
    if (data.id !== 'all') {
      if (this.state.filter.categories !== '' && this.state.filter.categories !== 'all') { array = this.state.filter.categories.split(',') } else { array = [] }
      var index = array.indexOf(data.id)
      if (index > -1) {
        array.splice(index, 1)
        if (_filter.subcategory && !Array.isArray(_filter.subcategory)) {
          let _subCategory = _filter.subcategory.split(',')
          _subCategory.map(dataValue => {
            if (_filter.subcategoryParent[dataValue] == data.id) {
              const _subIndex = _subCategory.indexOf(dataValue)
              _subCategory = [..._subCategory.slice(0, _subIndex), ..._subCategory.slice(_subIndex + 1, _subCategory.length)]
            }
          })
          _filter.subcategory = _subCategory.toString()
        }
      } else {
        array.push(data.id)
      }
      _filter.categories = this.getArrayString(array)
    } else {
      if (this.state.filter.categories.includes('all')) { _filter.categories = '' } else { _filter.categories = 'all' }
    }
    this.setState({ filter: _filter })
  }

  handleSubCategoryCheckboxClick = (data) => {
    const _filter = this.state.filter
    let array
    if (data.id !== 'all') {
      if (!Array.isArray(this.state.filter.subcategory) && this.state.filter.subcategory !== '' && this.state.filter.subcategory !== 'all') { array = this.state.filter.subcategory.split(',') } else { array = [] }
      var index = array.indexOf(data.id)
      if (index > -1) {
        array.splice(index, 1)
      } else {
        array.push(data.id)
      }
      _filter.subcategory = this.getArrayString(array)
      _filter.subcategoryParent[data.id] = data.category
    } else {
      if (this.state.filter.subcategory.includes('all')) { _filter.subcategory = '' } else { _filter.subcategory = 'all' }
    }
    this.setState({ filter: _filter })
  }

  handleCountryCheckboxClick = (data) => {
    // console.log('click', data, this.state.filter)
    const _filter = this.state.filter
    let array
    if (data.id !== 'all') {
      if (this.state.filter.countries !== '') { array = this.state.filter.countries.split(',') } else { array = [] }
      const index = array.indexOf(data.id)
      if (index > -1) {
        array.splice(index, 1)
      } else {
        array.push(data.id)
      }

      _filter.countries = this.getArrayString(array)
    } else {
      if (this.state.filter.countries.includes('all')) { _filter.countries = '' } else { _filter.countries = 'all' }
    }

    this.setState({ filter: _filter })
  }

  handleOpcoCheckboxClick = (data) => {
    const _filter = this.state.filter
    let array
    if (data.id !== 'all') {
      if (this.state.filter.opco !== '') { array = this.state.filter.opco.split(',') } else { array = [] }
      var index = array.indexOf(data.id)
      if (index > -1) {
        array.splice(index, 1)
      } else {
        array.push(data.id)
      }

      _filter.opco = this.getArrayString(array)
    } else {
      if (this.state.filter.opco.includes('all')) { _filter.opco = '' } else { _filter.opco = 'all' }
    }

    this.setState({ filter: _filter })
  }

  getArrayString = (array) => {
    return array.join()
  };

  applyFilter = () => {
    // console.log('filter-state', this.state.filter)
    this.props.setListEmpty('HAPPENING')
    this.props.setFilterData(this.state.filter, this.props.match.params.component)
    if (this.props.match.params.component == 'create') {
      this.props.getExperienceList(0, 20, this.state.filter.categories === 'all' ? '' : this.state.filter.categories, this.state.filter.countries, this.state.filter.opco, this.state.filter.price, this.state.filter.requirement, this.state.filter.subcategory)
    } else if(this.props.match.params.component == 'store') {

      let { countries, sort } = this.state.filter
      let sortby = sort ? sort.split("-")[0] : null
      let orderby = sort ? sort.split("-")[1] : null
      this.props.getStoreList(0, 10, countries, sortby, orderby)
    } else { this.props.getHappeningList(0, 20, this.state.filter.categories === 'all' ? '' : this.state.filter.categories, this.state.filter.countries, this.state.filter.opco, this.state.filter.price) }
    this.props.history.goBack()
  }

  clearAll = () => {
    const _filter = {
      categories: '',
      countries: '',
      opco: '',
      price: [],
      subcategory: [],
      requirement: [],
      subcategoryParent: []
    }

    this.setState({ filter: _filter })
  }

  _getFilterCount = () => {
    let _count = 0
    this.props.filterCount.category.map(data => {
      if (data.count > 0) { _count++ }
    })
    return _count
  }

  render() {
    // console.log("FILTER SCREEN", this.props, this.state)
    let { navSelected, isLoading } = this.state
    let { filterCount } = this.props
    let { component } = this.props.match.params

    // console.log(navSelected, component)
    if (navSelected === 'Category' && component == 'store') {
      navSelected = 'Country'
      this.setState({ navSelected: 'Country' })
    }

    debugger
    if(isLoading || !filterCount)
      return null

    // console.log(Object.keys(filterCount))

    return (
      <div>
        <NavBar
          mode='light'
          leftContent={
            <Icon key='1' type='left' className='navbar-back-icon' onClick={() => this.props.history.goBack()} />
          }
          className='navbar-container'
          rightContent={
            <div onClick={this.clearAll}>
              {/* <Icon key='0' type='plus' style={{ width: '13px', height: '13px', color: '#9F803E' }} /> */}
              <span className='navbar-right-button'> Clear All</span>
            </div>
          }
        >Apply Filter
        </NavBar>
        {!this.props.filterCount.country ? <div className='loader'><img src='/icons/MAF.gif' /></div> : <div className='filter-main-container'>
          <div className='filter-side-nav'>
            <div className={navSelected === 'Country' ? 'filter-content-item filter-side-nav-content-item side-nav-active' : 'filter-content-item filter-side-nav-content-item'} onClick={() => this.setState({ navSelected: 'Country' })}>
              <p>Country</p>
              {this.state.filter.countries !== '' ? <div className='filter-item-count filter-item-count-country'>{this.state.filter.countries.split(',').length}</div> : null}
            </div>
            {this.props.filterCount.category ? <div className={navSelected === 'Category' ? 'filter-content-item filter-side-nav-content-item side-nav-active' : 'filter-content-item filter-side-nav-content-item'} onClick={() => this.setState({ navSelected: 'Category' })}>
              <p>Interest</p>
              {this.state.filter.categories !== '' ? <div className='filter-item-count filter-item-count-category'>{this.state.filter.categories === 'all' ? this._getFilterCount() : this.state.filter.categories ? this.state.filter.categories.split(',').length : null}</div> : null}
            </div> : null}
            {this.props.filterCount.subcategory && this.state.filter.categories !== '' ? <div className={this.state.navSelected === 'SubCategory' ? 'filter-content-item filter-side-nav-content-item side-nav-active' : 'filter-content-item filter-side-nav-content-item'} onClick={() => this.setState({ navSelected: 'SubCategory' })}>
               <p>Sub-Category</p>
			     {!Array.isArray(this.state.filter.subcategory) && this.state.filter.subcategory !== '' && typeof (this.state.filter.subcategory) === 'string' ? <div className='filter-item-count filter-item-count-subcategory'>{this.state.filter.subcategory === 'all' ? this._getFilterCount() : this.state.filter.subcategory.split(',').length}</div> : null}
           
            </div> : null}
            {this.props.filterCount.opco ? <div className={navSelected === 'Opco' ? 'filter-content-item filter-side-nav-content-item side-nav-active' : 'filter-content-item filter-side-nav-content-item'} onClick={() => this.setState({ navSelected: 'Opco' })}>
              <p>OpCo</p>
              {this.state.filter.opco !== '' ? <div className='filter-item-count filter-item-count-opco'>{this.state.filter.opco ? this.state.filter.opco.split(',').length : null}</div> : null}
            </div> : null}
			 {this.props.filterCount.price ? <div className={navSelected === 'Price' ? 'filter-content-item filter-side-nav-content-item side-nav-active' : 'filter-content-item filter-side-nav-content-item'} onClick={() => this.setState({ navSelected: 'Price' })}>
             
             <p>Price</p>
			  {this.state.filter.price && this.state.filter.price.length > 0 ? <div className='filter-item-count filter-item-count-opco'>1</div> : null}
            
            </div> : null}
             {this.props.filterCount.requirement ? <div className={navSelected === 'Requirement' ? 'filter-content-item filter-side-nav-content-item side-nav-active' : 'filter-content-item filter-side-nav-content-item'} onClick={() => this.setState({ navSelected: 'Requirement' })}>
            
              <p>Requirement</p>
			  
			   {this.state.filter.requirement && this.state.filter.requirement.length > 0 ? <div className='filter-item-count filter-item-count-requirement'>1</div> : null}
            </div> : null}
            {this.props.filterCount.sort ? <div className={navSelected === 'Sort' ? 'filter-content-item filter-side-nav-content-item side-nav-active' : 'filter-content-item filter-side-nav-content-item'} onClick={() => this.setState({ navSelected: 'Sort' })}>
              
              <p>Sort</p>
			  {this.state.filter.sort && this.state.filter.sort.length > 0 ? <div className='filter-item-count filter-item-count-requirement'>1</div> : null}
            </div> : null}
          </div>
          <div className='filter-content-menu'>
            {this.renderOptions(navSelected)}
          </div>
          <div className='filter-footer'>
            <div className='filter-footer-button' onClick={this.applyFilter}>
              <p>
                Apply
              </p>
            </div>
          </div>
        </div>}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  categories: state.experienceReducer.categories,
  filterCount: state.experienceReducer.filterCount,
  filter: state.experienceReducer.filter,
  filterCreate: state.experienceReducer.filterCreate,
  filterStore: state.experienceReducer.filterStore
})

const mapDispatchToProps = dispatch => ({
  getCategories: () => dispatch(getCategories()),
  setListEmpty: (type) => dispatch(setListEmpty(type)),
  getFilterCount: (type) => dispatch(getFilterCount(type)),
  setFilterData: (data, type) => dispatch(setFilterData(data, type)),
  getHappeningList: (page, limit, category, country, opco, price) => dispatch(getHappeningList(page, limit, category, country, opco, price)),
  getExperienceList: (page, limit, category, country, opco, price, requirement, subcategory) => dispatch(getExperienceList(page, limit, category, country, opco, price, requirement, subcategory)),
  getStoreList: (page, limit, country, sortby, orderby) => dispatch(getStoreList(page, limit, country, sortby, orderby)),
  emptyFilterCount: () => dispatch(emptyFilterCount()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterScreen)
