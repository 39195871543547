export const listStore = (data, type) => dispatch => {
  // console.log("from action", data)

  dispatch({
    type: 'LIST_STORES',
    payload: data,
    updateType: type
  })
}

export const updateDetailedStore = (data) => dispatch => {
  dispatch({
    type: 'LIST_DETIALS_STORE',
    payload: data
  })
}

export const listStoreHistoryAction = (data, type) => dispatch => {
  // console.log('data', data)
  dispatch({
      type: 'LIST_STORE_HISTORY',
      payload: data,
      updateType: type
  })
}