export default (state = {
    result: {
        items: []
    },
    tribeResult: {
        items: []
    },
    stepsResult: {
        items: []
    }
}, action) => {
    switch (action.type) {
        case 'LIST_LEADERBOARD':
            // console.log("from leader reducer", action)
            return Object.assign({}, state, {
                result: {
                    items: action.updateType === 'FORCE_UPDATE' ? action.payload.data : [...state.result.items, ...action.payload.data],
                    totalResult: action.payload.total
                }
            })
        case 'LIST_STEP_LEADERBOARD':
            // console.log("from step reducer", action)
            return Object.assign({}, state, {
                stepsResult: {
                    items: action.updateType === 'FORCE_UPDATE' ? action.payload.data : [...state.stepsResult.items, ...action.payload.data],
                    totalResult: action.payload.total
                }
            })
        case 'LIST_LEADERBOARD_TRIBE':
            // console.log("from leader reducer", action)
            return Object.assign({}, state, {
                tribeResult: {
                    items: action.updateType === 'FORCE_UPDATE' ? action.payload.data : [...state.tribeResult.items, ...action.payload.data],
                    totalResult: action.payload.total
                }
            })
        default:
            return state
    }
}
