const DEEPLINK_PROD = {
  APPLICATION_BACK: "https://mafempapp/screen/home​",
  EMP_ID: "https://mafempapp://screen/profile?id=",
  APPLE_HEALTH: "https://mafempapp://screen/apple-health",
  REVOKE_APPLE_HEALTH: "https://mafempapp://screen/revoke-apple-health",
};

const DEEPLINK_STAGING = {
  APPLICATION_BACK: "https://mafempstaging/screen/home​",
  EMP_ID: "https://mafempstaging://screen/profile?id=",
  APPLE_HEALTH: "https://mafempstaging://screen/apple-health",
  REVOKE_APPLE_HEALTH: "https://mafempstaging://screen/revoke-apple-health",
};

// const DEEPLINK = DEEPLINK_STAGING;
const DEEPLINK = DEEPLINK_PROD;

export default DEEPLINK;
