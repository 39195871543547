import { Grid, ListView, WhiteSpace, WingBlank } from "antd-mobile";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getStoreList } from "../../apiManager/services/storeServices";
import { Loader } from "../../components/common/Loader/loader";
import { NavigationBar } from "../../components/common/NavBar/navBar";
import ProfileCard from "../Home/components/ProfileCard/ProfileCard";
import { StoreListCard } from "./components/StoreListCard/StoreListCard";
import "./StoreScreen.scss";
import SkeletonStoreCard from "../../components/common/SkeletonLayouts/SkeletonStoreCard";

let data = {
  result: true,
  total: 10,
  data: [
    {
      id: "5fba2081f1e3c45614fb819c",
      name: "Demo 1",
      pointValue: 100,
      stock: 1,
      country: "United Arab Emirates",
      image:
        "https://s3.us-east-2.amazonaws.com/workfam-upload/media/company-5d1319c8828b1806d4bc4a62/a6b314ab-1573047824538-486.jpg",
    },
    {
      id: "5fba20eaf1e3c45614fb81ae",
      name: "Demo 10",
      pointValue: 10,
      stock: 0,
      country: "United Arab Emirates",
      image:
        "https://s3.us-east-2.amazonaws.com/workfam-upload/media/company-5d1319c8828b1806d4bc4a62/8b0aa4ad-1573118839141-486.jpg",
    },
    {
      id: "5fba20bdf1e3c45614fb819f",
      name: "Demo 3",
      pointValue: 10,
      stock: 10,
      country: "United Arab Emirates",
      image:
        "https://s3.us-east-2.amazonaws.com/workfam-upload/media/company-5d1319c8828b1806d4bc4a62/e0f7ffd8-1573118838750-486.jpg",
    },
    {
      id: "5fba20c5f1e3c45614fb81a2",
      name: "Demo 4",
      pointValue: 10,
      stock: 10,
      country: "United Arab Emirates",
      image:
        "https://s3.us-east-2.amazonaws.com/workfam-upload/media/company-5d1319c8828b1806d4bc4a62/8b0aa4ad-1573118839141-486.jpg",
    },
    {
      id: "5fba20cbf1e3c45614fb81a4",
      name: "Demo 5",
      pointValue: 10,
      stock: 10,
      country: "United Arab Emirates",
      image:
        "https://s3.us-east-2.amazonaws.com/workfam-upload/media/company-5d1319c8828b1806d4bc4a62/8b0aa4ad-1573118839141-486.jpg",
    },
    {
      id: "5fba20d0f1e3c45614fb81a6",
      name: "Demo 6",
      pointValue: 10,
      stock: 10,
      country: "United Arab Emirates",
      image:
        "https://s3.us-east-2.amazonaws.com/workfam-upload/media/company-5d1319c8828b1806d4bc4a62/8b0aa4ad-1573118839141-486.jpg",
    },
    {
      id: "5fba20d5f1e3c45614fb81a8",
      name: "Demo 7",
      pointValue: 10,
      stock: 10,
      country: "United Arab Emirates",
      image:
        "https://s3.us-east-2.amazonaws.com/workfam-upload/media/company-5d1319c8828b1806d4bc4a62/8b0aa4ad-1573118839141-486.jpg",
    },
    {
      id: "5fba20dcf1e3c45614fb81aa",
      name: "Demo 8",
      pointValue: 10,
      stock: 10,
      country: "United Arab Emirates",
      image:
        "https://s3.us-east-2.amazonaws.com/workfam-upload/media/company-5d1319c8828b1806d4bc4a62/8b0aa4ad-1573118839141-486.jpg",
    },
    {
      id: "5fba20e2f1e3c45614fb81ac",
      name: "Demo 9",
      pointValue: 10,
      stock: 10,
      country: "United Arab Emirates",
      image:
        "https://s3.us-east-2.amazonaws.com/workfam-upload/media/company-5d1319c8828b1806d4bc4a62/8b0aa4ad-1573118839141-486.jpg",
    },
    {
      id: "5fd86bcd785bea35e4f1df4a",
      name: "Ski Dubai Day Pass",
      pointValue: 150,
      stock: 7,
      country: "United Arab Emirates",
      image:
        "https://s3.us-east-2.amazonaws.com/workfam-upload/media/company-5d1319c8828b1806d4bc4a62/3db4736d-1608018554689-486.png",
    },
  ],
};

const LIMIT = 6;

class StoreScreen extends Component {
  componentDidMount() {
    if (!this.props.result.totalResult) {
      this.getStoreData(0, LIMIT);
    }
  }

  getStoreData(page, limit) {
    let { countries, sort } = this.props.filter;
    let sortby = sort ? sort.split("-")[0] : null;
    let orderby = sort ? sort.split("-")[1] : null;

    this.props.getStoreList(page, limit, countries, sortby, orderby);
  }

  constructor(props) {
    super(props);
    this.storeTop = React.createRef() 
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });
    this.state = {
      dataSource,
    };
  }

  static getDerivedStateFromProps(props, state) {
    // console.log("date while update", props, state);
    if (props.result && props.result.items) {
      return {
        dataSource: state.dataSource.cloneWithRows(props.result.items),
      };
    } else {
      return null;
    }
  }

  row = (rowData, sectionID, rowID) => {
    return (
      <div className="store-list-item" key={rowID}>
        <StoreListCard data={rowData} history={this.props.history} />
      </div>
    );
  };

  onEndReached = () => {
    // console.log("this.onEndReached");
    let { result } = this.props;
    if (
      result &&
      result.totalResult &&
      result.totalResult > result.items.length
    ) {
      const _page = Math.floor(result.items.length / LIMIT);
      this.setState({ initialLoad: true });
      this.getStoreData(_page, LIMIT);
      // console.log("page", _page);
      const _loadingCount =
        (_page + 1) * LIMIT > result.totalResult
          ? result.totalResult % LIMIT
          : LIMIT;
      this.setState({
        loadingCount: _loadingCount,
      });
    }
  };

  handleFilterClick = () => this.props.history.push("/store/filter");
  scrollToTop = () =>  window.scroll({
    top: this.storeTop.current.offsetTop,
    left: 0,
    behavior: 'smooth'
  });
  render() {
    let { result } = this.props;

    // console.log(this.props.result);
    return (
      <div className="store-main-container" >
        <NavigationBar history={this.props.history} />
        <ProfileCard isExpanded={false} />
        <div className="home-heading-container slide-top" ref={this.storeTop}>
          <span className="home-heading-container-title margin-s-20">
            Rewards
          </span>
          {this.props.result.totalResult > 0 ? (
            <div className="home-all-event-coount home-list-all-event-coount store-count">
              {this.props.result.totalResult}
            </div>
          ) : null}
          <div
            className="home-heading-container-filter margin-s-20 margin-t-14"
            onClick={this.handleFilterClick}
          >
            <span>Filter</span>
            <img src="/icons/filter.png" width="15px" height="11px" />
            {(this.props.filter.countries !== ""
              ? this.props.filter.countries.split(",").length
              : 0) +
              (this.props.filter.sort ? 1 : 0) >
            0 ? (
              <div className="home-filter-count">
                {(this.props.filter.countries !== ""
                  ? this.props.filter.countries.split(",").length
                  : 0) + (this.props.filter.sort ? 1 : 0)}
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <ListView
            dataSource={this.state.dataSource}
            renderRow={this.row}
            className="am-list"
            pageSize={4}
            useBodyScroll
            onScroll={() => {
              console.log("scroll");
            }}
            scrollRenderAheadDistance={500}
            onEndReached={this.onEndReached}
            onEndReachedThreshold={10}
          />
        </div>
        { result &&
          result.items &&
          result.items.length !== result.totalResult ||
          !result.items ? (
          <div className="list-view-section-body">
            <div className="store-list-item">
              <StoreListCard data={{}} history={this.props.history} />
            </div>
            <div className="store-list-item">
              <StoreListCard data={{}} history={this.props.history} />
            </div>
            <div className="store-list-item">
              <StoreListCard data={{}} history={this.props.history} />
            </div>
            <div className="store-list-item">
              <StoreListCard data={{}} history={this.props.history} />
            </div>
          </div>
        ):result &&
        result.totalResult>=5 ? (<div className="text-center">
          <p>That's all folks!</p>
           <a className="backToTop" onClick={() => this.scrollToTop()}>Scroll to top</a>
          </div>):null }
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  result: state.storeReducer.result,
  filter: state.experienceReducer.filterStore,
});

const mapDispatchToProps = (dispatch) => ({
  getStoreList: (page, limit, country, sortby, orderby) =>
    dispatch(getStoreList(page, limit, country, sortby, orderby)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreScreen);
