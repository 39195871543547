import React from "react";
import pluralize from "pluralize";

import Image from "../../../../components/common/Image/image";
import { numberWithCommas } from "../../../../components/utils/numbers";

import StepIcon from "../../../../assets/icons/steps-icon.png";
import "./styles.scss";

function UserStepsCard({ data }) {
    let { name, image, rank, totalSteps, opcoName, departmentName } = data;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}
            className="leader-card-main-container"
            key={name + new Date().getTime()}
        >
            <div className="leader-card-index">
                <p>{rank}</p>
            </div>
            <div>
                <Image
                    src={image || "/icons/profile.png"}
                    className="leader-card-img"
                    fallbackurl="/icons/profile.png"
                />
            </div>

            <div className="wallet-sub-container">
                <p className="wallet-card-title">{name}</p>
                <p className="wallet-card-description">{departmentName}</p>
                <p className="wallet-card-status">{opcoName}</p>
            </div>

            <div className="wallet-sub-container-step">
                <p className="step-challenge-points-tag">
                    <img alt="footsteps" src={StepIcon} />
                    {numberWithCommas(totalSteps)}
                    {/* {` `}
                    {
                        pluralize("Step", totalSteps)
                    } */}
                </p>
            </div>
        </div>
    );
}

export default UserStepsCard
