import { ListView } from "antd-mobile";
import React, { Component } from "react";
import { connect } from "react-redux";
import { listStepLeaderBoard } from "../../../../apiManager/services/leaderBoardServices";
import LeaderCardSkeleton from "../../components/LeaderCardSkeleton/leaderCardSkeleton";

import "./styles.scss";
import UserStepsCard from "./UserStepsCard";

const LIMIT = 10;

class StepChallengeTab extends Component {
  state = {
    initialLoad: false,
  };

  componentDidMount() {
    this.props.listStepLeaderBoard(0, LIMIT);
  }

  constructor(props) {
    super(props);
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });
    this.state = {
      dataSource,
    };
  }

  static getDerivedStateFromProps(props, state) {
    // console.log("date while update", props, state);
    if (props.result && props.result.items) {
      return {
        dataSource: state.dataSource.cloneWithRows(props.result.items),
      };
    } else {
      return null;
    }
  }

  row = (rowData, rowID) => {
    return (
      <UserStepsCard
        data={rowData}
        key={rowID}
      />
    );
  };

  onEndReached = () => {
    let { result } = this.props;
    // console.log("page", result, result.totalResult, result.items.length);
    if (
      result &&
      result.totalResult &&
      result.totalResult > result.items.length
    ) {
      const _page = Math.floor(result.items.length / LIMIT);
      this.setState({ initialLoad: true });
      this.props.listStepLeaderBoard(_page, LIMIT);
      const _loadingCount =
        (_page + 1) * LIMIT > result.totalResult
          ? result.totalResult % LIMIT
          : LIMIT;
      this.setState({
        loadingCount: _loadingCount,
      });
    }
  };

  render() {
    let { result } = this.props;

    return (
      <div>
        <ListView
          dataSource={this.state.dataSource}
          renderRow={this.row}
          className="am-list"
          pageSize={4}
          useBodyScroll
          onScroll={() => {
            console.log("scroll");
          }}
          scrollRenderAheadDistance={500}
          onEndReached={this.onEndReached}
          onEndReachedThreshold={5}
        />
        <LeaderCardSkeleton
          visible={
            result && result.items && result.items.length !== result.totalResult
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  result: state.leaderBoardReducer.stepsResult,
});

const mapDispatchToProps = (dispatch) => ({
  listStepLeaderBoard: (page, limit) => dispatch(listStepLeaderBoard(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepChallengeTab);
