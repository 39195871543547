export default class Token {
  static setToken = (userToken) => {
    try {
      localStorage.setItem("app-key", userToken);
    } catch {
      console.log("unable to write to local storage");
    }
    this.token = userToken;
  };

  static getToken = () => {
    return this.token || localStorage.getItem("app-key");
  };
}
