import { Carousel, Flex, Icon, Modal } from 'antd-mobile'
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getStoreDetails, orderStore } from '../../apiManager/services/storeServices';
import { NavigationBar } from '../../components/common/NavBar/navBar';
import { Counter } from './components/Counter/counter';
import { AbsoluteHeader } from './components/NavBar/navBar';
import { StoreOrder } from './components/StoreOrder/storeOrder';
import { StoreOrderSuccess } from './components/StoreOrderSuccess/storeOrderSuccess';
import './storeDetails.scss'

class StoreDetails extends Component {

  state = {
    isBookingModelVisible: false,
    isOrderSuccess: false,
    title: '',
    message: '',
    collection: ''
  }

  componentDidMount() {
    // simulate img loading
    this.props.getStoreDetails(this.props.match.params.id)
  }

  orderItem = (id, val) => {
    orderStore(id, val).then(res => {
      if (res) {
        let { title, message, collection, error, balance } = res
        this.setState({ title, message, collection, error, balance })
        this.setState({ isOrderSuccess: true })
      }
    })
  }

  render() {
    let { id, images, name, pointValue, stock, details, collection, country } = this.props.details

    let { credit } = this.props.profile
    
    let { isOrderSuccess, title, message, error, balance } = this.state
    return (
      this.props.details.id !== this.props.match.params.id ? <div className='loader'><img src='/icons/MAF.gif' /></div> : <>
        <NavigationBar history={this.props.history}/>
        <div className="wallet-main-container store-details">
          <div>
            <Carousel
              autoplay={false}
              infinite
              beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
              afterChange={index => console.log('slide to', index)}
            >
              {images.map(val => (
                <img
                  src={val}
                  alt=""
                  style={{ width: '100%', height: 300, verticalAlign: 'top' }}
                  onLoad={() => {
                    // fire window resize event to change height
                    window.dispatchEvent(new Event('resize'));
                    this.setState({ imgHeight: 'auto' });
                  }}
                />
              ))}
            </Carousel>
          </div>
          <div className="title-container">
            <Flex justify="between">
              <Flex.Item>
                <p className="title">{name}</p>
                <p className="credits">{pointValue} Credits</p>
              </Flex.Item>
              <Flex.Item>
                <div className="maf-tag">
                  <span>{stock} Left</span>
                </div>

              </Flex.Item>
            </Flex>
            {country?<div className="availability">
              <img src={require('../../assets/icons/warning.svg')} alt="warning" />
              <span>Available in {country.name} only</span>
            </div>:null}
          </div>
          <div className="description-container">
            <div className="sub-container">
              <p className="description-title">Description</p>
              <p className="description-detials">
                {details}
              </p>
            </div>
            <div className="sub-container">
              <p className="description-title">Collection Process</p>
              <p className="description-detials">
                {collection}
              </p>
            </div>
          </div>

          <div className='filter-footer'>
            <div className={stock && stock > 0 ? 'filter-footer-button' : 'filter-footer-button  bg-gray'} onClick={() => stock && stock > 0? this.setState({ isBookingModelVisible: true }):null}>
              <p>
                Redeem
              </p>
            </div>
          </div>

          <Modal
            popup
            visible={this.state.isBookingModelVisible}
            transparent
            animationType='slide-up'
            wrapProps={{ onTouchStart: this.onWrapTouchStart }}
            onClose={() => this.setState({ isCancelModelVisible: false })}
          >
            <div className="model-container">
              <div className="close-button" onClick={() => this.setState({ isBookingModelVisible: false}, () => this.setState({ isOrderSuccess: false }))}>
                <Icon type="cross" size="xs" color="#9F803D" />
              </div>

              {!isOrderSuccess ?
                <StoreOrder
                  onConfirm={(val) => this.orderItem(id, val)}
                  title={title}
                  image={images[0]}
                  name={name}
                  credits={credit}
                  collection={collection}
                  pointValue={pointValue} /> :
                <StoreOrderSuccess
                  title={title}
                  message={message}
                  collection={this.state.collection}
                  history={this.props.history}
                  error={error}
                  balance={balance}
                  onClose={() => this.setState({ isBookingModelVisible: false}, () => this.setState({ isOrderSuccess: false }))}
                />}
            </div>
          </Modal>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  details: state.storeReducer.details,
  profile: state.profileReducer.profile
})

const mapDispatchToProps = dispatch => ({
  getStoreDetails: (page, limit) => dispatch(getStoreDetails(page, limit)),
  orderStore: (id, count) => dispatch(orderStore(id, count))
})

export default connect(mapStateToProps, mapDispatchToProps)(StoreDetails)