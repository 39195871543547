import { ListView } from "antd-mobile";
import React, { Component } from "react";
import { connect } from "react-redux";
import { listStoreHistory } from "../../apiManager/services/storeServices";
import { Loader } from "../../components/common/Loader/loader";
import { NavigationBar } from "../../components/common/NavBar/navBar";
import ProfileCard from "../Home/components/ProfileCard/ProfileCard";
import { WalletListCard } from "./components/StoreListCard/walletListCard";
import "./walletScreen.scss";
import SkeletonHistory from "./components/SkeletonHistory/skeletonHistory";
const LIMIT = 10;
class WalletScreen extends Component {
  componentDidMount() {
    this.props.listStoreHistory(0, LIMIT);
  }

  constructor(props) {
    super(props);
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });
    this.state = {
      dataSource,
    };
  }

  static getDerivedStateFromProps(props, state) {
    // console.log("date while update", props, state);
    if (props.result && props.result.items) {
      return {
        dataSource: state.dataSource.cloneWithRows(props.result.items),
      };
    } else {
      return null;
    }
  }

  row = (rowData, sectionID, rowID) => {
    return <WalletListCard data={rowData} key={rowID} />;
  };

  onEndReached = () => {
    // console.log("this.onEndReached");
    let { result } = this.props;
    if (
      result &&
      result.totalResult &&
      result.totalResult > result.items.length
    ) {
      const _page = Math.floor(result.items.length / LIMIT);
      this.setState({ initialLoad: true });
      this.props.listStoreHistory(_page, LIMIT);
      // console.log("page", _page);
      const _loadingCount =
        (_page + 1) * LIMIT > result.totalResult
          ? result.totalResult % LIMIT
          : LIMIT;
      this.setState({
        loadingCount: _loadingCount,
      });
    }
  };

  render() {
    let { result } = this.props;

    // console.log(
    //   " result && result.items && result.items.length !== result.totalResult",
    //   result && result.items && result.items.length !== result.totalResult
    // );

    return (
      <div className="wallet-main-container">
        <NavigationBar history={this.props.history} />
        <ProfileCard />
        <div>
          <div className={"redemption-history"}>
            <span className="home-heading-container-title">
              Redemption History
            </span>
          </div>

          <ListView
            dataSource={this.state.dataSource}
            renderRow={this.row}
            className="am-list"
            pageSize={4}
            useBodyScroll
            onScroll={() => {
              console.log("scroll");
            }}
            scrollRenderAheadDistance={500}
            onEndReached={this.onEndReached}
            onEndReachedThreshold={10}
          />
          <SkeletonHistory
            visible={
              result &&
              result.items &&
              result.items.length !== result.totalResult
            }
          />
        </div>

        {/* <Loader 
        initialLoad={this.state.initialLoad } 
        loadingCount={this.state.loadingCount} 
        visible={result && result.items && result.items.length !== result.totalResult}/> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  result: state.storeReducer.historyResult,
});

const mapDispatchToProps = (dispatch) => ({
  listStoreHistory: (page, limit) => dispatch(listStoreHistory(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletScreen);
