const URL_CONFIG = {
  dev: "https://dev.workfam.com/maf/",
  uat: "http://51.144.90.63/maf/",
  prod: "https://application.workfam.com/maf/",
};

const BASE_API_URL = URL_CONFIG.prod;
// const BASE_API_URL = URL_CONFIG.dev;

export default BASE_API_URL;
