import React, { Component } from 'react'
import './image.scss'

export default class Image extends React.Component {
    constructor (props) {
      super(props)
      
      this.state = {
        error: false
      }
    }
    
    render () {

        let { error } = this.state

        let { fallbackurl, src } = this.props

      
      return <img {...this.props} src={error? fallbackurl: src } onError={e => this.setState({ error: true })}/>
    }
  }
  