import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "antd-mobile";

import Intro from "./components/Intro";
import ConnectAccount from "./components/ConnectAccount";
import Navbar from "./components/Navbar";
import Success from "./components/Success";
import Error from "./components/Error";
import {
  digimeRevokeAccess,
  appleHealthStatus,
} from "../../apiManager/services/stepsChallengeServices";
import { validateToken } from "../../apiManager/services/validationServices";
import Token from "../../apiManager/token/tokenValue";

import DEEPLINK from "../../apiManager/endpoints/deeplink";

import { INTRO, CONNECT_ACCOUNT, SUCCESS, ERROR } from "./constants";
import "./styles.scss";

const StepChallengeSetup = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [component, setComponent] = useState(INTRO);
  const [error, setError] = useState(false);

  const success = useCallback((data) => {
    if (data.data.result) {
      Token.setToken(props.match.params.token);
    }
  }, [props.match.params.token])

  const failure = useCallback(() => {
    setError(true);
  }, [])


  const validate = useCallback((token) => {
    validateToken(token, success, failure);
  }, [success, failure])

  useEffect(() => {
    if (Object.keys(props.match.params).length > 2) {
      validate(props.match.params.token);
      appleHealthStatus().then((res) => {
        if (res.data.result) setComponent(SUCCESS);
        else setComponent(ERROR);
      });
    } else if (props.match.params.status === SUCCESS) setComponent(SUCCESS);
    else if (props.match.params.status === ERROR) setComponent(ERROR);
    else setComponent(INTRO);
    setTimeout(() => setIsLoading(false), 500);
  }, [props.match.params, validate]);

  const getComponent = {
    [INTRO]: <Intro skip={() => props.history.replace("/home")} />,
    [CONNECT_ACCOUNT]: <ConnectAccount />,
    [SUCCESS]: <Success id={parseInt(props.match.params.id)} />,
    [ERROR]: <Error id={parseInt(props.match.params.id)} />,
  };

  const getButtons = {
    [INTRO]: (
      <div className="step-footer">
        <div
          onClick={() => setComponent(CONNECT_ACCOUNT)}
          className="step-footer-button"
        >
          <p>JOIN NOW</p>
        </div>
      </div>
    ),
    [CONNECT_ACCOUNT]: <></>,
    [SUCCESS]: (
      <div className="step-footer">
        <div
          onClick={() => modalAlert()}
          className="step-footer-button-secondary"
        >
          <p>REVOKE ACCESS</p>
        </div>
        <div
          onClick={() => props.history.replace("/home")}
          className="step-footer-button"
        >
          <p>DONE</p>
        </div>
      </div>
    ),
    [ERROR]: (
      <div className="step-footer">
        <div
          onClick={() => setComponent(CONNECT_ACCOUNT)}
          className="step-footer-button"
        >
          <p>GO BACK</p>
        </div>
      </div>
    ),
  };

  const getTitle = {
    [INTRO]: (
      <Navbar
        title="Step Challenge"
        backButtonAction={() => {
          props.history.replace("/home");
        }}
      />
    ),
    [CONNECT_ACCOUNT]: (
      <Navbar
        title="Step Challenge"
        backButtonAction={() => {
          setComponent(INTRO);
        }}
      />
    ),
    [SUCCESS]: (
      <Navbar
        title="Step Challenge"
        backButtonAction={() => {
          props.history.replace("/home");
        }}
      />
    ),
    [ERROR]: (
      <Navbar
        title="Step Challenge"
        backButtonAction={() => {
          props.history.replace("/home");
        }}
      />
    ),
  };

  const modalAlert = () =>
    Modal.alert(
      "Revoke access ?",
      "Are you sure that you want to unregister from Step Challenge?",
      [
        {
          text: "Cancel",
          style: "cancel",
        },
        { text: "OK", onPress: () => revokeAccessHandler() },
      ]
    );

  const revokeAccessHandler = () => {
    if (Object.keys(props.match.params).length > 2) {
      window.location.replace(DEEPLINK.REVOKE_APPLE_HEALTH);
    } else {
      setIsLoading(true);
      digimeRevokeAccess()
        .then((res) => {
          if (res.result) props.history.replace("/home");
          else {
            setIsLoading(false);
            modalError();
          }
        })
        .catch(() => {
          setIsLoading(false);
          modalError();
        });
    }
  };

  const modalError = () =>
    Modal.alert("Something went wrong !", "Please try again.", [
      { text: "OK" },
    ]);

  if (error)
    return (
      <div>
        <h1 className="text-center">Your Token is Expired or Wrong</h1>
      </div>
    );

  return (
    <div className="main-container">
      {getTitle[component]}
      <div className="step-challenge-setup-container margin-t-70">
        {isLoading ? (
          <div className="loader">
            <img alt="maficon" src="/icons/MAF.gif" />
          </div>
        ) : (
          getComponent[component]
        )}
      </div>
      {!isLoading && getButtons[component]}
    </div>
  );
};

export default StepChallengeSetup;
