import { Card, Flex, WhiteSpace } from "antd-mobile";
import React from "react";
import Image from "../../../../components/common/Image/image";
import { numberWithCommas } from "../../../../components/utils/numbers";

import "./leaderBoardCard.scss";

export function LeaderCard({ index, isStatusVisible, data }) {
  let { name, image, badge, rank, totalPoint, opcoName, departmentName } = data;

  return (
    <div
      className="leader-card-main-container"
      key={name + new Date().getTime()}
    >
      <div className="leader-card-index">
        <p>{rank}</p>
      </div>
      <div>
        <Image
          src={image || "/icons/profile.png"}
          className="leader-card-img"
          fallbackurl="/icons/profile.png"
        />
      </div>

      <div className="wallet-sub-container">
        <p className="wallet-card-title">{name}</p>
        <p className="wallet-card-description">{departmentName}</p>
        <p className="wallet-card-status">{opcoName}</p>
      </div>

      <div className="wallet-sub-container">
        <p className="leader-card-points">{numberWithCommas(totalPoint)} points</p>
      </div>
      {isStatusVisible && badge ? (
        <div className="min-width-30">
          <img
            alt="status icon"
            className="leader-status-icon"
            src={badge.image.sm}
          />
        </div>
      ) : null}
    </div>
  );
}
