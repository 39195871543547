import { ListView } from "antd-mobile";
import React, { Component } from "react";
import { connect } from "react-redux";
import { listLeaderBoard } from "../../../../apiManager/services/leaderBoardServices";
import { Loader } from "../../../../components/common/Loader/loader";
import { LeaderCard } from "../../components/LeaderBoardCard/leaderBoardCard";
import "./leaderTab.scss";
import LeaderCardSkeleton from "../../components/LeaderCardSkeleton/leaderCardSkeleton";
let data = [
  {
    id: "5c46febd31e5fa4409cb27fe",
    name: "Gibin George",
    trube: [
      "5bd19f4c44d2e93c33fb527c",
      "5bc88ee800f20a7db90b2a08",
      "5bc88f2c00f20a7db90b2a0c",
    ],
    totalPoint: 293,
    image:
      "https://s3.us-east-2.amazonaws.com/workfam-upload/media/f8ac6ea6-1554825249859-64.jpg",
    badge: {
      _id: "5c500d594047dd82e07ab327",
      name: "Gold",
      image: {
        sm: "https://s3.us-east-2.amazonaws.com/workfam-upload/media/7ae21a5f-1550655317675-320.png",
      },
      id: "5c500d594047dd82e07ab327",
      points: {
        company: "5b8fbe59b7c904ecbc446b34",
        badge: "5c500d594047dd82e07ab327",
        pointFrom: 1000,
        pointTo: 1500,
      },
    },
  },
];

const LIMIT = 10;

class LeaderTab extends Component {
  state = {
    initialLoad: false,
  };

  componentDidMount() {
    this.props.listLeaderBoard(0, LIMIT);
  }

  constructor(props) {
    super(props);
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });
    this.state = {
      dataSource,
    };
  }

  static getDerivedStateFromProps(props, state) {
    // console.log("date while update", props, state);
    if (props.result && props.result.items) {
      return {
        dataSource: state.dataSource.cloneWithRows(props.result.items),
      };
    } else {
      return null;
    }
  }

  row = (rowData, sectionID, rowID) => {
    return (
      <LeaderCard
        data={rowData}
        key={rowID}
        index={rowID}
        isStatusVisible={true}
      />
    );
  };

  onEndReached = () => {
    // console.log("this.onEndReached");
    let { result } = this.props;
    if (
      result &&
      result.totalResult &&
      result.totalResult > result.items.length
    ) {
      const _page = Math.floor(result.items.length / LIMIT);
      this.setState({ initialLoad: true });
      this.props.listLeaderBoard(_page, LIMIT);
      // console.log("page", _page);
      const _loadingCount =
        (_page + 1) * LIMIT > result.totalResult
          ? result.totalResult % LIMIT
          : LIMIT;
      this.setState({
        loadingCount: _loadingCount,
      });
    }
  };

  render() {
    // console.log("rendering", this.props);

    let { result } = this.props;

    return (
      <div>
        <div className="decription-container m-t-10">
          <p className="description-text">
            Join or create MyWellness Experiences to start competing with other
            MAFers on the leaderboard!
          </p>
        </div>
        <ListView
          dataSource={this.state.dataSource}
          renderRow={this.row}
          className="am-list"
          pageSize={4}
          useBodyScroll
          onScroll={() => {
            console.log("scroll");
          }}
          scrollRenderAheadDistance={500}
          onEndReached={this.onEndReached}
          onEndReachedThreshold={10}
        />
        {/* <Loader
          initialLoad={this.state.initialLoad}
          loadingCount={this.state.loadingCount}
          visible={
            result && result.items && result.items.length !== result.totalResult
          }
        /> */}
        <LeaderCardSkeleton
          visible={
            result && result.items && result.items.length !== result.totalResult
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  result: state.leaderBoardReducer.result,
});

const mapDispatchToProps = (dispatch) => ({
  listLeaderBoard: (page, limit) => dispatch(listLeaderBoard(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaderTab);
