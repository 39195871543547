import { httpGETRequest, httpPOSTRequest } from "../httpRequestHandler";
import API from "../endpoints";

import {
  listExperiences,
  serviceActionError,
  listallexperience,
  listCategories,
  updateFilterCount,
  setLoading,
} from "../../actions/experienceActions";

export const getHappeningList = (
  page,
  limit,
  category,
  country,
  opco,
  price
) => {
  return (dispatch) => {
    dispatch(setLoading());
    httpGETRequest(API.HAPPENING, {
      page: page,
      limit: limit,
      category: category,
      country: country,
      opco: opco,
      priceStart: price[0] ? price[0] : null,
      priceEnd: price[1] ? price[1] : null,
    })
      .then((res) => {
        // console.log(res.data);
        if (res.data) {
          const _type = page === 0 ? "FORCE_UPDATE" : "NORMAL_PUSH";
          dispatch(listExperiences(res.data, _type));
        } else {
          dispatch(serviceActionError(res));
        }
      })
      .catch((error) => {
        // console.log(error);
        dispatch(serviceActionError(error));
      });
  };
};

export const getExperienceList = (
  page,
  limit,
  category,
  country,
  opco,
  price = [],
  requirement = [],
  subcategory
) => {
  return (dispatch) => {
    dispatch(setLoading());
    httpGETRequest(API.EXPERIENCES, {
      page: page,
      limit: limit,
      category: category,
      country: country,
      opco: opco,
      priceStart: price[0] ? price[0] : null,
      priceEnd: price[1] ? price[1] : null,
      requirement: requirement[0],
      subcategory: subcategory,
    })
      .then((res) => {
        // console.log(res.data);
        if (res.data) {
          const _type = page === 0 ? "FORCE_UPDATE" : "NORMAL_PUSH";
          dispatch(listallexperience(res.data, _type));
        } else {
          dispatch(serviceActionError(res));
        }
      })
      .catch((error) => {
        // console.log(error);
        dispatch(serviceActionError(error));
      });
  };
};

export const getExperienceDetails = (id, success, failure) => {
  return httpGETRequest(API.EXPERIENCES + `/${id}`)
    .then(success)
    .catch(failure);
};

export const getHappeningDetails = (id, success, failure) => {
  return httpGETRequest(API.HAPPENING + `/${id}`)
    .then(success)
    .catch(failure);
};

export const getBookingAvailability = (data, success, failure) => {
  return httpGETRequest(API.BOOKING_AVAILABILITY, data)
    .then(success)
    .catch(failure);
};

export const createBooking = (data, success, failure) => {
  return httpPOSTRequest(API.CREATE_BOOKING, data).then(success).catch(failure);
};

export const getIntetersts = (success, failure) => {
  return httpGETRequest(API.INTERESTS).then(success).catch(failure);
};

export const postInterests = (data, success, failure) => {
  return httpPOSTRequest(API.POST_INTEREST, data).then(success).catch(failure);
};

export const postInvites = (data, success, failure) => {
  return httpPOSTRequest(API.POST_INVITE, data).then(success).catch(failure);
};

export const getSuggestedUsers = (id, success, failure) => {
  return httpGETRequest(API.SUGGEST_USERS, { id: id })
    .then(success)
    .catch(failure);
};

export const getAttendeeList = (id, success, failure) => {
  return httpGETRequest(API.ATTENDEE_LIST + id)
    .then(success)
    .catch(failure);
};

export const getSearchedUsers = (
  id,
  query,
  opco,
  bu,
  country,
  success,
  failure
) => {
  return httpGETRequest(API.SEARCH_USER, {
    id: id,
    find: query,
    opco: opco,
    bu: bu,
    country: country,
  })
    .then(success)
    .catch(failure);
};

export const cancelBooking = (id, data, success, failure) => {
  return httpPOSTRequest(API.CANCEL_BOOKING + `/${id}`, data)
    .then(success)
    .catch(failure);
};

export const joinExperience = (id, data, success, failure) => {
  return httpPOSTRequest(API.JOIN_EXPERIENCE + `/${id}`, data)
    .then(success)
    .catch(failure);
};

export const getCategories = () => {
  return (dispatch) => {
    httpGETRequest(API.INTERESTS)
      .then((res) => {
        // console.log(res.data);
        if (res.data) {
          dispatch(listCategories(res.data.items));
        } else {
          dispatch(serviceActionError(res));
        }
      })
      .catch((error) => {
        // console.log(error);
        dispatch(serviceActionError(error));
      });
  };
};

export const getFilterCount = (type) => {
  const url =
    type === "create"
      ? API.FILTER_COUNT_CREATE
      : type === "store"
      ? API.STORE_COUNT_FILTER
      : API.FILTER_COUNT_HAPPENING;
  return (dispatch) => {
    httpGETRequest(url)
      .then((res) => {
        // console.log("filter got it", res.data);
        if (res.data) {
          dispatch(updateFilterCount(res.data.data, type));
        } else {
          dispatch(serviceActionError(res));
        }
      })
      .catch((error) => {
        // console.log(error);
        dispatch(serviceActionError(error));
      });
  };
};

export const getMyHappenings = (success, failure) => {
  return httpGETRequest(API.HAPPENING_MY).then(success).catch(failure);
};

export const getInviteFilter = (success, failure) => {
  return httpGETRequest(API.USER_FILTER).then(success).catch(failure);
};

export const getHappeningCalendar = (startDate, endDate) => {
  return new Promise((resolve, reject) => {
    httpGETRequest(API.CALENDER, {
      dateFrom: startDate,
      dateTo: endDate,
      page: 0,
      limit: 200,
    })
      .then((res) => {
        // console.log(res.data);
        if (res.data) {
          resolve(res.data);
        } else {
          resolve(res);
        }
      })
      .catch((err) => reject(err));
  });
};
