import React from "react";
import ContentLoader from "react-content-loader";
import { WingBlank } from "antd-mobile";

const SkeletonStoreCard = ({ width = 150, height = 260, ...rest }) => (
  <ContentLoader
    speed={1}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...rest}
  >
    <rect x="0" y="0" rx="3" ry="3" width={width} height="160" />

    <rect x="10" y="175" rx="3" ry="3" width="100" height="15" />
    <rect x="10" y="195" rx="0" ry="0" width="50" height="7" />

    <rect x="100" y="215" rx="3" ry="3" width="50" height="15" />
    <rect x="10" y="215" rx="0" ry="0" width="50" height="15" />
    <rect x="10" y="235" rx="0" ry="0" width="75" height="25" />
  </ContentLoader>
);

export default SkeletonStoreCard;
