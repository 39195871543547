import React, { useState, useLayoutEffect } from "react";

import { servicesData } from "../constants";

import ErrorAnimation from "../../../assets/animations/error01.gif";  

const Error = ({ id }) => {
  const [data, setData] = useState(servicesData[0]);

  useLayoutEffect(() => {
    setData(servicesData.filter((item) => item.id === id)[0] || null);
  }, []);

  return (
    <>
      <div className="contents">
        <div className="row flex-1 flex-center-bottom">
          <img alt="error" className="success-image" src={ErrorAnimation} />
        </div>
        <div className="row flex-1 flex-center-top">
          <div>
            <h1>Error</h1>
            <p className="desc-text">
              Something went wrong while connecting with your fitness account.
            </p>
          </div>
        </div>
        {/* {data !== null && (
          <div className="row flex-1 flex-center-top flex-column">
            <div className="item-logo">
              <img alt="connect-option-logo" src={data.logo} />
            </div>
          </div>
        )} */}
      </div>
    </>
  );
};

export default Error;
