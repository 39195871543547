import { Card, Flex, WhiteSpace, WingBlank } from "antd-mobile";
import React from "react";
import "./createEventCard.scss";
import SkeletonCard from "../../../../components/common/SkeletonLayouts/SkeletonCard";

export const CreateEventCardRow = (rowData, sectionID, rowID, history) => {
  // console.log("rowasda", rowData);
  return <CreateEventCard rowData={rowData} rowID={rowID} history={history} />;
};

export const CreateEventCard = ({ rowData, rowID, history }) => {
  const {
    cover,
    name,
    category,
    priceUnit,
    price,
    offerprice,
    currency,
    required,
    discount,
    id,
    points,
  } = rowData;
  // console.log("rowData", cover);

  if (!rowID) return <SkeletonCard></SkeletonCard>;

  const handleEventClick = (id) => history.push(`/experience/${id}`);
  return (
    <WingBlank size="md" key={name}>
      <Card
        className="card padding-b-0 fade-in create-event-card"
        style={{ backgroundImage: `url(${cover})` }}
        onClick={() => handleEventClick(id)}
      >
        <Card.Body>
          {points ? (
            <div className="maf-tag event-points">
              <span>{points} points</span>
            </div>
          ) : null}
        </Card.Body>
        <Card.Footer
          className="card-footer"
          content={
            <div className="text-align-left">
              <p className="card-event-title margin-5 margin-t-10">{name}</p>

              <Flex className="margin-5 overflow-visible">
                <Flex.Item className="tag-container">
                  <span>{category}</span>
                </Flex.Item>
                <Flex.Item className="tag-container">
                  <span>{required}</span>
                </Flex.Item>
              </Flex>
              <div>
                <a className="call-action">Book Now</a>
              </div>
            </div>
          }
          extra={
            <div className="create-card-content-container">
              <div className="create-card-content-data-container">
                <span className="create-card-main-text">{discount}</span>
                <p className="create-card-main-sub-text">off</p>
              </div>
              <div className="create-card-content-data-container">
                <p className="line-height-5">
                  <strike className="font-9 color-gray">
                    {currency} {price}
                  </strike>
                </p>
                <span className="create-card-main-text">
                  {currency} {offerprice}
                </span>
                <p className="create-card-main-sub-text">{priceUnit}</p>
              </div>
            </div>
          }
        />
      </Card>
      <WhiteSpace size="lg" />
    </WingBlank>
  );
};
