import React from "react";
import { NavBar } from "antd-mobile";

import "../styles.scss";

const Navbar = ({ title, backButtonAction }) => {
  return (
    <NavBar
      mode="light"
      leftContent={
        <img
          alt="back-arrow"
          className="arrowback"
          src="./icons/back-gold.png"
          onClick={backButtonAction}
        />
      }
      className="navbar-container"
    >
      {title}
    </NavBar>
  );
};

export default Navbar;
