import React from "react";

import StepChallengeImage from "../../../../assets/icons/DFC.png";
import "./styles.scss";

const StepChallengeBanner = ({ setupStepChallenge }) => {
  return (
    <div className="row step-challenge-banner">
      <div className="banner-thumb">
        <img alt="step-challenge" src={StepChallengeImage} />
      </div>
      <div className="banner-text">
        <h3>Get Your Move On</h3>
        <p>Step & Win</p>
      </div>
      <div className="banner-button">
        <button onClick={setupStepChallenge}>Join Now</button>
      </div>
    </div>
  );
};

export default StepChallengeBanner;
