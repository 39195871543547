import BASE_API_URL from "./config";

const API = {
  HAPPENING: BASE_API_URL + "happening",
  EXPERIENCES: BASE_API_URL + "experiences",
  BOOKING_AVAILABILITY: BASE_API_URL + "booking/availability",
  INTERESTS: BASE_API_URL + "interest",
  POST_INTEREST: BASE_API_URL + "profile/interest",
  SUGGEST_USERS: BASE_API_URL + "user/suggest",
  ATTENDEE_LIST: BASE_API_URL + "booking/going/",
  POST_INVITE: BASE_API_URL + "booking/invite",
  SEARCH_USER: BASE_API_URL + "user/find/",
  CREATE_BOOKING: BASE_API_URL + "booking/new",
  CANCEL_BOOKING: BASE_API_URL + "booking/cancel",
  JOIN_EXPERIENCE: BASE_API_URL + "booking/join",
  FILTER_COUNT_HAPPENING: BASE_API_URL + "filter",
  FILTER_COUNT_CREATE: BASE_API_URL + "filter/create",
  VALIDATE_TOKEN: BASE_API_URL + "user/validate",
  HAPPENING_MY: BASE_API_URL + "happening/my",
  USER_FILTER: BASE_API_URL + "user/filter",
  STORE: BASE_API_URL + "store",
  LEADER_BOARD: BASE_API_URL + "leaderboard",
  TRIBE: BASE_API_URL + "tribe",
  LEADER_STORE_HISTORY: BASE_API_URL + "store/history",
  STORE_ORDER: BASE_API_URL + "store/order",
  PROFILE: BASE_API_URL + "profile",
  STORE_COUNT_FILTER: BASE_API_URL + "filter/store",
  CALENDER: BASE_API_URL + "calendar",
  TRIBE_BOARD: BASE_API_URL + "tribeboard",
  DIGIME: BASE_API_URL + "digime/connect",
  DIGIME_SYNC: BASE_API_URL + "digime/sync",
  DIGIME_REVOKE_ACCESS: BASE_API_URL + "digime/revoke",
  APPLE_HEALTH_CONNECT: BASE_API_URL + "digime/appleconnect",
  APPLE_HEALTH_DISCONNECT: BASE_API_URL + "digime/appledisconnect",
  APPLE_HEALTH_STATUS: BASE_API_URL + "digime/applestatus",
  STEP_CHALLENGE_BOARD: BASE_API_URL + 'stepchallengeboard'
};

export default API;
