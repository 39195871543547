import React, { Component } from "react";
import "./Invite.scss";
import { Icon, NavBar, Row, Col, Flex, Modal, Toast } from "antd-mobile";
import {
  getSuggestedUsers,
  getHappeningDetails,
  postInvites,
  getSearchedUsers,
  getAttendeeList,
  getInviteFilter,
} from "../../apiManager/services/experienceServices";

/* import { connect } from 'react-redux'
import { getHappeningDetails } from '../../apiManager/services/experienceServices'
import { Card, WingBlank, WhiteSpace, NavBar, Icon, Flex } from 'antd-mobile' */

import DEEPLINK from "../../apiManager/endpoints/deeplink";
import Image from "../../components/common/Image/image";

class InviteScreen extends Component {
  state = {
    isLoading: true,
    inviteeList: [],
    invitedList: [],
    query: "",
    selectedInviteeList: [],
    suggestedList: [],
    filterData: {},
    selectedFilter: "",
    isModalVisible: false,
    inviteVisible: true,
    tempFilter: {},
    filter: {
      opco: [],
      bu: [],
      country: [],
    },
  };

  constructor() {
    super();
    this.searchRef = React.createRef();
  }

  componentDidMount() {
    // console.log(this.props.match.path)
    // console.log(this.props.location.state)
    this.props.match.path === "/invite/:expId"
      ? this.fetchSugestedInvitees()
      : this.fetchAttendeeList();
  }

  fetchSugestedInvitees = () => {
    getSuggestedUsers(
      this.props.match.params.expId,
      this._success_suggested,
      this._failure
    );
    getInviteFilter(this._success_filterData, this._failure_filterData);
  };

  _success_filterData = (data) => {
    // console.log('data', data)
    if (data && data.data && data.data.data) {
      this.setState({ filterData: data.data.data });
    }
  };

  _failure_filterData = (err) => {
    Toast.fail(err.message, 1);
    // console.log(err)
  };

  fetchAttendeeList = () => {
    getHappeningDetails(
      this.props.match.params.expId,
      this._eventDetailsSuccess,
      this._eventDetailsFailure
    );
    getAttendeeList(
      this.props.match.params.expId,
      this._success_suggested,
      this._failure
    );
  };

  _eventDetailsSuccess = (data) => {
    let _inviteVisible = true;
    if (data && data.data && data.data.data) {
      _inviteVisible = !(
        data.data.data &&
        (data.data.data.timeout || data.data.data.full)
      );
    }
    // console.log('inviteCalue', _inviteVisible)
    this.setState({ inviteVisible: _inviteVisible });
  };

  _eventDetailsFailure = (err) => {
    Toast.fail(err.message, 1);
    // console.log(err)
  };

  fetchQueryList = (query) => {
    this.setState({ query: query });
    const _opco =
      this.state.filter.opco.length === 0
        ? null
        : this.state.filter.opco.toString();
    const _bu =
      this.state.filter.bu.length === 0
        ? null
        : this.state.filter.bu.toString();
    const _country =
      this.state.filter.country.length === 0
        ? null
        : this.state.filter.country.toString();
    if (query.length >= 3) {
      this.setState({ isLoading: true });
      getSearchedUsers(
        this.props.match.params.expId,
        query,
        _opco,
        _bu,
        _country,
        this._success,
        this._failure
      );
    } else {
      this.setState({
        isLoading: false,
        inviteeList: this.state.suggestedList,
      });
    }
  };

  resetSearch = () => {
    this.searchRef.current.value = "";
    this.fetchQueryList("");
  };

  resetFilter = () => {
    this.setState({
      filter: {
        opco: [],
        bu: [],
        country: [],
      },
    });
    this.fetchQueryList(this.state.query);
  };

  checkIsFilterAvailable = () => {
    return (
      this.state.filter.opco.length !== 0 ||
      this.state.filter.bu.length !== 0 ||
      this.state.filter.country.length !== 0
    );
  };

  _filterData = () => {
    const _query = this.state.query;
    this.setState({ isLoading: true });
    const _opco =
      this.state.filter.opco.length === 0
        ? null
        : this.state.filter.opco.toString();
    const _bu =
      this.state.filter.bu.length === 0
        ? null
        : this.state.filter.bu.toString();
    const _country =
      this.state.filter.country.length === 0
        ? null
        : this.state.filter.country.toString();

    getSearchedUsers(
      this.props.match.params.expId,
      _query,
      _opco,
      _bu,
      _country,
      this._success,
      this._failure
    );
  };

  _success_suggested = (resp) => {
    // console.log('resp', resp, this.props.match.params.expId)
    this.setState({
      isLoading: false,
      inviteeList: resp.data.data,
      suggestedList: resp.data.data,
    });
  };

  _success = (resp) => {
    // console.log('resp', resp, this.props.match.params.expId)
    this.setState({
      isLoading: false,
      inviteeList: resp.data.data,
      invitedList: resp.data.invited,
    });
  };

  _failure = (err) => {
    this.setState({ isLoading: false });
    Toast.fail(err.message, 1);
    // console.log(err)
  };

  addToSelectedList = (id) => {
    const selectedList = this.state.selectedInviteeList;
    const index = selectedList.indexOf(id);
    if (index > -1) {
      selectedList.splice(index, 1);
    } else {
      selectedList.push(id);
    }
    this.setState({ selectedInviteeList: selectedList }, () =>
      console.log("selectedList", this.state.selectedInviteeList)
    );
  };

  convertToTitlecase = (string) => {
    var sentence = string.toLowerCase().split(" ");
    for (var i = 0; i < sentence.length; i++) {
      if (sentence[i][0])
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ");
  };

  _renderFilterList = () => {
    let _data = [];
    let _label = "";
    // console.log(this.state.filterData)
    if (this.state.selectedFilter === "Opco") {
      _data = this.state.filterData.opco;
      _label = "opco";
    } else if (this.state.selectedFilter === "Business Unit") {
      _data = this.state.filterData.bu;
      _label = "bu";
    } else if (this.state.selectedFilter === "Country") {
      _data = this.state.filterData.country;
      _label = "country";
    }
    return _data.map((item) => {
      return (
        <Flex className="invite-dashed-line">
          <Flex.Item className="flex-1 margin-l-15">
            <p>{item}</p>
          </Flex.Item>
          <Flex.Item className="userdetailscheckcdiv">
            <div className="round invite-user-filter-round">
              <input
                type="checkbox"
                id={item}
                onChange={() => this._addFilter(item, _label)}
                checked={this.state.tempFilter[_label].includes(item)}
              />
              <label htmlFor={item} />
            </div>
          </Flex.Item>
        </Flex>
      );
    });
  };

  _addFilter = (value, label) => {
    const _filter = this.state.tempFilter;
    if (_filter[label].includes(value)) {
      const _index = _filter[label].indexOf(value);
      _filter[label] = [
        ..._filter[label].slice(0, _index),
        ..._filter[label].slice(_index + 1, _filter[label].length),
      ];
    } else {
      _filter[label].push(value);
    }
    this.setState({ tempFilter: _filter }, () => {
      console.log("temo", this.state.tempFilter, "filter", this.state.filter);
    });
  };

  _clearAll = () => {
    const _filter = this.state.tempFilter;
    if (this.state.selectedFilter === "Opco") {
      _filter.opco = [];
    } else if (this.state.selectedFilter === "Business Unit") {
      _filter.bu = [];
    } else if (this.state.selectedFilter === "Country") {
      _filter.country = [];
    }

    this.setState({ tempFilter: _filter });
  };

  _getWhoisGoingConunt = () => {
    if (this.state.inviteeList) {
      let _sum = 0;
      this.state.inviteeList.map((data) => {
        _sum = _sum + data.noOfPeople;
      });
      return _sum;
    }
  };

  render() {
    return this.state.isModalVisible ? (
      <div>
        <div className="popup-main-container">
          <NavBar
            mode="light"
            leftContent={
              <p className="font-12 color-gray" onClick={this._clearAll}>
                Clear All
              </p>
            }
            rightContent={
              <img
                src="../../icons/close-r.png"
                width="50px"
                height="50px"
                onClick={() => this.setState({ isModalVisible: false })}
              />
            }
            className="navbar-container"
          >
            <div className="text-center">
              <p className="font-14">Filter</p>
              <p className="color-gray font-12">{this.state.selectedFilter}</p>
            </div>
          </NavBar>
          <div className="padding-t-70">{this._renderFilterList()}</div>
          <Flex.Item>
            <div className="filter-footer">
              <div
                className="filter-footer-button"
                onClick={() =>
                  this.setState(
                    {
                      filter: JSON.parse(JSON.stringify(this.state.tempFilter)),
                    },
                    () => {
                      this._filterData();
                      this.setState({ isModalVisible: false });
                    }
                  )
                }
              >
                <p>Apply</p>
              </div>
            </div>
          </Flex.Item>
        </div>
      </div>
    ) : (
      <div className="flex-container">
        <Flex>
          <Flex.Item>
            {" "}
            <NavBar
              mode="light"
              leftContent={
                <img
                  className="arrowback"
                  src="../../icons/back-gold.png"
                  onClick={() => this.goBackToEvent()}
                />
              }
              className="navbar-container"
            >
              {" "}
              {this.props.match.path === "/attendee/:expId"
                ? `Attendees (${this._getWhoisGoingConunt()})`
                : "Invite"}
              {this.props.match.path === "/attendee/:expId" &&
              this.state.inviteVisible ? (
                <p
                  className="color-orange invite-button"
                  onClick={() => this.goToInvite()}
                >
                  Invite
                </p>
              ) : null}
            </NavBar>
          </Flex.Item>
        </Flex>
        <Flex>
          <Flex.Item
            style={{ margin: "0px 60px 0px 0px" }}
            className="searchdiv"
          > 
            {this.props.match.path === "/invite/:expId" ? (
              <div className="ant-col-24">
                <input
                  ref={this.searchRef}
                  type="text"
                  name="search"
                  className="searchbox"
                  placeholder="Search.."
                  onChange={(event) => this.fetchQueryList(event.target.value)}
                  style={{
                    width: this.checkIsFilterAvailable() ? "80%" : "90%",
                  }}
                />
                {this.state.query.length ? (
                  <button
                    className="close-icon"
                    type="reset"
                    onClick={() => this.resetSearch()}
                  />
                ) : null}
                {this.checkIsFilterAvailable() ? (
                  <span
                    className="clear"
                    onClick={() => this.resetFilter()}
                    style={{
                      marginLeft: this.state.query.length ? "0%" : "10px",
                    }}
                  >
                    Clear
                  </span>
                ) : null}
              </div>
            ) : null}
          </Flex.Item>
        </Flex>
        {this.props.match.path === "/invite/:expId" &&
        this.state &&
        this.state.filterData &&
        this.state.filterData.hasOwnProperty("opco") ? (
          <div>
            <Flex className="bg-white margin-t-15 invite-filter-container">
              <Flex.Item
                className="flex-1 text-center margin-t-10 margin-b-10 invite-filter-item padding-top-5 seperation-border-right"
                onClick={() => {
                  this.setState(
                    {
                      selectedFilter: "Opco",
                      tempFilter: JSON.parse(JSON.stringify(this.state.filter)),
                    },
                    () => {
                      this.setState({ isModalVisible: true });
                    }
                  );
                }}
              >
                <p
                  className={
                    this.state.filter.opco.length > 0
                      ? "display-line margin-r-5 invite-filter-text-active"
                      : "display-line margin-r-5 font-color-gray"
                  }
                >
                  OpCo
                </p>
                <img
                  className="rotate-90"
                  width="8px"
                  height="10px"
                  src="../../icons/back-gold.png"
                />
              </Flex.Item>
              <Flex.Item
                className="flex-1 text-center margin-t-10 margin-b-10 invite-filter-item padding-top-5"
                onClick={() => {
                  this.setState(
                    {
                      selectedFilter: "Business Unit",
                      tempFilter: JSON.parse(JSON.stringify(this.state.filter)),
                    },
                    () => {
                      this.setState({ isModalVisible: true });
                    }
                  );
                }}
              >
                <p
                  className={
                    this.state.filter.bu.length > 0
                      ? "display-line margin-r-5 invite-filter-text-active"
                      : "display-line margin-r-5 font-color-gray"
                  }
                >
                  Business Unit
                </p>
                <img
                  className="rotate-90"
                  width="8px"
                  height="10px"
                  src="../../icons/back-gold.png"
                />
              </Flex.Item>
              <Flex.Item
                className="flex-1 text-center margin-t-10 margin-b-10 invite-filter-item padding-top-5 seperation-border-left"
                onClick={() => {
                  this.setState(
                    {
                      selectedFilter: "Country",
                      tempFilter: JSON.parse(JSON.stringify(this.state.filter)),
                    },
                    () => {
                      this.setState({ isModalVisible: true });
                    }
                  );
                }}
              >
                <p
                  className={
                    this.state.filter.country.length > 0
                      ? "display-line margin-r-5 invite-filter-text-active"
                      : "display-line margin-r-5 font-color-gray"
                  }
                >
                  Country
                </p>
                <img
                  className="rotate-90"
                  width="8px"
                  height="10px"
                  src="../../icons/back-gold.png"
                />
              </Flex.Item>
            </Flex>
          </div>
        ) : null}
        <div className="height-full">
          {this.state.isLoading ? (
            <div className="invite-loader">
              <img src="/icons/MAF.gif" />
            </div>
          ) : (
            <div
              style={{
                marginTop:
                  this.props.match.path === "/invite/:expId" ? "0%" : "65px",
              }}
            >
              {this.state.inviteeList.map((invitee, id) => (
                <Flex className="borderlinedotted" key={id}>
                  <Flex.Item className="userdetailsimagediv">
                    <Image
                      className="personimage"
                      src={`${invitee.image || "/icons/profile.png"}`}
                      height="45"
                      width="45"
                      onClick={() =>
                        invitee && invitee.empid
                          ? window.open(DEEPLINK.EMP_ID + invitee.empid)
                          : null
                      }
                      fallbackurl="/icons/profile.png"
                    />
                    {invitee && invitee.noOfPeople && invitee.noOfPeople > 1 ? (
                      <div className="invite-attendees-number-round">
                        <p>+{invitee.noOfPeople - 1}</p>
                      </div>
                    ) : null}
                  </Flex.Item>

                  <Flex.Item className="userdetailsdescdiv">
                    <span className="spanfontsize">
                      {this.convertToTitlecase(invitee.name)}
                    </span>
                    {invitee.department ? <br /> : null}
                    {invitee.department ? (
                      <span>{invitee.department || ""}</span>
                    ) : null}
                    {invitee.opco ? <br /> : null}
                    {invitee.opco ? <span>{invitee.opco || ""}</span> : null}
                  </Flex.Item>
                  <Flex.Item className="userdetailscheckcdiv">
                    {this.props.match.path === "/invite/:expId" ? (
                      this.state.invitedList &&
                      this.state.invitedList.includes(invitee.id) ? (
                        <p className="color-gray">Joined!</p>
                      ) : (
                        <div className="round">
                          <input
                            type="checkbox"
                            id={invitee.id}
                            onChange={() => this.addToSelectedList(invitee.id)}
                            checked={this.state.selectedInviteeList.includes(
                              invitee.id
                            )}
                          />
                          <label htmlFor={invitee.id} />
                        </div>
                      )
                    ) : null}
                  </Flex.Item>
                </Flex>
              ))}
            </div>
          )}
        </div>

        <Flex>
          <Flex.Item>
            {" "}
            {this.state.selectedInviteeList.length &&
            this.props.match.path === "/invite/:expId" ? (
              <div className="filter-footer">
                <div
                  className="filter-footer-button"
                  onClick={this.handleInviteNext}
                >
                  <p>Invite</p>
                </div>
              </div>
            ) : null}
          </Flex.Item>
        </Flex>
      </div>
    );
  }

  handleInviteNext = () => {
    const inviteeList = {
      invitee: this.state.selectedInviteeList,
      id: this.props.match.params.expId,
    };
    postInvites(inviteeList, this.invitePostSuccess, this.invitePostFailure);
  };

  invitePostSuccess = (data) => {
    // console.log('data', data)
    Toast.success(data.message || "Invite Sent", 1);
    this.goBackToEvent();
  };

  goBackToEvent = () => this.props.history.goBack();
  goToInvite = () =>
    this.props.history.push(`/invite/${this.props.match.params.expId}`);
  invitePostFailure = (error) => {
    // console.log(error)
    Toast.fail(error.message, 1);
    this.goBackToEvent();
  };
}

export default InviteScreen;
