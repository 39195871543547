import React, { Component } from "react";
import "./LandingScreen.scss";
import { connect } from "react-redux";
import Lottie from "react-lottie";
import Token from "../../apiManager/token/tokenValue";
import animationData from "../../assets/animations/access.json";
import { validateToken } from "../../apiManager/services/validationServices";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

class LandingScreen extends Component {
  state = {
    isError: false,
  };

  componentDidMount() {
    // console.log(this.props.match.params.id);
    this._ValidateToken(this.props.match.params.id);
  }

  _ValidateToken = (token) => {
    validateToken(token, this._success, this._failure);
  };

  _success = (data) => {
    if (data.data.result) {
      const temp = data.data.data;
      Token.setToken(this.props.match.params.id);
      if (this.props.match.params.eventId) {
        this.props.history.replace("/home");
        this.props.history.push(`/event/${this.props.match.params.eventId}`);
      } else {
        if (
          temp &&
          temp.hasOwnProperty("interest") &&
          temp.interest &&
          temp.interest.length > 0
        ) {
          if (
            temp.hasOwnProperty("stepChallenge") &&
            temp.stepChallenge &&
            !temp.stepChallengeJoin
          )
            this.props.history.replace("/step-challenge-setup");
          else this.props.history.replace("/home");
        } else {
          this.props.history.replace("/interest");
        }
      }
    }
  };

  _failure = (error) => {
    console.log("error", error);
    this.setState({ isError: true });
  };

  render() {
    const { isError } = this.state;
    return isError ? (
      <div>
        <h1 className="text-center">Your Token is Expired or Wrong</h1>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  result: state.experienceReducer.result,
  filter: state.experienceReducer.filter,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LandingScreen);
