import React from "react";
import SkeletonLeaderCard from "../../../../components/common/SkeletonLayouts/SkeletonLeaderCard";

const LeaderCardSkeleton = ({ visible = false }) =>
  visible && (
    <>
   <div>
     <p><br/></p>
      <SkeletonLeaderCard />
      <SkeletonLeaderCard />
      <SkeletonLeaderCard />
      <SkeletonLeaderCard />
      <SkeletonLeaderCard />
      </div>
    </>
    
  );

export default LeaderCardSkeleton;
