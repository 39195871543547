import React from "react";
import SkeletonHistoryCard from "../SkeletonHistoryCard/SkeletonHistoryCard";

const SkeletonHistory = ({ visible }) =>
  visible && (
    <div>
      <p><br/></p>
      <SkeletonHistoryCard></SkeletonHistoryCard>
      <SkeletonHistoryCard></SkeletonHistoryCard>
      <SkeletonHistoryCard></SkeletonHistoryCard>
    </div>
  );

export default SkeletonHistory;
