import FitbitLogo from "../../assets/icons/fitbit-logo.png";
import GarminLogo from "../../assets/icons/garmin-logo.png";
import GoogleFitLogo from "../../assets/icons/google-fit-logo.png";
import AppleHealthLogo from "../../assets/icons/apple-health-logo.png";

import DEEPLINK from "../../apiManager/endpoints/deeplink";

export const INTRO = "intro";
export const CONNECT_ACCOUNT = "connectAccount";
export const SUCCESS = "success";
export const ERROR = "error";

export const servicesData = [
  {
    status: true,
    name: "Fitbit",
    id: 15,
    homepageURL: "https://www.fitbit.com",
    title: "Add your Fitbit...",
    subTitle: "Your activities, workouts, achievements",
    logo: FitbitLogo,
  },
  {
    status: true,
    name: "Garmin",
    id: 254,
    homepageURL: "https://www.garmin.com",
    title: "Add your Garmin...",
    subTitle: "Your activities and workouts",
    logo: GarminLogo,
  },
  {
    status: true,
    name: "Google Fit",
    id: 284,
    homepageURL: "https://fit.google.com",
    title: "Add your GoogleFit...",
    subTitle: "Your recent fitness data",
    logo: GoogleFitLogo,
  },
  {
    deeplink: DEEPLINK.APPLE_HEALTH,
    status: true,
    name: "Apple Health",
    id: false,
    homepageURL: "https://fit.google.com",
    title: "Add your GoogleFit...",
    subTitle: "Your recent fitness data",
    logo: AppleHealthLogo,
  },
];
