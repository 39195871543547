import { Flex } from 'antd-mobile';
import React, { useState } from 'react';
import { Counter } from '../Counter/counter';

import './storeOrder.scss';


export const StoreOrder = ({ image, pointValue, name, onConfirm, credits, collection }) => {
    const [count, setCount] = useState(1);

    return (
        <>
            <Flex justify="center">
             
                <Flex.Item>
                    <p>{name}</p>
                </Flex.Item>
            </Flex>
            <Counter value={count} onChange={(val) => setCount(val)}/>
            <p className="model-credits-value">{count} x {pointValue} Credits</p>
            <Flex justify="between" className="model-row">
                <Flex.Item className="model-instructions-title"><img src={require('../../../../assets/icons/wallet.svg')} alt="wallet" width="30" height="30"></img> Current Wallet Balance</Flex.Item>
                <Flex.Item className="model-instructions-value">{credits} Credits</Flex.Item>
            </Flex>
            <Flex justify="between" className="model-row">
                <Flex.Item className="model-instructions-value">Total</Flex.Item>
                <Flex.Item className="model-instructions-value">{count*pointValue} Credits</Flex.Item>
            </Flex>
            <p className="model-instructions-header collection-header">Collection Process</p>
            <p className="model-instructions">
            
                        {collection}
                      </p>
            <div className="maf-primary-button">
                <p onClick={() => onConfirm(count)}>Confirm</p>
            </div>
        </>
    )
}

